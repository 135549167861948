const mutations = {}

mutations.initialize = (state) => {
  state.isInitialized = true
}

mutations.setIsLoading = (state, payload) => {
  state.isLoading = payload
}

mutations.setHasError = (state, payload) => {
  state.hasError = payload
}

const names = {
  initialize: 'initialize',
  setIsLoading: 'setIsLoading',
  setHasError: 'setHasError'
}

const namespaced = {
  initialize: 'global/initialize',
  setIsLoading: 'global/setIsLoading',
  setHasError: 'global/setHasError'
}

export {
  names,
  namespaced,
  mutations
}
