import { getReturnsReasonsAsync, postReturnsAsync } from '@/services/returns-service'
import { mapOrderModelToPostOrdersRequestDto } from '@/utilities/mappers/order-mappers'
import { mapOrderModelToPostReturnsRequestDto } from '@/utilities/mappers/return-mappers'
import { mapOrderModelToPutProductsParRequestDto } from '@/utilities/mappers/product-mappers'
import { postOrdersAsync } from '@/services/orders-service'
import { putProductsParAsync } from '@/services/products-service'
import { addCollectionItem, removeCollectionItem, putCollectionItem, getCollection } from '@/helpers/cache-helper'
import { names as ordersMutations } from '@/stores/modules/orders/orders-store.mutations'
import { state, select } from '@/stores/store'
import { cacheKeys } from '@/constants/cache-constants'
import dayjs from 'dayjs'
import envConfig from '@/env.config'
import { isDefined, isNumber } from '@/utilities/type-utility'
import { prependNamespace } from '@sfc-enterprise-ui/utils/src'

const keyName = 'orderNumber'
const namespace = 'orders/'

const names = {
  cacheCreateAsync: 'cacheCreateAsync',
  cacheRemoveAsync: 'cacheRemoveAsync',
  cacheLoadAsync: 'cacheLoadAsync',
  cacheSaveAsync: 'cacheSaveAsync',
  remotePostOrderAsync: 'remotePostOrderAsync',
  remotePostReturnAsync: 'remotePostReturnAsync',
  activateAsync: 'activateAsync',
  addReasonCodesAsync: 'addReasonCodesAsync',
  clearAsync: 'clearAsync',
  createAsync: 'createAsync',
  deactivateAsync: 'deactivateAsync',
  removeAsync: 'removeAsync',
  setOrderDeliveryDateAsync: 'setOrderDeliveryDateAsync',
  setOrderSubmissionDateAsync: 'setOrderSubmissionDateAsync',
  setOrderTotalAsync: 'setOrderTotalAsync',
  setOrderTypeAsync: 'setOrderTypeAsync',
  setPurchaseOrderNumberAsync: 'setPurchaseOrderNumberAsync',
  setOrderUpdatedAt: 'setOrderUpdatedAt'
}

const namespaced = prependNamespace(namespace, names)

const actions = {}

actions.cacheCreateAsync = async ({ rootGetters }, payload) => {
  addCollectionItem(
    cacheKeys.orders,
    payload[keyName],
    {
      order: {
        ...rootGetters[select.orders.byNumber](payload[keyName]),
        createdAt: dayjs().toISOString()
      },
      lineItems: []
    },
    {
      exp: dayjs()
        .startOf('day')
        .add(envConfig.caching.orderCutoffHourOfDay, 'hours')
        .toISOString()
    }
  )
}

actions.cacheLoadAsync = ({ commit }) => {
  commit(
    ordersMutations.addRange,
    getCollection(cacheKeys.orders).map(cached => cached.order)
  )
}

actions.cacheRemoveAsync = async (_, payload) => {
  removeCollectionItem(
    cacheKeys.orders,
    payload.orderNumber
  )
}

actions.cacheSaveAsync = async ({ rootGetters }, payload) => {
  putCollectionItem(
    cacheKeys.orders,
    payload[keyName],
    ({ lineItems }) => ({
      order: rootGetters[select.orders.byNumber](payload[keyName]),
      lineItems
    })
  )
}

actions.remotePostOrderAsync = async ({ rootGetters }, payload) => {
  const orderLineItems = rootGetters[select.lineItems.filter](x => (
    x.orderNumber === payload[keyName] &&
    !!x.orderQuantity &&
    x.orderQuantity > 0
  ))

  const orderDto = mapOrderModelToPostOrdersRequestDto(payload, orderLineItems)

  const parLineItems = rootGetters[select.lineItems.filter](x => (
    x.orderNumber === payload[keyName] &&
    isDefined(x.parQuantity) &&
    (isNumber(x.parQuantity) && x.parQuantity >= 0)
  ))

  const parDto = mapOrderModelToPutProductsParRequestDto(payload, parLineItems)

  if (orderLineItems.length) {
    const demandCodes = await postOrdersAsync(orderDto)

    window.appInsights.trackTrace('remotePostOrderAsync', {
      uiOrderNumber: payload?.[keyName],
      apiOrderNumber: demandCodes,
      type: payload?.type,
      byUserNumber: payload?.byUserNumber,
      forCustomerNumber: payload?.forCustomerNumber,
      forUserNumber: payload?.forUserNumber,
      deliveredAt: payload?.deliveredAt,
      deliveryOffDays: payload?.deliveryOffDays,
      purchaseOrderNumber: payload?.purchaseOrderNumber,
      orderLineItemsCount: orderLineItems.length
    })
  }

  if (parLineItems.length) {
    await putProductsParAsync(parDto)
  }
}

actions.remotePostReturnAsync = async ({ rootGetters }, return_) => {
  const returnLineItems = rootGetters[select.lineItems.filter](x => (
    x.orderNumber === return_[keyName] &&
    !!x.orderQuantity &&
    x.orderQuantity > 0
  ))

  const dto = mapOrderModelToPostReturnsRequestDto(return_, returnLineItems)

  const returnCodes = await postReturnsAsync(dto)

  window.appInsights.trackTrace('remotePostReturnAsync', {
    uiCreditNumber: return_?.[keyName],
    apiCreditNumber: returnCodes,
    type: return_?.type,
    byUserNumber: return_?.byUserNumber,
    forCustomerNumber: return_?.forCustomerNumber,
    forUserNumber: return_?.forUserNumber,
    deliveredAt: return_?.deliveredAt,
    deliveryOffDays: return_?.deliveryOffDays,
    purchaseOrderNumber: return_?.purchaseOrderNumber,
    returnLineItemsCount: returnLineItems.length
  })
}

actions.activateAsync = ({ commit, rootGetters }, payload) => {
  if (!payload) {
    const activeCustomerOrder = rootGetters[select.orders.byCustomerNumber](
      state.customers.active)
    const firstOrder = rootGetters[select.orders.first]
    if (activeCustomerOrder) {
      commit(ordersMutations.activate, activeCustomerOrder.customerNumber)
    }
    else if (firstOrder) {
      commit(ordersMutations.activate, firstOrder[keyName])
    }
  }
  else {
    commit(ordersMutations.activate, payload[keyName])
  }
}

actions.addReasonCodesAsync = async ({ commit }) => {
  const reasonCodes = await getReturnsReasonsAsync()

  const reasonCodesByWarehouse = reasonCodes.reduce((prev, curr) => {
    if (!prev[curr.warehouseNumber]) {
      prev[curr.warehouseNumber] = []
    }

    prev[curr.warehouseNumber].push({
      code: curr.code,
      description: curr.description
    })

    return prev
  }, {})

  commit(ordersMutations.addReasonCodes, reasonCodesByWarehouse)
}

actions.clearAsync = ({ commit }) => {
  commit(ordersMutations.clear)
}

actions.createAsync = ({ commit }, payload) => {
  commit(ordersMutations.add, payload)
}

actions.deactivateAsync = ({ commit }) => {
  commit(ordersMutations.deactivate)
}

actions.removeAsync = ({ commit }, payload) => {
  commit(ordersMutations.remove, payload[keyName])
}

actions.setOrderDeliveryDateAsync = ({ commit }, payload) => {
  commit(ordersMutations.setOrderDeliveryDate, payload)
}

actions.setOrderSubmissionDateAsync = ({ commit }, payload) => {
  commit(ordersMutations.setOrderSubmissionDate, payload)
}

actions.setOrderTotalAsync = ({ commit, rootGetters }, payload) => {
  const lineItems = rootGetters[select.lineItems.range](
    (x) => x.orderNumber === payload[keyName] &&
      !!x.total
  )
  const lineItemsTotal = lineItems?.reduce((acc, curr) => acc + curr.total, 0) || 0
  const commitPayload = {
    key: payload[keyName],
    value: lineItemsTotal
  }
  commit(ordersMutations.setOrderTotal, commitPayload)
}

actions.setOrderTypeAsync = ({ commit }, payload) => {
  commit(ordersMutations.setOrderType, payload)
}

actions.setPurchaseOrderNumberAsync = ({ commit }, payload) => {
  commit(ordersMutations.setPurchaseOrderNumber, payload)
}

actions.setOrderUpdatedAt = ({ commit }, payload) => {
  commit(ordersMutations.setOrderUpdatedAt, payload)
}

export {
  names,
  namespaced,
  actions
}
