import ProductManage from '@/models/ProductManage'
import GetProductsHistoryResponseDto from '@/services/dto/GetProductsHistoryResponseDto.js'
import GetProductsInventoryResponseDto from '@/services/dto/GetProductsInventoryResponseDto'
import GetProductsManageResponseDto from '@/services/dto/GetProductsManageResponseDto'
import GetProductsParResponseDto from '@/services/dto/GetProductsParResponseDto'
import GetProductsResponseDto from '@/services/dto/GetProductsResponseDto'
import PutProductsManageRequestDto from '@/services/dto/PutProductsManageRequestDto'
import PutProductsParResponseDto from '@/services/dto/PutProductsParResponseDto'
import { isDefined, isEmpty } from '@/utilities/type-utility'
import { getAsync, putAsync } from '@sfc-enterprise-ui/fetch-api'
import dayjs from 'dayjs'

/**
 * @param {object} query
 * @param {string?} [query.warehouseNumber]
 * @param {string?} [query.customerNumber]
 * @param {string?} [query.commonCustomerNumber]
 * @param {string?} [query.asOf]
 * @returns {Promise<import('@/services/dto/GetProductsResponseDto').default[]>}
 */
export const getProductsAsync = async ({
  warehouseNumber = null,
  customerNumber = null,
  commonCustomerNumber = null,
  asOf = null
}) => {
  let query = ''

  if (isDefined(warehouseNumber)) {
    query += '&warehouse-number=' + warehouseNumber
  }
  if (isDefined(customerNumber)) {
    query += '&customer-number=' + customerNumber
  }
  if (isDefined(commonCustomerNumber)) {
    query += '&common-customer-number=' + commonCustomerNumber
  }
  if (isDefined(asOf)) {
    const parsedAsOf = dayjs(asOf)
    if (dayjs(parsedAsOf).isValid()) {
      query += '&as-of=' + parsedAsOf.startOf('day').toISOString()
    }
  }

  if (isDefined(query)) {
    query = '?' + query.slice(1)
  }

  const result = await getAsync(`/products${query}`)

  return result.map((x) => new GetProductsResponseDto(x))
}

/**
 * @param {object} query
 * @param {string?} [query.warehouseNumber]
 * @param {string?} [query.customerNumber]
 * @param {string?} [query.asOf]
 * @returns {Promise<import('@/services/dto/GetProductsInventoryResponseDto').default[]>}
 */
export const getProductsInventoryAsync = async ({
  warehouseNumber = null,
  customerNumber = null,
  asOf = null
}) => {
  let query = ''

  if (isDefined(warehouseNumber)) {
    query += '&warehouse-number=' + warehouseNumber
  }
  if (isDefined(customerNumber)) {
    query += '&customer-number=' + customerNumber
  }
  if (isDefined(asOf)) {
    const parsedAsOf = dayjs(asOf)
    if (dayjs(parsedAsOf).isValid()) {
      query += '&as-of=' + parsedAsOf.startOf('day').toISOString()
    }
  }

  if (!isEmpty(query)) {
    query = '?' + query.slice(1)
  }

  const result = await getAsync(`/products/inventory${query}`)

  return result.map((x) => new GetProductsInventoryResponseDto(x))
}

/**
 * @param {object} query
 * @param {string?} [query.warehouseNumber]
 * @param {string?} [query.customerNumber]
 * @returns {Promise<import('@/services/dto/GetProductsParResponseDto').default[]>}
 */
export const getProductsParAsync = async ({
  warehouseNumber = null,
  customerNumber = null
}) => {
  let query = ''

  if (isDefined(warehouseNumber)) {
    query += '&warehouse-number=' + warehouseNumber
  }
  if (isDefined(customerNumber)) {
    query += '&customer-number=' + customerNumber
  }

  if (!isEmpty(query)) {
    query = '?' + query.slice(1)
  }

  const result = await getAsync(`/products/par${query}`)

  return result.map((x) => new GetProductsParResponseDto(x))
}

/**
 * @param {import('@/services/dto/PutProductsParRequestDto').default} parDto
 * @returns {Promise<import('@/services/dto/PutProductsParResponseDto').default[]>}
 */
export const putProductsParAsync = async (parDto) => {
  const result = await putAsync('/products/par', parDto)

  return result.map((x) => new PutProductsParResponseDto(x))
}

/**
 * @param {object} query
 * @param {string?} [query.customerNumber]
 * @returns {Promise<import('@/services/dto/GetProductsHistoryResponseDto').default[]>}
 */
export const getProductsHistoryAsync = async ({
  customerNumber = null
}) => {
  const query = isDefined(customerNumber) ? `?customer-number=${customerNumber}` : ''

  const result = await getAsync(`/products/history${query}`)

  return result.map((x) => new GetProductsHistoryResponseDto(x))
}

/**
 * @param {object} query
 * @param {string?} [query.customerNumber]
 * @returns {Promise<import('@/models/ProductManage').default[]>}
 */
export const getProductsManageAsync = async ({
  customerNumber = null
}) => {
  const query = isDefined(customerNumber) ? `?customer-number=${customerNumber}` : ''

  const result = await getAsync(`/products/manage${query}`)

  return result.map((x) => new GetProductsManageResponseDto(x))
    .map((x) => new ProductManage(x))
}

/**
 * @param {object} request
 * @param {string} [request.customerNumber]
 * @param {ProductManage[]} [request.productsManage]
 * @returns {Promise<import('@/models/ProductManage').default[]>}
 */
export const putProductsManageAsync = async ({
  customerNumber,
  productsManage
}) => {
  const query = isDefined(customerNumber) ? `?customer-number=${customerNumber}` : ''

  const putProductsManageRequestDto = productsManage?.map((x) => new PutProductsManageRequestDto(x))

  const result = await putAsync(`/products/manage${query}`, putProductsManageRequestDto)

  return result.map((x) => new GetProductsManageResponseDto(x))
    .map((x) => new ProductManage(x))
}
