import User from '@/models/User'

/**
 * Validates and maps the API endpoint DTO to the application model.
 *
 * @param {import('@/services/dto/GetUsersResponseDto').default} dto
 * @returns {import('@/models/User').default}
 */
export const mapGetUsersResponseDtoToUserModel = (dto) => {
  let errorMessage

  // #region validate dto
  if (!dto.salesRepNumber) errorMessage = 'salesRepNumber is not valid.'
  // #endregion

  if (errorMessage) {
    throw new Error(`Model validation failed: ${errorMessage}`)
  }

  return new User({
    salesRepNumber: dto.salesRepNumber,
    salesRepName: dto.salesRepName,
    email: dto.email,
    employeeNumber: dto.employeeNumber
  })
}
