import { filter, byKey, isEmpty, isNotEmpty, range, single } from '@/stores/modules/common/common.getters'
import LineItem from '@/models/LineItem'

const reducer = (lineItem, { orders }, { include }) => {
  const retVal = lineItem ? new LineItem(lineItem) : {}

  if (include?.includes('customer')) {
    retVal.customer = byKey(orders, lineItem.forCustomerNumber) || null
  }

  return retVal
}

const getters = {}

getters.byNumber = (state) => (key) => byKey(state, key)

getters.byOrderNumber = (state) => (key) => filter(state, x => x.orderNumber === key)

getters.filter = (state) => (filterPredicate) => filter(state, filterPredicate)

getters.isEmpty = (state) => isEmpty(state)

getters.isNotEmpty = (state) => isNotEmpty(state)

getters.nextNumber = (_, __, { lineItems }) => {
  const all = Object.values(lineItems.all)
    .sort((a, b) => a.lineItemNumber < b.lineItemNumber ? -1 : 1)
  return (all[all.length - 1]?.lineItemNumber || 0) + 1
}

getters.range = (state, _, rootState) => (where, options) => {
  return range(state, rootState, reducer, where, options)
}

getters.single = (state, _, rootState) => (where, options) => {
  return single(state, rootState, reducer, where, options)
}

const names = {
  byNumber: 'byNumber',
  byOrderNumber: 'byOrderNumber',
  filter: 'filter',
  isEmpty: 'isEmpty',
  isNotEmpty: 'isNotEmpty',
  nextNumber: 'nextNumber',
  range: 'range',
  single: 'single'
}

const namespaced = {
  byNumber: 'lineItems/byNumber',
  byOrderNumber: 'lineItems/byOrderNumber',
  filter: 'lineItems/filter',
  isEmpty: 'lineItems/isEmpty',
  isNotEmpty: 'lineItems/isNotEmpty',
  nextNumber: 'lineItems/nextNumber',
  range: 'lineItems/range',
  single: 'lineItems/single'
}

export {
  names,
  namespaced,
  getters
}
