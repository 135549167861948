<template>
  <one-erp-page-layout
    :use-default-header-css-classes="false"
    :use-default-content-css-classes="false"
    :use-default-sidebar-css-classes="false"
  >
    <template #header>
      <div class="orders-edit-view-masthead">
        <div class="activate-user">
          <users-activate
            @loading="onLoading"
            @change-active-user="(x) => onChangeActiveUserOrCustomer(x)"
            :disabled="state.isLoading"
            show-orders-credits-counts
            use-header-style
          />
        </div>
        <div class="activate-customer">
          <customers-activate
            @change-active-customer="(x) => onChangeActiveUserOrCustomer(null, x)"
            :disabled="state.isLoading"
            show-orders-credits-counts
            use-header-style
          />
        </div>
        <div class="po-number">
          <one-erp-input
            v-if="data?.isOrder"
            v-model="data.purchaseOrderNumber"
            @blur="onUpdatePoNumberAsync(data.purchaseOrderNumber)"
            label="PO Number"
            width="8rem"
            :maxlength="16"
          />
        </div>
        <div class="active-order-total">
          <active-order-total />
        </div>
        <div class="off-day-switch" v-if="data?.isOrder">
          <one-erp-switch
            @update:model-value="(x) => state.allowOffDayDeliveries = x"
            :model-value="state.allowOffDayDeliveries"
            size="medium"
            label="Off Day"
          />
        </div>
        <div class="delivery-date">
          <one-erp-date-picker
            v-if="data?.isOrder"
            @update:model-value="onUpdateOrderDeliveryDateAsync"
            :model-value="data.deliveredAtFormatted"
            :disabled-dates="deliveryRange"
            :disabled-days-of-week="state.allowOffDayDeliveries ? undefined : data.deliveryOffDays"
            label="Delivery Date"
          />
        </div>
        <div class="submit">
          <one-erp-button
            v-if="data"
            @click="state.showConfirmSubmissionModal = true"
            :disabled="!data.canSubmit"
            :button-type="oneErpButtonTypes.solid"
            button-text="Submit"
          />
        </div>
      </div>
    </template>
    <template #content v-if="!global.isLoading.value">
      <erp-spinner :show-spinner="state.isLoading" />
      <div class="orders-edit-view-content">
        <div class="content" v-if="data?.isInProgress">
          <div class="multi-filter">
            <one-erp-multi-filter
              @update:filter-function="(x) => state.gridMultiFilterFunction = x"
              :filterable-options="gridMultiFilterOptions"
              filter-label="header"
              filter-value="field"
            />
          </div>
          <div class="column-toggle">
            <one-erp-dropdown
              :options="columnOptions"
              placeholder="Columns"
            >
              <template #dropdown-item="{ dropdownItem }">
                <div
                  @click.stop="() => { dropdownItem.selected = !dropdownItem.selected }"
                  class="dropdown-option"
                >
                  <svg-chooser
                    :svg-name="dropdownItem.selected ? SvgConstants.checkbox : SvgConstants.checkboxOutline"
                    :svg-size="2.4"
                    :svg-color="OneErpColors.shamrock"
                    :svg-background-color="OneErpColors.transparent"
                  />
                  <div>{{ dropdownItem.label }}</div>
                </div>
              </template>
            </one-erp-dropdown>
          </div>
          <div class="multi-sort">
            <one-erp-sort-indicator
              @update:descending="() => state.gridMultiSortDescending = !state.gridMultiSortDescending"
              @update:sort-param="(x) => state.gridMultiSortParameter = x"
              :sortable-parameters="gridMultiSortParameters"
              :sort-param="state.gridMultiSortParameter"
              :descending="state.gridMultiSortDescending"
              sort-param-label="header"
              sort-param-value="field"
            />
          </div>
          <div class="grid">
            <orders-line-items-grid
              @select-uom-is-each="onUpdateUomAsync"
              @update-par-quantity="onUpdateLineItemParQuantityAsync"
              @update-current-quantity="onUpdateLineItemCurrentQuantityAsync"
              @update-order-quantity="onUpdateLineItemOrderQuantityAsync"
              @update-reason-code="onUpdateLineItemReasonCodeAsync"
              :filters="[
                state.gridMultiFilterFunction
              ]"
              :sorts="[state.gridMultiSortFunction]"
              :order-number="data.orderNumber"
              :hide-return-reason-code="data.isOrder"
              :hide-last-ordered-quantity="hideColumn(orderGridFields.lastOrderedQuantity)"
              :hide-avg-sales="hideColumn(orderGridFields.avgSales)"
              :hide-avg-credits="hideColumn(orderGridFields.avgCredits)"
              :hide-product-pack-size-description="hideColumn(orderGridFields.packSizeDescription)"
              :hide-product-on-hand-quantity="hideColumn(orderGridFields.onHandQuantity)"
              :hide-product-price="hideColumn(orderGridFields.price)"
              :hide-par-quantity="data.isCredit"
              :hide-current-quantity="data.isCredit"
              :hide-unit-of-measure="data.isCredit"
              :order-quantity-header-text="data.isCredit ? 'Credit (EA)' : 'Order'"
              :default-uom="data.isCredit ? unitOfMeasureCodes.each : null"
              :is-order="data.isOrder"
              :is-credit="data.isCredit"
            />
          </div>
        </div>
        <div class="submitted" v-else-if="data?.isSubmitted">
          Already SUBMITTED.
        </div>
        <div class="not-found" v-else-if="!data">
          <app-card title="Not found.">
            <div class="card">
              <span class="text-1">Start a new order or credit for</span>
              <span class="text-2">{{ customers.active.value?.customerName }}</span>
              <orders-create
                @order-credit-created="onOrderCreditCreated"
                @loading="onLoading"
              />
            </div>
          </app-card>
        </div>
        <one-erp-modal
          @cancel="state.showConfirmSubmissionModal = false"
          @confirm="onSubmitOrderAsync"
          :width="modalSizesConstants.small"
          :show-modal="state.showConfirmSubmissionModal"
          :overflow-y="overflowConstants.auto"
          cancel-button-text="Back"
          confirm-button-text="Submit"
        >
          <template #header>
            <div class="order-submission-confirmation-header">
              <template v-if="data?.type === orderTypeNames.order">
                <span>Confirm Delivery</span>
              </template>
              <span class="po-number-confirmation" v-if="data?.purchaseOrderNumber">
                <strong>PO Number:</strong> {{ data?.purchaseOrderNumber }}
              </span>
              <template v-if="data?.type === orderTypeNames.credit">
                <span>Confirm Credit</span>
              </template>
            </div>
          </template>
          <template #content>
            <template v-if="data?.type === orderTypeNames.order">
              <active-order-confirm-submission />
            </template>
            <template v-if="data?.type === orderTypeNames.credit">
              <active-credit-confirm-submission />
            </template>
          </template>
          <template #footer>
            <div class="order-submission-confirmation-footer">
              <one-erp-button
                @click="state.showConfirmSubmissionModal = false"
                :button-type="oneErpButtonTypes.open"
                class="cancel-button"
                button-text="Cancel"
              />
              <one-erp-button
                @click="onSubmitOrderAsync"
                :button-type="oneErpButtonTypes.solid"
                :disabled="state.isApproveButtonDisabled"
                class="approve-button"
                button-text="Approve"
              />
            </div>
          </template>
        </one-erp-modal>
      </div>
    </template>
  </one-erp-page-layout>
</template>
<script setup>
import { computed, nextTick, reactive, ref } from 'vue'
import { useRouter, onBeforeRouteUpdate } from 'vue-router'
import { orderGridFields, orderTypeNames, unitOfMeasureNames, unitOfMeasureCodes } from '@/constants/orders-constants'
import { actions, dispatch, getters, map as storeMap, select, state as storeState } from '@/stores/store'
import { editOrderViewBeforeEnterOrUpdateAsync, editOrderViewBeforeUpdateAsync } from '@/router/guards'
import { routeNames } from '@/constants/route-constants'
import config from '@/env.config'
import dayjs from 'dayjs'
import {
  Button as OneErpButton,
  ButtonTypes as oneErpButtonTypes,
  DatePicker as OneErpDatePicker,
  FormInput as OneErpInput,
  Modal as OneErpModal,
  ModalSizes as modalSizesConstants,
  MultiFilter as OneErpMultiFilter,
  OneErpSwitch,
  Dropdown as OneErpDropdown,
  SvgChooser,
  SvgConstants,
  OneErpColors,
  OverflowYValues as overflowConstants,
  PageLayout as OneErpPageLayout,
  SortIndicator as OneErpSortIndicator,
  Spinner as ErpSpinner
} from '@sfc-enterprise-ui/one-erp-components'
import ActiveCreditConfirmSubmission from '@/components/orders/orders-active-credit-confirm-submission.vue'
import ActiveOrderConfirmSubmission from '@/components/orders/orders-active-order-confirm-submission.vue'
import ActiveOrderTotal from '@/components/orders/orders-active-order-total.vue'
import AppCard from '@/components/app-card.vue'
import CustomersActivate from '@/components/customers/customers-activate.vue'
import OrdersCreate from '@/components/orders/orders-create.vue'
import OrdersLineItemsGrid from '@/components/orders/orders-line-items-grid.vue'
import raiseUserError from '@/utilities/errors/raise-user-error'
import UsersActivate from '@/components/users/users-activate.vue'

const router = useRouter()

const { customers, global } = storeMap()

const gridMultiFilterFunction = (a) => a

const gridMultiSortFunction = (a, b) => {
  const descending = state.gridMultiSortDescending ? -1 : 1
  const fieldA = a[state.gridMultiSortParameter.field]
  const fieldB = b[state.gridMultiSortParameter.field]
  if (typeof fieldA === 'string') {
    return fieldA.localeCompare(fieldB) * descending
  } else {
    return (fieldA - fieldB) * descending
  }
}

const gridMultiSortDescending = false

const deliveryRange = [
  {
    start: dayjs()
      .startOf('day')
      .add((
        config.orders.deliveryWindowDelayDays +
        config.orders.deliveryWindowTotalDays
      ), 'days')
      .format('MM/DD/YYYY')
  },
  {
    end: dayjs()
      .startOf('day')
      .add(config.orders.deliveryWindowDelayDays, 'days')
      .format('MM/DD/YYYY')
  }
]
const columnOptions = ref([
  { label: 'Last Order', value: orderGridFields.lastOrderedQuantity, selected: true },
  { label: 'AVG Sales', value: orderGridFields.avgSales, selected: true },
  { label: 'AVG Credits', value: orderGridFields.avgCredits, selected: true },
  { label: 'Case Count', value: orderGridFields.packSizeDescription, selected: false },
  { label: 'On Hand', value: orderGridFields.onHandQuantity, selected: false },
  { label: 'Price', value: orderGridFields.price, selected: false }
])
const hideColumn = computed(() => (/** @type {String} */field) => {
  return columnOptions.value.find((item) => item.value === field)?.selected === false
})

const state = reactive({
  gridMultiFilterFunction,
  gridMultiSortFunction,
  gridMultiSortParameter: { header: 'Sequence', field: orderGridFields.sequenceGroupNumber },
  gridMultiSortDescending,
  allowOffDayDeliveries: false,
  isLoading: false,
  isApproveButtonDisabled: false,
  showConfirmSubmissionModal: false
})

const data = computed(() => {
  const order = getters[select.orders.single](
    (order) => order.orderNumber === storeState.orders.active,
    { include: ['lineItems', 'customer'] }
  )

  if (
    order &&
    order.orderNumber &&
    order.type &&
    order.lineItems &&
    order.customer
  ) {
    return {
      orderNumber: order.orderNumber,
      type: order.type,
      byUserNumber: order.byUserNumber,
      forCustomerNumber: order.forCustomerNumber,
      forUserNumber: order.forUserNumber,
      isOrder: order.type === orderTypeNames.order,
      isCredit: order.type === orderTypeNames.credit,
      isInProgress: !order.submittedAt,
      isSubmitted: !!order.submittedAt,
      deliveredAt: order.deliveredAt,
      deliveredAtFormatted: dayjs(order.deliveredAt).format('MM/DD/YYYY'),
      deliveryOffDays: order.customer.indexedOffDays,
      canSubmit: !!order.lineItems.find(x => x.orderQuantity > 0),
      purchaseOrderNumber: order.purchaseOrderNumber
    }
  }
  else {
    return null
  }
})

const gridMultiSortParameters = computed(() => {
  if (data.value?.type === orderTypeNames.order) {
    return [
      { header: 'Sequence', field: orderGridFields.sequenceGroupNumber },
      { header: 'Description', field: orderGridFields.productDescription },
      { header: 'Number', field: orderGridFields.productNumber },
      { header: 'Vendor', field: orderGridFields.vendorName },
      { header: 'Warehouse', field: orderGridFields.warehouseName }
    ]
  }
  if (data.value?.type === orderTypeNames.credit) {
    return [
      { header: 'Sequence', field: orderGridFields.lineItemNumber },
      { header: 'Description', field: orderGridFields.productDescription },
      { header: 'Number', field: orderGridFields.productNumber },
      { header: 'Vendor', field: orderGridFields.vendorName },
      { header: 'Warehouse', field: orderGridFields.warehouseName }
    ]
  }
  return []
})

const gridMultiFilterOptions = computed(() => {
  if (data.value?.type === orderTypeNames.order) {
    return [
      { header: 'Description', label: 'Description', field: orderGridFields.productDescription, id: 1 },
      { header: 'Number', label: 'Number', field: orderGridFields.productNumber, id: 2 },
      { header: 'Vendor', label: 'Vendor', field: orderGridFields.vendorName, id: 3 }
    ]
  }
  if (data.value?.type === orderTypeNames.credit) {
    return [
      { header: 'Description', label: 'Description', field: orderGridFields.productDescription, id: 1 },
      { header: 'Number', label: 'Number', field: orderGridFields.productNumber, id: 2 },
      { header: 'Vendor', label: 'Vendor', field: orderGridFields.vendorName, id: 3 }
    ]
  }
  return []
})

onBeforeRouteUpdate(editOrderViewBeforeUpdateAsync)
onBeforeRouteUpdate(editOrderViewBeforeEnterOrUpdateAsync)

const onChangeActiveUserOrCustomer = (userNumber, customerNumber) => {
  const order = getters[select.orders.find](order => (
    (
      order.byUserNumber === userNumber ||
      order.forCustomerNumber === customerNumber
    ) &&
    (
      order.type === orderTypeNames.order ||
      order.type === orderTypeNames.credit
    )
  ))
  if (order) {
    router.push({
      name: routeNames.editOrder,
      params: { orderNumber: order.orderNumber }
    })
  }
  else {
    router.push({
      name: routeNames.editOrder
    })
  }
}

const onUpdateUomAsync = async (key, value) => {
  value = value
    ? unitOfMeasureNames.case
    : unitOfMeasureNames.each
  dispatch(actions.lineItems.setLineItemUnitOfMeasureAsync, { key, value })
  dispatch(actions.lineItems.setLineItemTotalAsync, key)
  dispatch(actions.orders.setOrderTotalAsync, data.value)
  dispatch(actions.orders.cacheSaveAsync, data.value)
  dispatch(actions.lineItems.cacheSaveAllForOrderAsync, data.value)
}

const onUpdateLineItemParQuantityAsync = async (key, value) => {
  value = parseInt(value) || 0
  dispatch(actions.lineItems.setLineItemParQuantityAsync, { key, value })
  dispatch(actions.lineItems.setLineItemTotalAsync, key)
  dispatch(actions.orders.setOrderTotalAsync, data.value)
  dispatch(actions.orders.cacheSaveAsync, data.value)
  dispatch(actions.lineItems.cacheSaveAllForOrderAsync, data.value)
}

const onUpdateLineItemCurrentQuantityAsync = async (key, value) => {
  value = parseInt(value) || 0
  dispatch(actions.lineItems.setLineItemCurrentQuantityAsync, { key, value })
  dispatch(actions.lineItems.setLineItemTotalAsync, key)
  dispatch(actions.orders.setOrderTotalAsync, data.value)
  dispatch(actions.orders.cacheSaveAsync, data.value)
  dispatch(actions.lineItems.cacheSaveAllForOrderAsync, data.value)
}

const onUpdateLineItemOrderQuantityAsync = async (key, value) => {
  value = parseInt(value) || 0
  dispatch(actions.lineItems.setLineItemOrderQuantityAsync, { key, value })
  dispatch(actions.lineItems.setLineItemTotalAsync, key)
  dispatch(actions.orders.setOrderTotalAsync, data.value)
  dispatch(actions.orders.cacheSaveAsync, data.value)
  dispatch(actions.lineItems.cacheSaveAllForOrderAsync, data.value)
}

const onUpdateLineItemReasonCodeAsync = async (key, value) => {
  dispatch(actions.lineItems.setLineItemReasonCodeAsync, { key, value })
  dispatch(actions.orders.cacheSaveAsync, data.value)
  dispatch(actions.lineItems.cacheSaveAllForOrderAsync, data.value)
}

const onUpdateOrderDeliveryDateAsync = async (value) => {
  if (!value) {
    return
  }
  value = dayjs(value).toISOString()
  if (data.value?.deliveredAt !== value) {
    await dispatch(actions.orders.setOrderDeliveryDateAsync, {
      key: data.value?.orderNumber,
      value
    })
    await dispatch(actions.orders.cacheSaveAsync, data.value)
  }
}

const onUpdatePoNumberAsync = async (value) => {
  dispatch(actions.orders.setPurchaseOrderNumberAsync, {
    key: data.value?.orderNumber,
    value
  })
  dispatch(actions.orders.cacheSaveAsync, data.value)
}

const onSubmitOrderAsync = async () => {
  window.appInsights.trackEvent('onSubmitOrderAsync', {
    orderNumber: data?.value?.orderNumber,
    type: data?.value?.type,
    byUserNumber: data?.value?.byUserNumber,
    forCustomerNumber: data?.value?.forCustomerNumber,
    forUserNumber: data?.value?.forUserNumber,
    deliveredAt: data?.value?.deliveredAt,
    purchaseOrderNumber: data?.value?.purchaseOrderNumber
  })

  state.isApproveButtonDisabled = true

  await nextTick()

  state.showConfirmSubmissionModal = false
  try {
    state.isLoading = true
    if (data.value?.type === orderTypeNames.order) {
      await dispatch(actions.orders.remotePostOrderAsync, data.value)
    }
    else if (data.value?.type === orderTypeNames.credit) {
      await dispatch(actions.orders.remotePostReturnAsync, data.value)
    }
    await dispatch(actions.orders.setOrderSubmissionDateAsync, data.value)
    await dispatch(actions.orders.cacheSaveAsync, data.value)
    router.push({
      name: routeNames.dashboard
    })
  }
  catch (err) {
    raiseUserError(
      err.message,
      `Could not submit the ${data.value?.type}.`,
      'Please try again and contact support if this problem continues.'
    )
  }
  finally {
    state.isLoading = false
    state.isApproveButtonDisabled = false
  }
}

const onOrderCreditCreated = (value) => {
  router.push({
    name: routeNames.editOrder,
    params: { orderNumber: value }
  })
}

const onLoading = (isLoading) => {
  state.isLoading = isLoading
}
</script>
<style lang="scss" scoped>
.orders-edit-view-masthead {
  grid-template-areas:
    'activate-user po-number active-order-total off-day-switch delivery-date submit'
    'activate-customer po-number active-order-total off-day-switch delivery-date submit';
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr repeat(4, auto);
  grid-template-rows: repeat(2, 3.4rem);
  padding: 1.8rem;
  row-gap: 0;
  .activate-user {
    grid-area: activate-user;
  }
  .activate-customer {
    grid-area: activate-customer;
  }
  .active-order-total {
    align-self: end;
    font-family: Chivo;
    font-size: 2rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    grid-area: active-order-total;
    letter-spacing: 0.6px;;
    line-height: 3rem;
    padding: 0.5rem 0;
  }
  .off-day-switch {
    align-self: end;
    grid-area: off-day-switch;
  }
  .delivery-date {
    grid-area: delivery-date;
    padding-right: 1.2rem;
    place-self: end end;
  }

  .po-number {
    align-self: end;
    grid-area: po-number;
  }
  .submit {
    grid-area: submit;
    place-self: end end;
  }
}
.orders-edit-view-content {
  height: calc(100vh - 15.8rem);
}
.content {
  grid-template-areas:
    'multi-filter column-toggle multi-sort'
    'grid grid grid';
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr auto auto;
  padding: 3rem 1.8rem 1.8rem;
  .multi-filter {
    grid-area: multi-filter;
  }
  .column-toggle {
    grid-area: column-toggle;
  }
  .multi-sort {
    grid-area: multi-sort;
    padding: 1.3rem 0;
  }
  .grid {
    grid-area: grid;
  }
}
.not-found {
  box-sizing: border-box;
  display: grid;
  height: 100%;
  place-content: center center;
  padding-bottom: 5%;
  .card {
    .text-1,
    .text-2 {
      display: block;
      text-align: center;
    }
    .text-1 {
      font-size: 1.8rem;
      margin-bottom: 1.8rem;
    }
    .text-2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 5rem;
    }
  }
}
.order-submission-confirmation-header {
  color: var(--text-alt-color);
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  .po-number-confirmation {
    font-weight: normal;
    margin-left: 14rem;
    text-transform: none;
  }
}
.order-submission-confirmation-footer {
  display: grid;
  grid-template-areas: 'cancel-button approve-button';
  justify-content: space-between;
  width: 100%;
  .cancel-button {
    grid-area: cancel-button;
  }
  .approve-button {
    grid-area: approve-button;
  }
}
:deep(.column-toggle) {
  .dropdown-item {
    padding: 0;
  }
}
.dropdown-option {
    display: flex;
    align-items: center;
    gap: 0.7rem;
    width: 100%;
    padding: 0.9rem 0.6rem;
  }
</style>
