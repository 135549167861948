import { unitOfMeasureCodes } from '@/constants/orders-constants'
import PostOrdersRequestDto from '@/services/dto/PostOrdersRequestDto'

/**
 * Validates and maps the application model to the API endpoint DTO.
 *
 * @param {import('@/models/Order.js').default} order
 * @param {import('@/models/LineItem.js').default[]} lineItems
 * @returns {import('@/services/dto/PostOrdersRequestDto').default}
 */
export const mapOrderModelToPostOrdersRequestDto = (order, lineItems) => {
  let errorMessage

  // #region validate order model
  if (!order.forCustomerNumber) errorMessage = 'forCustomerNumber is not valid.'
  if (!order.forUserNumber) errorMessage = 'forUserNumber is not valid.'
  if (!order.deliveredAt) errorMessage = 'deliveredAt is not valid.'
  // #endregion

  // #region validate line items models
  for (const lineItem of lineItems) {
    if (errorMessage) break

    if (!lineItem.product?.productNumber) errorMessage = 'productNumber is not valid.'
    if (!lineItem.product?.warehouseNumber) errorMessage = 'warehouseNumber is not valid.'
    if (!lineItem.orderQuantity) errorMessage = 'orderQuantity is not valid.'
    if (!lineItem.unitOfMeasure) errorMessage = 'unitOfMeasure is not valid.'
  }
  // #endregion

  if (errorMessage) {
    throw new Error(`Model validation failed: ${errorMessage}`)
  }

  const warehouseObjsTable = {}

  for (const lineItem of lineItems) {
    const { warehouseNumber } = lineItem.product

    if (!warehouseObjsTable[warehouseNumber]) {
      warehouseObjsTable[warehouseNumber] = {
        warehouseNumber,
        products: []
      }
    }

    warehouseObjsTable[warehouseNumber].products.push({
      productNumber: lineItem.product.productNumber,
      quantity: lineItem.orderQuantity,
      unitOfMeasure: unitOfMeasureCodes[lineItem.unitOfMeasure]
    })
  }

  return new PostOrdersRequestDto({
    customerNumber: order.forCustomerNumber,
    salesRepNumber: order.forUserNumber,
    deliveredAt: order.deliveredAt,
    warehouses: Object.values(warehouseObjsTable),
    purchaseOrderNumber: order.purchaseOrderNumber
  })
}
