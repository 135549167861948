import { names as globalMutations } from '@/stores/modules/global/global-store.mutations'

const actions = {}

actions.initialize = async ({ commit }) => {
  commit(globalMutations.initialize, true)
}

actions.setLoading = async ({ commit }, payload) => {
  commit(globalMutations.setIsLoading, payload)
}

actions.setHasError = ({ commit }, payload) => {
  commit(globalMutations.setHasError, payload)
}

const names = {
  initialize: 'initialize',
  setLoading: 'setLoading',
  setHasError: 'setHasError'
}

const namespaced = {
  initialize: 'global/initialize',
  setLoading: 'global/setLoading',
  setHasError: 'global/setHasError'
}

export {
  names,
  namespaced,
  actions
}
