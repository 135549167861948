// @ts-check
import ProductCellRenderer from '@/components/shared/product-cell-renderer.vue'
import DescriptionCellRenderer from '@/components/shared/description-cell-renderer.vue'
import InputCellRenderer from '@/components/shared/input-cell-renderer.vue'
import { productInventoryFields } from '@/constants/product-constants'

// NOTE: If these field names change, CSS names also need altering.

/**
* @typedef SimpleGridColumnAttribute
* @type {object}
* @property {string} header
* @property {keyof import('@/constants/product-constants').IProductInventoryFields} field
* @property {string} width
*/

/**
* @typedef CustomColumnDef
* @property {import('vue').Component} renderer
* @property {Function?=} updateFn
* @property {Boolean?=} disabled
*/

/**
* @typedef ColumnDef
* @type {SimpleGridColumnAttribute & CustomColumnDef}
*/

/** @type {Array<ColumnDef>} */
export const columnDefs = [{
  header: 'Product',
  field: productInventoryFields.productNumber,
  width: '9rem',
  renderer: ProductCellRenderer,
  disabled: true
}, {
  header: 'Description',
  field: productInventoryFields.productDescription,
  width: '3fr',
  renderer: DescriptionCellRenderer,
  disabled: true
}, {
  header: 'Warehouses',
  field: productInventoryFields.warehouseQuantity,
  width: '12rem',
  renderer: InputCellRenderer,
  disabled: false
}, {
  header: 'Shelves',
  field: productInventoryFields.shelfQuantity,
  width: '12rem',
  renderer: InputCellRenderer,
  disabled: false
}, {
  header: 'Other',
  field: productInventoryFields.otherQuantity,
  width: '12rem',
  renderer: InputCellRenderer,
  disabled: false
}, {
  header: 'Subtotal',
  field: productInventoryFields.subtotalQuantity,
  width: '12rem',
  renderer: InputCellRenderer,
  disabled: true
}]
