import BaseProduct from '@/models/base/BaseProduct'

/** @typedef {import('@/models/interfaces/IProductCellRenderer').IProductCellRenderer} IProductCellRenderer */
/** @typedef {import('@/models/interfaces/IDescriptionCellRenderer').IDescriptionCellRenderer} IDescriptionCellRenderer */

/**
 * @typedef {object} ProductInventoryDto
 * @property {string?=} warehouseNumber
 * @property {string?=} customerNumber
 * @property {string?=} productNumber
 * @property {string?=} store
 * @property {string?=} packSizeDescription
 * @property {string?=} vendorName
 * @property {string?=} productDescription
 * @property {number?=} eachesPerCase
 * @property {string?=} classDescription
 * @property {number?=} sequenceGroupNumber
 * @property {string?=} sequenceGroupDescription
 * @property {string?=} itemSource
*/

/** @implements {IProductCellRenderer} */
/** @implements {IDescriptionCellRenderer} */
class ProductInventory extends BaseProduct {
  /** @param {ProductInventoryDto} dto */
  constructor ({
    warehouseNumber = null,
    customerNumber = null,
    productNumber = null,
    store = null,
    packSizeDescription = null,
    vendorName = null,
    productDescription = null,
    eachesPerCase = null,
    classDescription = null,
    sequenceGroupNumber = null,
    sequenceGroupDescription = null,
    itemSource = null
  }) {
    super({ warehouseNumber })
    /** @type {string?} */
    this.customerNumber = customerNumber
    /** @type {string?} */
    this.productNumber = productNumber
    /** @type {string?} */
    this.store = store
    /** @type {string?} */
    this.packSizeDescription = packSizeDescription
    /** @type {string?} */
    this.vendorName = vendorName
    /** @type {string?} */
    this.productDescription = productDescription
    /** @type {number?} */
    this.eachesPerCase = eachesPerCase
    /** @type {string?} */
    this.classDescription = classDescription
    /** @type {number?} */
    this.sequenceGroupNumber = sequenceGroupNumber
    /** @type {string?} */
    this.sequenceGroupDescription = sequenceGroupDescription
    /** @type {string?} */
    this.itemSource = itemSource
  }
}

export default ProductInventory
