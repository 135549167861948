import { cacheKeys } from '@/constants/cache-constants'
import { getItem } from '@/helpers/cache-helper'
import { getUsersCurrentAsync, getUsersAsync } from '@/services/users-service'
import { mapGetUsersResponseDtoToUserModel } from '@/utilities/mappers/user-mappers'
import { names as usersMutations } from '@/stores/modules/users/users-store.mutations'

const actions = {}

actions.initializeAsync = async ({ commit, getters }, currentUserRoles) => {
  // Get the user data for the current user.
  const currentUser = mapGetUsersResponseDtoToUserModel(await getUsersCurrentAsync())

  currentUser.roles = currentUserRoles

  commit(usersMutations.add, currentUser)
  commit(usersMutations.setCurrent, currentUser)

  // If the user can impersonate, get all the application users.
  if (currentUser.isImpersonationUser) {
    commit(
      usersMutations.addRange,
      (await getUsersAsync()).map((x) => mapGetUsersResponseDtoToUserModel(x))
    )
  }

  // If the user cannot impersonate, they will always be the active user.
  if (!currentUser.isImpersonationUser) {
    commit(usersMutations.activate, currentUser)
  }
  // Otherwise, check if the active user number is cached. Use that, or fall back to the current
  // user's number.
  else {
    commit(
      usersMutations.activate,
      getters.byNumber(getItem(cacheKeys.activeUserNumber)) || currentUser
    )
  }
}

actions.activateAsync = ({ commit }, user) => {
  commit(usersMutations.activate, user)
}

const names = {
  initializeAsync: 'initializeAsync',
  activateAsync: 'activateAsync'
}

const namespaced = {
  initializeAsync: `users/${names.initializeAsync}`,
  activateAsync: `users/${names.activateAsync}`
}

export {
  names,
  namespaced,
  actions
}
