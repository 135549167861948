import { mapInventoryModelToPostInventoriesRequestDto } from '@/utilities/mappers/inventory-mappers'
import { postInventoriesAsync } from '@/services/inventories-service'
import { removeCollectionItem, putCollectionItem, addCollectionItem, getCollection } from '@/helpers/cache-helper'
import { names as inventoriesMutationName } from '@/stores/modules/inventories/inventories-store.mutations'
import { select, state } from '@/stores/store'
import { cacheKeys } from '@/constants/cache-constants'
import dayjs from 'dayjs'
import envConfig from '@/env.config'
import { prependNamespace } from '@sfc-enterprise-ui/utils/src'

const keyName = 'inventoryNumber'
const namespace = 'inventories/'
const names = {
  cacheCreateAsync: 'cacheCreateAsync',
  cacheRemove: 'cacheRemove',
  cacheLoad: 'cacheLoad',
  cacheSaveAsync: 'cacheSaveAsync',
  remotePostInventoryAsync: 'remotePostInventoryAsync',
  remotePostReturnAsync: 'remotePostReturnAsync',
  activateAsync: 'activateAsync',
  addReasonCodesAsync: 'addReasonCodesAsync',
  clearAsync: 'clearAsync',
  createAsync: 'createAsync',
  deactivateAsync: 'deactivateAsync',
  removeAsync: 'removeAsync',
  setOrderDeliveryDateAsync: 'setOrderDeliveryDateAsync',
  setInventorySubmissionDate: 'setInventorySubmissionDate',
  setOrderTotalAsync: 'setOrderTotalAsync',
  setOrderUpdatedAt: 'setOrderUpdatedAt'
}

const actions = {
  [names.cacheRemove]: async (_, payload) => {
    removeCollectionItem(
      cacheKeys.inventories,
      payload[keyName]
    )
  },

  [names.cacheSaveAsync]: async ({ rootGetters }, payload) => {
    putCollectionItem(
      cacheKeys.inventories,
      payload[keyName],
      ({ inventoryItems }) => ({
        inventory: rootGetters[select.inventories.byNumber](payload[keyName]),
        inventoryItems
      })
    )
  },

  [names.remotePostInventoryAsync]: async ({ rootGetters }, payload) => {
    const inventory = rootGetters[select.inventories.active]
    const inventoryItems = rootGetters[select.inventoryItems.filter](inventoryItem => (
      inventoryItem.inventoryNumber === payload[keyName] &&
      inventoryItem.subtotalQuantity !== null
    ))

    const inventoryDto = mapInventoryModelToPostInventoriesRequestDto(inventory, inventoryItems)

    if (inventoryItems.length) {
      await postInventoriesAsync(inventoryDto)
    }
  },

  [names.setInventorySubmissionDate]: ({ commit }, payload) => {
    commit(names.setInventorySubmissionDate, payload)
  },

  [names.cacheCreateAsync]: async ({ rootGetters }, payload) => {
    addCollectionItem(
      cacheKeys.inventories,
      payload[keyName],
      {
        inventory: {
          ...rootGetters[select.inventories.byNumber](payload[keyName]),
          createdAt: dayjs().toISOString()
        },
        inventoryItems: []
      },
      {
        exp: dayjs()
          .startOf('day')
          .add(envConfig.caching.orderCutoffHourOfDay, 'hours')
          .toISOString()
      }
    )
  },

  [names.cacheLoad]: ({ commit }) => {
    commit(
      inventoriesMutationName.addRange,
      getCollection(cacheKeys.inventories).map(cached => cached.inventory)
    )
  },

  // TODO Remove if not used
  [names.activateAsync]: ({ commit, rootGetters }, payload) => {
    if (!payload) {
      const activeCustomerOrder = rootGetters[select.inventories.byCustomerNumber](
        state.customers.active)
      const firstOrder = rootGetters[select.inventories.first]
      if (activeCustomerOrder) {
        commit(inventoriesMutationName.activate, activeCustomerOrder.orderNumber)
      }
      else if (firstOrder) {
        commit(inventoriesMutationName.activate, firstOrder.orderNumber)
      }
    }
    else {
      commit(inventoriesMutationName.activate, payload[keyName])
    }
  },

  [names.clearAsync]: ({ commit }) => {
    commit(inventoriesMutationName.clear)
  },

  [names.createAsync]: ({ commit }, payload) => {
    commit(inventoriesMutationName.add, payload)
  },

  [names.deactivateAsync]: ({ commit }) => {
    commit(inventoriesMutationName.deactivate)
  },

  [names.removeAsync]: ({ commit }, payload) => {
    commit(inventoriesMutationName.remove, payload[keyName])
  }
}

const namespaced = prependNamespace(namespace, names)

export {
  names,
  namespaced,
  actions
}
