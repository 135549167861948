class GetProductsParResponseDto {
  constructor ({
    warehouseNumber = null,
    customerNumber = null,
    productNumber = null,
    quantity = null,
    unitOfMeasure = null,
    isDefined = null
  }) {
    this.warehouseNumber = warehouseNumber
    this.customerNumber = customerNumber
    this.productNumber = productNumber
    this.quantity = quantity
    this.unitOfMeasure = unitOfMeasure
    this.isDefined = isDefined
  }
}

export default GetProductsParResponseDto
