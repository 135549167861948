import { getAsync, postAsync } from '@sfc-enterprise-ui/fetch-api'
import GetReturnsReasonsResponseDto from '@/services/dto/GetReturnsReasonsResponseDto'

/**
 * @param {import('@/services/dto/PostReturnsRequestDto').default} returnDto
 * @returns {Promise<Array<String>>}
 */
export const postReturnsAsync = async (returnDto) => {
  const returnCodes = await postAsync('/returns', returnDto)

  return returnCodes
}

/**
 * @returns {Promise<import('@/services/dto/GetReturnsReasonsResponseDto').default[]>}
 */
export const getReturnsReasonsAsync = async () => {
  const result = await getAsync('/returns/reasons')

  return result.map((x) => new GetReturnsReasonsResponseDto(x))
}
