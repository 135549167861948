/**
 * @typedef IPostInventoriesProductRequestDto
 * @type {object}
 * @property {string?} productNumber
 * @property {number?} warehouseQuantity
 * @property {number?} shelfQuantity
 * @property {string?} unitOfMeasure
 */

export class PostInventoriesProductRequestDto {
  /**
   * @param {IPostInventoriesProductRequestDto} param
   */
  constructor ({
    productNumber = null,
    warehouseQuantity = null,
    shelfQuantity = null,
    unitOfMeasure = ''
  }) {
    /** @type {string?} */
    this.productNumber = productNumber
    /** @type {number?} */
    this.warehouseQuantity = warehouseQuantity
    /** @type {number?} */
    this.shelfQuantity = shelfQuantity
    /** @type {string?} */
    this.unitOfMeasure = unitOfMeasure
  }
}

/**
 * @typedef IPostInventoriesWarehouseProductRequestDto
 * @type {object}
 * @property {string?} warehouseNumber
 * @property {IPostInventoriesProductRequestDto[]} products
 */

export class PostInventoriesWarehouseProductRequestDto {
  /**
   * @param {IPostInventoriesWarehouseProductRequestDto} param
   */
  constructor ({
    warehouseNumber = null,
    products = []
  }) {
    /** @type {string?} */
    this.warehouseNumber = warehouseNumber
    /** @type {IPostInventoriesProductRequestDto[]} */
    this.products = products.map((x) => new PostInventoriesProductRequestDto(x))
  }
}

/**
 * @typedef IPostInventoriesRequestDto
 * @type {object}
 * @property {string?} salesRepNumber
 * @property {string?} customerNumber
 * @property {IPostInventoriesWarehouseProductRequestDto[]} warehouses
 */

export class PostInventoriesRequestDto {
  /**
   * @param {IPostInventoriesRequestDto} param
   */
  constructor ({
    salesRepNumber = '',
    customerNumber = '',
    warehouses = []
  }) {
    /** @type {string?} */
    this.salesRepNumber = salesRepNumber
    /** @type {string?} */
    this.customerNumber = customerNumber
    /** @type {IPostInventoriesWarehouseProductRequestDto[]} */
    this.warehouses = warehouses.map((x) => new PostInventoriesWarehouseProductRequestDto(x))
  }
}

export default PostInventoriesRequestDto
