import { postAsync } from '@sfc-enterprise-ui/fetch-api'

/**
 * @param {import('@/services/dto/PostOrdersRequestDto').default} orderDto
 * @returns {Promise<Array<String>>}
 */
export const postOrdersAsync = async (orderDto) => {
  const demandCodes = await postAsync('/orders', orderDto)

  return demandCodes
}
