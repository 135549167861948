import dayjs from 'dayjs'
/** @typedef {import('dayjs').Dayjs} Dayjs */
/**
 * @typedef {object} InventoryDto
 * @property {string?=} inventoryNumber
 * @property {Dayjs|string?=} createdAt
 * @property {Dayjs|string?=} submittedAt
 * @property {Dayjs|string?=} updatedAt
 * @property {string?=} byUserNumber
 * @property {string?=} forUserNumber
 * @property {string?=} forCustomerNumber
*/
class Inventory {
  /** @param {InventoryDto} dto */
  constructor ({
    inventoryNumber = null,
    createdAt = null,
    submittedAt = null,
    updatedAt = null,
    byUserNumber = null,
    forUserNumber = null,
    forCustomerNumber = null
  }) {
    /** @type {string?} */
    this.inventoryNumber = inventoryNumber
    /** @type {Dayjs?} */
    this.createdAt = dayjs(createdAt).isValid() ? dayjs(createdAt) : null
    /** @type {Dayjs?} */
    this.submittedAt = dayjs(submittedAt).isValid() ? dayjs(submittedAt) : null
    /** @type {Dayjs?} */
    this.updatedAt = dayjs(updatedAt).isValid() ? dayjs(updatedAt) : null
    /** @type {string?} */
    this.byUserNumber = byUserNumber
    /** @type {string?} */
    this.forUserNumber = forUserNumber
    /** @type {string?} */
    this.forCustomerNumber = forCustomerNumber
  }

  get isSubmitted () {
    return dayjs(this.submittedAt).isValid()
  }
}
export default Inventory
