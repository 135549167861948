export class PutProductsParProductRequestDto {
  constructor ({
    productNumber = /** @type {string?} */(null),
    quantity = 0,
    unitOfMeasure = /** @type {string?} */(null)
  }) {
    /** @type {string?} */
    this.productNumber = productNumber
    /** @type {number} */
    this.quantity = quantity
    /** @type {string?} */
    this.unitOfMeasure = unitOfMeasure
  }
}

export class PutProductsParWarehouseProductRequestDto {
  constructor ({
    warehouseNumber = /** @type {string?} */(null),
    products = /** @type {PutProductsParProductRequestDto[]} */([])
  }) {
    /** @type {string?} */
    this.warehouseNumber = warehouseNumber
    /** @type {PutProductsParProductRequestDto[]} */
    this.products = products.map((x) => new PutProductsParProductRequestDto(x))
  }
}

export class PutProductsParRequestDto {
  constructor ({
    customerNumber = /** @type {string?} */(null),
    warehouses = /** @type {PutProductsParWarehouseProductRequestDto[]} */([])
  }) {
    /** @type {string?} */
    this.customerNumber = customerNumber
    /** @type {PutProductsParWarehouseProductRequestDto[]} */
    this.warehouses = warehouses.map((x) => new PutProductsParWarehouseProductRequestDto(x))
  }
}

export default PutProductsParRequestDto
