<template>
  <one-erp-page-layout
    :use-default-header-css-classes="false"
    :use-default-content-css-classes="false"
    :use-default-sidebar-css-classes="false"
  >
    <template #header>
      <div class="dashboard-view-masthead">
        <users-activate
          @loading="(value) => isLoading = value"
          :disabled="isLoading"
          show-orders-credits-counts
          use-header-style
        />
        <customers-activate
          :disabled="isLoading"
          show-orders-credits-counts
          use-header-style
        />
      </div>
    </template>
    <template #content v-if="!global.isLoading.value">
      <one-erp-spinner :show-spinner="isLoading" />
      <template v-if="customers.isNotEmpty.value">
        <div class="dashboard-view-content">
          <div class="dashboard">
            <app-card description="Current Orders & Credits">
              <div class="option existing-orders-container">
                <orders-activate
                  @activate-order-credit="onOrderCreditActivated"
                  activate-button-text="View"
                  delete-button-text="Delete"
                />
              </div>
            </app-card>
            <app-card description="New Orders & Credits">
              <div class="option create-order">
                <orders-create
                  @order-credit-created="onOrderCreditCreated"
                  @loading="(value) => isLoading = value"
                  show-active-customer-details
                />
              </div>
            </app-card>
            <transition name="fade" appear>
              <app-card description="Inventory" v-if="hasConsignedInventory">
                <create-inventory />
              </app-card>
            </transition>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="page-message">
          <h1 class="page-message__header">
            {{
              `${users.currentIsActive.value
                ? 'You do not have'
                : users.active.value?.salesRepName + ' does not have'} any assigned customers.`
            }}
          </h1>
          <span class="page-message__text">Please contact support.</span>
        </div>
      </template>
    </template>
  </one-erp-page-layout>
</template>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import {
  PageLayout as OneErpPageLayout,
  Spinner as OneErpSpinner
} from '@sfc-enterprise-ui/one-erp-components'
import AppCard from '@/components/app-card.vue'
import CustomersActivate from '@/components/customers/customers-activate.vue'
import OrdersActivate from '@/components/orders/orders-activate.vue'
import OrdersCreate from '@/components/orders/orders-create.vue'
import UsersActivate from '@/components/users/users-activate.vue'
import CreateInventory from '@/areas/home/components/create-inventory.vue'
import { routeNames } from '@/constants/route-constants'
import * as deprecatedStore from '@/stores/store'
import { select } from '@/stores/store'

const store = useStore()
const { push } = useRouter()
const { customers, global, users } = deprecatedStore.map()

const isLoading = ref(false)

const activeCustomer = computed(() => store.getters[select.customers.active])

const hasConsignedInventory = computed(() => activeCustomer.value.hasConsignedInventory)

const onOrderCreditCreated = (value) => {
  push({
    name: routeNames.editOrder,
    params: { orderNumber: value }
  })
}

const onOrderCreditActivated = (value) => {
  push({
    name: routeNames.editOrder,
    params: { orderNumber: value }
  })
}
</script>
<style lang="scss" scoped>
$card-width-small: 44rem;
$card-width-large: 56rem;
.dashboard-view-masthead {
  padding: 1.8rem;
}
.dashboard-view-content {
  margin-left: auto;
  margin-right: auto;
  @media screen {
    margin-top: 2rem;
    min-width: $card-width-small;
    max-width: $card-width-large;
  }
  @media screen and (min-width: 1025px) {
    margin-top: 4rem;
    max-width: calc($card-width-small * 2) + 3rem;
  }
  @media screen and (min-width: 1441px) {
    margin-top: 6rem;
    max-width: calc($card-width-small * 3) + 6rem;
  }
}
.dashboard {
  display: grid;
  justify-content: center;
  width: 100%;
  @media screen {
    gap: 3rem;
    grid-template-columns: 1fr;
  }
  @media screen and (min-width: 1025px) {
    gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1441px) {
    gap: 3rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
.card {
  justify-self: center;
  max-width: $card-width-small;
  @media screen {
    width: $card-width-large;
  }
  @media screen and (min-width: 1025px) {
    width: $card-width-small;
  }
  @media screen and (min-width: 1441px) {
    width: $card-width-small;
  }
}
.option {
  height: 100%;
}
</style>
