<template>
  <one-erp-page-layout
    :use-default-header-css-classes="false"
    :use-default-content-css-classes="false"
    :use-default-sidebar-css-classes="false"
  >
    <template #header>
      <inventory-placement-area-header
        @submit="() => showConfirmSubmissionModal = true"
        :submit-disabled="submitDisabled"
      />
    </template>
    <template #content>
      <one-erp-spinner :show-spinner="isLoading" />
      <div class="inventory-edit-view-content">
        <template v-if="activeCustomer">
          <div class="content" v-if="inventory?.isInProgress">
            <div class="multi-filter">
              <one-erp-multi-filter
                @update:filter-function="(x) => gridMultiFilterFunction = x"
                :filterable-options="gridMultiFilterOptions"
                filter-label="header"
                filter-value="field"
              />
            </div>
            <div class="multi-sort">
              <one-erp-sort-indicator
                @update:descending="() => gridMultiSortDescending = !gridMultiSortDescending"
                @update:sort-param="(x) => gridMultiSortParameter = x"
                :sortable-parameters="gridMultiSortParameters"
                :sort-param="gridMultiSortParameter"
                :descending="gridMultiSortDescending"
                sort-param-label="header"
                sort-param-value="field"
              />
            </div>
            <div class="grid">
              <inventory-placement-area-grid
                :inventory-number="inventory?.inventoryNumber"
                :filters="[gridMultiFilterFunction]"
                :sorts="[gridMultiSortFunction]"
              />
            </div>
          </div>
          <div class="submitted" v-else-if="inventory?.isSubmitted">
            Already SUBMITTED.
          </div>
          <div class="not-found" v-else-if="showNotFound">
            <app-card title="Not found.">
              <div class="card">
                <span class="text-1">Start a new inventory for</span>
                <span class="text-2">{{ activeCustomer.customerName }}</span>
                <div>
                  <one-erp-button
                    @click="createInventoryAsync"
                    :button-type="OneErpButtonTypes.solid"
                    class="create-order"
                    button-text="Create Inventory"
                  />
                </div>
              </div>
            </app-card>
          </div>
          <div class="not-available" v-else-if="showNotAvailable">
            <app-card title="Not available.">
              <div class="card">
                <span class="text-1">Inventory not available for</span>
                <span class="text-2">{{ activeCustomer.customerName }}</span>
              </div>
            </app-card>
          </div>
          <one-erp-modal
            @cancel="showConfirmSubmissionModal = false"
            @confirm="showConfirmSubmissionModal = false"
            :width="OneErpModalSizes.small"
            :show-modal="showConfirmSubmissionModal"
            :overflow-y="OneErpOverflowYValues.auto"
            cancel-button-text="Back"
            confirm-button-text="Submit"
          >
            <template #header>
              <div class="inventory-submission-confirmation-header">
                <div>Inventory Count</div>
              </div>
            </template>
            <template #content>
              <inventory-review-grid />
            </template>
            <template #footer>
              <div class="inventory-submission-confirmation-footer">
                <one-erp-button
                  @click="showConfirmSubmissionModal = false"
                  :button-type="OneErpButtonTypes.open"
                  class="cancel-button"
                  button-text="Cancel"
                />
                <one-erp-button
                  @click="onSubmitInventoryAsync"
                  :button-type="OneErpButtonTypes.solid"
                  :disabled="approveButtonDisabled"
                  class="approve-button"
                  button-text="Approve"
                />
              </div>
            </template>
          </one-erp-modal>
        </template>
        <template v-else>
          <div class="page-message">
            <h1 class="page-message__header">
              No customer selected
            </h1>
            <span class="page-message__text">Please contact support.</span>
          </div>
        </template>
      </div>
    </template>
  </one-erp-page-layout>
</template>
<script setup>
import AppCard from '@/components/app-card.vue'
import { productFields } from '@/constants/product-constants'
import { routeNames } from '@/constants/route-constants'
import idHelper from '@/helpers/id-helper'
import { actions, select } from '@/stores/store'
import raiseUserError from '@/utilities/errors/raise-user-error'
import {
  Button as OneErpButton, ButtonTypes as OneErpButtonTypes,
  Modal as OneErpModal,
  ModalSizes as OneErpModalSizes,
  MultiFilter as OneErpMultiFilter,
  OverflowYValues as OneErpOverflowYValues,
  PageLayout as OneErpPageLayout,
  SortIndicator as OneErpSortIndicator,
  Spinner as OneErpSpinner
} from '@sfc-enterprise-ui/one-erp-components/src'
import { isArrayWithItems } from '@sfc-enterprise-ui/utils/src'
import { computed, nextTick, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import InventoryPlacementAreaGrid from '@/areas/inventories/inventory-placement-area-grid.vue'
import InventoryPlacementAreaHeader from '@/areas/inventories/inventory-placement-area-header.vue'
import InventoryReviewGrid from '@/areas/inventories/inventory-review-grid.vue'

const store = useStore()
const { push } = useRouter()

const isLoading = ref(false)

const gridMultiSortParameters = [
  { header: 'Sequence', field: productFields.sequenceGroupNumber },
  { header: 'Description', field: productFields.productDescription },
  { header: 'Number', field: productFields.productNumber },
  { header: 'Vendor', field: productFields.vendorName },
  { header: 'Warehouse', field: productFields.warehouseName }
]

const gridMultiFilterOptions = [
  { header: 'Description', label: 'Description', field: productFields.productDescription, id: 1 },
  { header: 'Number', label: 'Number', field: productFields.productNumber, id: 2 },
  { header: 'Vendor', label: 'Vendor', field: productFields.vendorName, id: 3 }
]

const showConfirmSubmissionModal = ref(false)
const isApproveButtonDisabled = ref(false)
const gridMultiSortDescending = ref(false)
const gridMultiSortParameter = ref(gridMultiSortParameters[0])

const gridMultiFilterFunction = ref((a) => a)

const gridMultiSortFunction = (a, b) => {
  const descending = gridMultiSortDescending.value ? -1 : 1
  const fieldA = a[gridMultiSortParameter.value.field]
  const fieldB = b[gridMultiSortParameter.value.field]
  if (typeof fieldA === 'string') {
    return fieldA.localeCompare(fieldB) * descending
  } else {
    return (fieldA - fieldB) * descending
  }
}

const activeCustomer = computed(() => store.getters[select.customers.active])
const hasConsignedInventory = computed(() => activeCustomer.value?.hasConsignedInventory)
const showNotFound = computed(() => !inventory.value && hasConsignedInventory.value)
const showNotAvailable = computed(() => !inventory.value && !hasConsignedInventory.value)
const submitDisabled = computed(() => !inventory.value && !hasInventoryItems.value)
const inventoryItems = computed(() => {
  return store.getters[select.inventoryItems.range](
    (inventoryItem) => (
      inventoryItem.inventoryNumber === store.getters[select.inventories.active]?.inventoryNumber &&
      (inventoryItem.subtotalQuantity || 0) > 0
    )
  )
})
const hasInventoryItems = computed(() => isArrayWithItems(inventoryItems.value))
const approveButtonDisabled = computed(() => isApproveButtonDisabled.value || !hasInventoryItems.value)

const inventory = computed(() => {
  const inventory = store.getters[select.inventories.single](
    (inventory) => inventory.inventoryNumber === store.state.inventories.active,
    { include: ['inventoryItems', 'customer'] }
  )

  if (
    inventory &&
    inventory.inventoryNumber &&
    inventory.inventoryItems &&
    inventory.customer
  ) {
    return {
      inventoryNumber: inventory.inventoryNumber,
      isInProgress: !inventory.submittedAt,
      isSubmitted: !!inventory.submittedAt
    }
  }
  else {
    return null
  }
})

const onSubmitInventoryAsync = async () => {
  isApproveButtonDisabled.value = true

  await nextTick()

  showConfirmSubmissionModal.value = false
  try {
    isLoading.value = true

    await store.dispatch(actions.inventories.remotePostInventoryAsync, inventory.value)
    await store.dispatch(actions.inventories.setInventorySubmissionDate, inventory.value)
    await store.dispatch(actions.inventories.cacheSaveAsync, inventory.value)
    push({
      name: routeNames.dashboard
    })
  }
  catch (err) {
    raiseUserError(
      err.message,
      `Could not submit ${inventory.value?.inventoryNumber}.`,
      'Please try again and contact support if this problem continues.'
    )
  }
  finally {
    isLoading.value = false
    isApproveButtonDisabled.value = false
  }
}

const createInventoryAsync = async () => {
  isLoading.value = true
  let inventory
  const byUser = store.getters[select.users.current]
  const forUser = store.getters[select.users.active]
  const forCustomer = store.getters[select.customers.active]
  try {
    if (byUser && forUser && forCustomer) {
      inventory = {
        inventoryNumber: 'DSI_' + idHelper.random(13).toUpperCase(),
        byUserNumber: byUser.salesRepNumber,
        forUserNumber: forUser.salesRepNumber,
        forCustomerNumber: forCustomer.customerNumber
      }
    }
    else {
      throw new Error('Missing dependencies to create new inventory.')
    }

    await store.dispatch(actions.inventories.createAsync, inventory)
    await store.dispatch(actions.inventories.cacheCreateAsync, inventory)
    await store.dispatch(actions.inventoryItems.cacheSaveAllFromRemoteForInventoryAsync, inventory)

    await push({
      name: routeNames.editInventories,
      params: { inventoryNumber: inventory.inventoryNumber }
    })
  }
  catch (err) {
    try {
      await store.dispatch(actions.inventories.removeAsync, inventory)
      await store.dispatch(actions.inventories.cacheRemove, inventory)
    }
    catch { }
    raiseUserError(
      err.message,
      'Could not create the Inventory.',
      'Please try again and contact support if this problem continues.'
    )
  }
  finally {
    isLoading.value = false
  }
}
</script>
<style scoped lang="scss">
.inventory-edit-view-masthead {
  grid-template-areas:
    'activate-user po-number active-order-total off-day-switch delivery-date submit'
    'activate-customer po-number active-order-total off-day-switch delivery-date submit';
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr repeat(4, auto);
  grid-template-rows: repeat(2, 3.4rem);
  padding: 1.8rem;
  row-gap: 0;
  .activate-user {
    grid-area: activate-user;
  }
  .activate-customer {
    grid-area: activate-customer;
  }
  .active-order-total {
    align-self: end;
    font-family: Chivo;
    font-size: 2rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    grid-area: active-order-total;
    letter-spacing: 0.6px;;
    line-height: 3rem;
    padding: 0.5rem 0;
  }
  .off-day-switch {
    align-self: end;
    grid-area: off-day-switch;
  }
  .delivery-date {
    grid-area: delivery-date;
    padding-right: 1.2rem;
    place-self: end end;
  }

  .po-number {
    align-self: end;
    grid-area: po-number;
  }
  .submit {
    grid-area: submit;
    place-self: end end;
  }
}
.inventory-edit-view-content {
  height: calc(100vh - 15.8rem);
}
.content {
  grid-template-areas:
    'multi-filter warehouse-filter multi-sort'
    'grid grid grid';
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr auto auto;
  padding: 3rem 1.8rem 1.8rem;
  .multi-filter {
    grid-area: multi-filter;
  }
  .warehouse-filter {
    grid-area: warehouse-filter;
  }
  .multi-sort {
    grid-area: multi-sort;
    padding: 1rem 0;
  }
  .grid {
    grid-area: grid;
  }
}
.not-found,.not-available {
  box-sizing: border-box;
  display: grid;
  height: 100%;
  place-content: center center;
  padding-bottom: 5%;
  .card {
    .text-1,
    .text-2 {
      display: block;
      text-align: center;
    }
    .text-1 {
      font-size: 1.8rem;
      margin-bottom: 1.8rem;
    }
    .text-2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 5rem;
    }
  }
}
.inventory-submission-confirmation-header {
  color: var(--text-alt-color);
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  .po-number-confirmation {
    font-weight: normal;
    margin-left: 14rem;
    text-transform: none;
  }
}
.inventory-submission-confirmation-footer {
  display: grid;
  grid-template-areas: 'cancel-button approve-button';
  justify-content: space-between;
  width: 100%;
  .cancel-button {
    grid-area: cancel-button;
  }
  .approve-button {
    grid-area: approve-button;
  }
}</style>
