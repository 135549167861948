<template>
  <div class="customers-grid">
    <one-erp-simple-grid
      max-grid-height="calc(100vh - 32rem)"
      :column-attributes="schema"
      :row-data="all"
      :enable-grid-tips="false"
      :hide-vertical-separators="false"
    >
      <template #cellcontent="{ row, column }">
        <div class="name" v-if="column.field === customerFields.customerName">
          <span class="label">
            {{ row.customerName }}
          </span>
          <template v-if="props.showQuickStats && row.hasOrderOrCredit">
            <div class="activity-indicator" />
            <customers-quick-stats :customer-number="row.customerNumber" />
          </template>
        </div>
        <div class="number" v-if="column.field === customerFields.customerNumber">
          {{ row.customerNumber }}
        </div>
        <div class="address" v-if="column.field === customerFields.address">
          {{ row.address }}
        </div>
        <div class="delivery-days" v-if="column.field === customerFields.deliveryDays">
          <customers-delivery-days
            :customer-number="row.customerNumber"
            show-off-days
          />
        </div>
        <div class="activate" v-if="column.field === customerFields.activate">
          <one-erp-button
            @click="$emit('customer-selected', row.customerNumber)"
            :disabled="row.active"
            :button-text="props.selectButtonText"
            class="activate"
            :button-type="OneErpButtonTypes.tertiary"
          />
          <one-erp-button
            @click="() => { push({name: routeNames.manageProducts, params: { customerNumber: row.customerNumber }})}"
            button-text="Manage"
            class="activate"
            :button-type="OneErpButtonTypes.tertiary"
          />
        </div>
      </template>
    </one-erp-simple-grid>
  </div>
</template>
<script setup>
import { state as storeState, getters, select } from '@/stores/store'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import {
  Button as OneErpButton,
  ButtonTypes as OneErpButtonTypes,
  SimpleGrid as OneErpSimpleGrid
} from '@sfc-enterprise-ui/one-erp-components'
import CustomersDeliveryDays from '@/components/customers/customers-delivery-days.vue'
import CustomersQuickStats from '@/components/customers/customers-quick-stats.vue'
import { customerFields } from '@/constants/customer-constants'
import { routeNames } from '@/constants/route-constants'

const props = defineProps({
  hideName: { type: Boolean, required: false, default: false },
  hideNumber: { type: Boolean, required: false, default: false },
  hideAddress: { type: Boolean, required: false, default: false },
  hideDeliveryDays: { type: Boolean, required: false, default: false },
  hideActivate: { type: Boolean, required: false, default: false },
  selectButtonText: { type: String, required: false, default: 'Select' },
  filters: { type: Array, required: false, default: () => [] },
  sorts: { type: Array, required: false, default: () => [] },
  showQuickStats: { type: Boolean, required: false, default: true }
})

defineEmits([
  'customer-selected'
])

const { push } = useRouter()

const schema = computed(() => {
  const retVal = []
  if (!props.hideName) retVal.push({ header: 'Name', field: customerFields.customerName, width: '1fr' })
  if (!props.hideNumber) retVal.push({ header: 'Number', field: customerFields.customerNumber, width: '10rem' })
  if (!props.hideAddress) retVal.push({ header: 'Address', field: customerFields.address, width: '2fr' })
  if (!props.hideDeliveryDays) retVal.push({ header: 'Delivery', field: customerFields.deliveryDays, width: '32.1rem' })
  if (!props.hideNumber && !props.hideActivate) retVal.push({ header: '', field: customerFields.activate, width: '16rem' })
  return retVal
})

const all = computed(() => {
  let customers = getters[select.customers.range](
    null,
    { include: ['orders'] }
  )
    .map(({ customerName, customerNumber, address, orders }) => ({
      customerName,
      customerNumber,
      address: address?.singleLine,
      active: customerNumber === storeState.customers.active,
      hasOrderOrCredit: orders.hasOrderOrCredit,
      hasInProgressOrderOrCredit: orders.hasInProgressOrderOrCredit,
      hasSubmittedOrderOrCredit: orders.hasSubmittedOrderOrCredit
    }))
    .sort((a, b) => a.customerName < b.customerName ? -1 : 1)

  if (props.filters.length) {
    for (const filter of props.filters) {
      customers = customers.filter(filter)
    }
  }
  if (props.sorts.length) {
    for (const sort of props.sorts) {
      customers.sort(sort)
    }
  }

  return customers
})
</script>
<style lang="scss" scoped>
.name {
  align-content: center;
  align-items: start;
  display: grid;
  grid-template-areas:
    'label label'
    'indicator counts';
  grid-template-columns: 1.2rem 1fr;
  grid-template-rows: 1.6rem auto;
  height: 4.4rem;
  .order-credit-counts,
  .label {
    line-height: 1.5rem;
    padding-top: 0.1rem;
  }
  .label {
    font-weight: bold;
    grid-area: label;
  }
  .activity-indicator {
    background-color: var(--primary-color);
    border-radius: 50%;
    grid-area: indicator;
    height: 0.8rem;
    margin: 0.4rem 0;
    width: 0.8rem;
  }
  .order-credit-counts {
    grid-area: counts;
  }
}
.number,
.address {
  height: 4.4rem;
  line-height: 4.4rem;
}
.delivery-days {
  height: 2rem;
  margin: 1.2rem 0;
}
.activate {
  display: flex;
  margin: 0.8rem 0 0.8rem;
  gap: 0.9rem;
}
</style>
