<template>
  <div class="active-order-line-items-review">
    <one-erp-simple-grid
      :column-attributes="schema"
      :row-data="inventoryItems"
      :enable-grid-tips="false"
      max-grid-height="21rem"
    >
      <template #cellcontent="{ row, column }">
        <div class="description" v-if="column.field === inventoryReviewGridColumns.productDescription">
          {{ /** @type {IRowData} */(row).product.productDescription }}
        </div>
        <div class="order-quantity" v-if="column.field === inventoryReviewGridColumns.subtotalQuantity">
          {{ /** @type {IRowData} */(row).subtotalQuantity }}
        </div>
      </template>
    </one-erp-simple-grid>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import {
  SimpleGrid as OneErpSimpleGrid
} from '@sfc-enterprise-ui/one-erp-components/src'
import InventoryItem from '@/models/InventoryItem'
import { getters, select } from '@/stores/store'
import { inventoryReviewGridColumns } from '@/constants/inventory-constants'

/** @typedef {import('@/models/interfaces/IInventoryReviewGrid').IInventoryReviewGrid} IRowData */

const schema = [
  { header: 'Product', field: inventoryReviewGridColumns.productDescription, width: '3fr' },
  { header: 'Total', field: inventoryReviewGridColumns.subtotalQuantity, width: '11rem' }
]
/** @type {import('vue').ComputedRef<IRowData[]>} */
const inventoryItems = computed(() => {
  return getters[select.inventoryItems.range](
    /** @param {InventoryItem} inventoryItem */
    (inventoryItem) => (
      inventoryItem.inventoryNumber === getters[select.inventories.active]?.inventoryNumber &&
      inventoryItem.subtotalQuantity !== null
    ),
    {
      /** @param {InventoryItem} inventoryItem */
      map: (inventoryItem) => new InventoryItem(inventoryItem)
    }
  )
})
</script>
