/**
 * type-utility.js (part one: type guards)
 *
 * A set utilities for type guarding values in JavaScript
 * using the TypeScript compiler in the JSDoc syntax.
 *
 * These type guards are simple and meant to be combined
 * for more explicit type checking flow.
 *
 * @example
 * if (isString(myValue) && isEmpty(myValue) {
 *   // myValue is ''
 * })
 */

/**
 * Custom type guard to check if a value is a string.
 * @param {unknown} value The value to check.
 * @returns {value is string} True if the value is a string, false otherwise.
 */
export const isString = (value) => {
  return typeof value === 'string'
}

/**
 * Custom type guard to check if a value is an array.
 * @param {unknown} value The value to check.
 * @returns {value is unknown[]} True if the value is an array, false otherwise.
 */
export const isArray = (value) => {
  return Array.isArray(value)
}

/**
 * Custom type guard to check if a value is an object.
 * @param {unknown} value The value to check.
 * @returns {value is Record<string, unknown>} True if the value is an array, false otherwise.
 */
export const isObject = (value) => {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

/**
 * Custom type guard to check if a value is a number.
 * @param {unknown} value The value to check.
 * @returns {value is number} True if the value is a number, false otherwise.
 */
export const isNumber = (value) => {
  return typeof value === 'number'
}

/**
 * Custom type guard to check if a value is a Date.
 * @param {unknown} value The value to check.
 * @returns {value is Date} True if the value is an Date, false otherwise.
 */
export const isDate = (value) => {
  return value instanceof Date && !isNaN(value.getTime())
}

/**
 * Custom type guard to check if a value is a Promise.
 * @param {unknown} value The value to check.
 * @returns {value is Promise<unknown>} True if the value is a Promise, false otherwise.
 */
export const isPromise = (value) => {
  return value instanceof Promise
}

/**
 * Custom type guard to check if a value is a Function.
 * @param {unknown} value The value to check.
 * @returns {value is Function} True if the value is a Function, false otherwise.
 */
export const isFunction = (value) => {
  return typeof value === 'function'
}

/**
 * type-utility.js (part two: validators)
 *
 * A set utilities for validating in JavaScript
 * using the TypeScript compiler in the JSDoc syntax.
 */

/**
 * Custom validator to check if a value is not null or undefined.
 * @template T unknown
 * @param {T} value The value to check.
 * @returns {value is Exclude<T, null | undefined>} True if the value is not null or undefined, false otherwise.
 */
export const isDefined = (value) => {
  return value !== null && value !== undefined
}

/**
 * Custom validator to check if a value is null or undefined.
 * @param {unknown} obj The value to check.
 * @returns {obj is null | undefined} True if the value is null or undefined, false otherwise.
 */
export const isNullOrUndefined = (obj) => {
  return typeof obj === 'undefined' || obj === null
}

/**
 * Custom validator to check if a value is an empty collection.
 * @param {unknown} value - The value to check.
 * @returns {boolean} - True if the value is empty, false is the value is defined, undefined otherwise.
 */
export const isEmpty = (value) => {
  if (typeof value === 'undefined' || value === null) {
    return true
  }
  if (typeof value === 'string') {
    return value.trim().length === 0
  }
  if (Array.isArray(value)) {
    return value.length === 0
  }
  if (typeof value === 'object' &&
      value !== null &&
      !(value instanceof Date && !isNaN(value.getTime()))) {
    return Object.getOwnPropertyNames(value).length === 0
  }
  return false
}

/**
 * Custom validator to check if a value is truthy (not false | 0 | '' | null | undefined).
 * @param {unknown} value The value to check.
 * @returns {value is Exclude<any, false | 0 | '' | null | undefined>} True if the value is truthy, false otherwise.
 */
export const isTruthy = (value) => {
  return !!value
}
