import { getAsync } from '@sfc-enterprise-ui/fetch-api'
import GetCustomersResponseDto from '@/services/dto/GetCustomersResponseDto'

/**
 * @param {object} query
 * @param {string?} [query.warehouseNumber]
 * @param {string?} [query.salesRepNumber]
 * @param {string?} [query.customerNumber]
 * @param {string?} [query.commonCustomerNumber]
 * @returns {Promise<import('@/services/dto/GetCustomersResponseDto').default[]>}
 */
export const getCustomersAsync = async ({
  warehouseNumber = null,
  salesRepNumber = null,
  customerNumber = null,
  commonCustomerNumber = null
}) => {
  let query = ''

  if (warehouseNumber) {
    query += '&warehouse-number=' + warehouseNumber
  }
  if (salesRepNumber) {
    query += '&sales-rep-number=' + salesRepNumber
  }
  if (customerNumber) {
    query += '&customer-number=' + customerNumber
  }
  if (commonCustomerNumber) {
    query += '&common-customer-number=' + commonCustomerNumber
  }

  if (query) {
    query = '?' + query.slice(1)
  }

  const result = await getAsync(`/customers${query}`)

  return result.map((x) => new GetCustomersResponseDto(x))
}
