import { appConfig, injectionConstants } from '@/app-constants'
import { authenticateAndUpdateCachedTokenAsync } from '@sfc-enterprise-ui/msal-authentication'
import { createApp } from 'vue'
import { initializeRequestPipeline, MsalInterceptor } from '@sfc-enterprise-ui/fetch-api'
import { useOneErpCore } from '@one-erp/core/src'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs'
import envConfig from '@/env.config'
import msalConfig from '@/authentication/msal-config'
import router from '@/router'
import { vuePlugin as appInsightsPlugin } from '@sfc-enterprise-ui/application-insights/src'
import sfcApplicationInsightsInit from '@/services/application-insight-init'
import store from '@/stores/store'

import App from '@/app.vue'

authenticateAndUpdateCachedTokenAsync(msalConfig)
  .then((user) => {
    const app = createApp(App)

    app
      .use(store)
      .use(router)
      .use(appInsightsPlugin, { instrumentationKey: envConfig.appInsights.instrumentationKey })
      .provide(injectionConstants.dayjs, dayjs)

    window.getStore = () => {
      return store
    }

    const fetchConfiguration = {
      envConfig
    }

    const requestPipeline = [
      new MsalInterceptor(msalConfig)
    ]

    initializeRequestPipeline(fetchConfiguration, requestPipeline)
    useOneErpCore(envConfig.environment)
    dayjs.extend(customParseFormat)
    sfcApplicationInsightsInit(appConfig.name, appConfig.version)

    router.isReady().then(app.mount('#app'))
  })
