class GetReturnsReasonsResponseDto {
  constructor ({
    warehouseNumber = null,
    code = null,
    description = null
  }) {
    this.warehouseNumber = warehouseNumber
    this.code = code
    this.description = description
  }
}

export default GetReturnsReasonsResponseDto
