import { isEmpty } from '@sfc-enterprise-ui/utils/src'
import { PostInventoriesRequestDto, PostInventoriesWarehouseProductRequestDto, PostInventoriesProductRequestDto } from '@/services/dto/PostInventoriesRequestDto.js'
import { isDefined, isNumber } from '@/utilities/type-utility'

/**
 * Validates and maps the application model to the API endpoint DTO.
 *
 * @param {import('@/models/Inventory.js').default} inventory
 * @param {import('@/models/InventoryItem.js').default[]} inventoryItems
 * @returns {import('@/services/dto/PostInventoriesRequestDto').default}
 */
export const mapInventoryModelToPostInventoriesRequestDto = (inventory, inventoryItems) => {
  let errorMessage = ''

  // #region validate inventory model
  if (
    !inventory.forCustomerNumber ||
    !inventory.forUserNumber
  ) {
    throw new Error('Model validation failed')
  }
  // #endregion

  // #region validate line items models
  for (const inventoryItem of inventoryItems) {
    if (isEmpty(inventoryItem.product?.productNumber)) {
      errorMessage += 'Product Number is not valid. '
      continue
    }
    if (isEmpty(inventoryItem.product?.warehouseNumber)) {
      errorMessage += 'Warehouse Number is not valid. '
      continue
    }
    if (!isNumber(inventoryItem.warehouseQuantity) ||
       !isNumber(inventoryItem.shelfQuantity) ||
       !isNumber(inventoryItem.otherQuantity)) {
      errorMessage += `${inventoryItem.product?.productNumber} has an invalid quantity. `
    }
  }
  // #endregion

  if (errorMessage) {
    throw new Error(`Inventory count validation failed: ${errorMessage}`)
  }

  /** @type {{[key: string]: PostInventoriesWarehouseProductRequestDto}} */
  const warehouseObjsTable = {}

  for (const inventoryItem of inventoryItems) {
    const { warehouseNumber } = inventoryItem.product

    if (isDefined(warehouseNumber)) {
      if (!warehouseObjsTable[warehouseNumber]) {
        warehouseObjsTable[warehouseNumber] = new PostInventoriesWarehouseProductRequestDto({
          warehouseNumber,
          products: []
        })
      }

      const newLocal = new PostInventoriesProductRequestDto({
        productNumber: inventoryItem.product.productNumber,
        warehouseQuantity: inventoryItem.warehouseQuantity,
        shelfQuantity: (inventoryItem.shelfQuantity ?? 0) + (inventoryItem.otherQuantity ?? 0),
        unitOfMeasure: inventoryItem.unitOfMeasure
      })
      warehouseObjsTable[warehouseNumber].products.push(newLocal)
    }
  }

  const dto = new PostInventoriesRequestDto({
    customerNumber: inventory.forCustomerNumber,
    salesRepNumber: inventory.forUserNumber,
    warehouses: Object.values(warehouseObjsTable)
  })
  return dto
}
