const getters = {}

getters.isInitialized = (_, __, { global }) => {
  return global.isInitialized
}

getters.isLoading = (_, __, { global }) => {
  return global.isLoading
}

getters.hasError = (_, __, { global }) => {
  return global.hasError
}

getters.featureIsEnabledByName = (_, __, { global }) => {
  return (flagName) => {
    return global.flags[flagName] === true
  }
}

getters.allWarehouses = (_, __, { global }) => {
  return Object.values(global.warehouses)
}

getters.warehouseByCode = (_, __, { global }) => {
  return (warehouseCode) => {
    return Object
      .values(global.warehouses)
      .find(x => x.code === warehouseCode)
  }
}

getters.warehouseByNumber = (_, __, { global }) => {
  return (warehouseNumber) => {
    return Object
      .values(global.warehouses)
      .find(x => x.warehouseNumber === warehouseNumber)
  }
}

const names = {
  isInitialized: 'isInitialized',
  isLoading: 'isLoading',
  hasError: 'hasError',
  featureIsEnabledByName: 'featureIsEnabledByName',
  allWarehouses: 'allWarehouses',
  warehouseByCode: 'warehouseByCode',
  warehouseByNumber: 'warehouseByNumber'
}

const namespaced = {
  isInitialized: 'global/isInitialized',
  isLoading: 'global/isLoading',
  hasError: 'global/hasError',
  featureIsEnabledByName: 'global/featureIsEnabledByName',
  allWarehouses: 'global/allWarehouses',
  warehouseByCode: 'global/warehouseByCode',
  warehouseByNumber: 'global/warehouseByNumber'
}

export {
  names,
  namespaced,
  getters
}
