export const warehouses = {
  '001': {
    warehouseNumber: '001',
    warehouseName: 'Dairy',
    code: 'AZD',
    orderGuideNumbers: []
  },
  '007': {
    warehouseNumber: '007',
    warehouseName: 'Dairy Maid',
    code: 'DM',
    orderGuideNumbers: []
  }
}

export const warehouseCodes = {
  AZD: '001',
  DM: '007'
}
