import { setItem, removeItem } from '@/helpers/cache-helper'
import { mutations } from '@/stores/store'
import cacheConstants from '@/constants/cache-constants'

export const syncCachedSettings = (_) => {
  _.subscribe(({ type, payload }) => {
    switch (type) {
      case mutations.users.deactivate:
        setItem(cacheConstants.keys.activeUserNumber, _.state.users.current)
        break
      case mutations.customers.deactivate:
        removeItem(cacheConstants.keys.activeUserNumber)
        break
    }
  })
}
