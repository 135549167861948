<template>
  <one-erp-page-layout
    :use-default-header-css-classes="false"
    :use-default-content-css-classes="false"
    :use-default-sidebar-css-classes="false"
  >
    <template #header>
      <div class="customer-selection-view-masthead">
        <users-activate
          @loading="onLoading"
          :disabled="state.isLoading"
          show-orders-credits-counts
          use-header-style
        />
        <h2>Select a customer</h2>
      </div>
    </template>
    <template #content v-if="!global.isLoading.value">
      <erp-spinner :show-spinner="state.isLoading" />
      <div class="customer-selection-view-content">
        <div class="content" v-if="customers.isNotEmpty.value">
          <div class="multi-filter">
            <one-erp-multi-filter
              @update:filter-function="x => state.gridMultiFilterFunction = x"
              :filterable-options="state.gridMultiFilterOptions"
              filter-label="header"
              filter-value="field"
            />
          </div>
          <div class="multi-sort">
            <one-erp-sort-indicator
              @update:descending="onToggleMultiSortDescending"
              @update:sort-param="onSelectSetMultiSortParameter"
              :sortable-parameters="state.gridMultiSortParameters"
              :sort-param="state.gridMultiSortParameter"
              :descending="state.gridMultiSortDescending"
              sort-param-label="header"
              sort-param-value="field"
            />
          </div>
          <div class="grid">
            <customers-grid
              @customer-selected="onSelectCustomerAsync"
              :filters="[state.gridMultiFilterFunction]"
              :sorts="[state.gridMultiSortFunction]"
              select-button-text="Select"
              show-quick-stats
            />
          </div>
        </div>
        <div class="not-found" v-else-if="customers.isEmpty.value">
          <div class="page-message">
            <h1 class="page-message__header">
              {{
                `${users.currentIsActive.value
                  ? 'You do not have'
                  : users.active.value?.salesRepName + ' does not have'} any assigned customers.`
              }}
            </h1>
            <span class="page-message__text">Please contact support.</span>
          </div>
        </div>
      </div>
    </template>
  </one-erp-page-layout>
</template>

<script setup>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { routeNames } from '@/constants/route-constants'
import { map as storeMap, dispatch, actions, getters, select } from '@/stores/store'
import {
  MultiFilter as OneErpMultiFilter,
  PageLayout as OneErpPageLayout,
  Spinner as ErpSpinner,
  SortIndicator as OneErpSortIndicator
} from '@sfc-enterprise-ui/one-erp-components'
import CustomersGrid from '@/components/customers/customers-grid.vue'
import UsersActivate from '@/components/users/users-activate.vue'
import { customerFields } from '@/constants/customer-constants'

const { push } = useRouter()
const { customers, global, users } = storeMap()

const gridMultiFilterFunction = (a) => a

const gridMultiFilterOptions = [
  { header: 'Name', label: 'Name', field: customerFields.customerName, id: 1 },
  { header: 'Number', label: 'Number', field: customerFields.customerNumber, id: 2 }
]

const gridMultiSortFunction = (a, b) => {
  const desc = state.gridMultiSortDescending ? -1 : 1
  const c = a[state.gridMultiSortParameter.field]
  const d = b[state.gridMultiSortParameter.field]
  if (typeof c === 'string') {
    return c.localeCompare(d) * desc
  } else {
    return (c - d) * desc
  }
}

const gridMultiSortParameters = [
  { header: 'Name', field: customerFields.customerName },
  { header: 'Number', field: customerFields.customerNumber },
  { header: 'Started', field: customerFields.started },
  { header: 'Submitted', field: customerFields.submitted }
]

const gridMultiSortParameter = gridMultiSortParameters[0]

const gridMultiSortDescending = false

const state = reactive({
  gridMultiFilterFunction,
  gridMultiFilterOptions,
  gridMultiSortFunction,
  gridMultiSortParameters,
  gridMultiSortParameter,
  gridMultiSortDescending,
  isLoading: false
})

const onSelectCustomerAsync = async (value) => {
  await dispatch(
    actions.customers.activateAsync,
    getters[select.customers.byNumber](value)
  )
  push({ name: routeNames.dashboard })
}

const onSelectSetMultiSortParameter = (parameter) => {
  state.gridMultiSortParameter = parameter
}

const onToggleMultiSortDescending = () => {
  state.gridMultiSortDescending = !state.gridMultiSortDescending
}

const onLoading = (isLoading) => {
  state.isLoading = isLoading
  if (isLoading === false) {
    resetState()
  }
}

const resetState = () => {
  state.gridMultiFilterFunction = gridMultiFilterFunction
  state.gridMultiFilterOptions = gridMultiFilterOptions
  state.gridMultiSortFunction = gridMultiSortFunction
  state.gridMultiSortParameters = gridMultiSortParameters
  state.gridMultiSortParameter = gridMultiSortParameter
  state.gridMultiSortDescending = gridMultiSortDescending
}
</script>
<style lang="scss" scoped>
.customer-selection-view-masthead {
  padding: 1.8rem;
  h2 {
    font-family: "Overpass", sans-serif;
    font-size: 2rem;
    font-weight: bold;
    height: 2.2rem;
    letter-spacing: 0.5px;
    line-height: 2.2rem;
    margin-left: 2.2rem;
    padding: 0.6rem 0;
  }
}
.content {
  grid-template-areas:
    'multi-filter day-filter multi-sort'
    'grid grid grid';
  display: grid;
  grid-template-columns: 1fr auto auto;
  padding: 3rem 1.8rem 1.8rem;
}
.multi-filter {
  grid-area: multi-filter;
}
.day-filter {
  grid-area: day-filter;
}
.multi-sort {
  grid-area: multi-sort;
  padding: 1rem 0;
}
.grid {
  grid-area: grid;
}
</style>
