export const routeNames = {
  dashboard: 'dashboard',
  searchCustomers: 'select-customer',
  searchOrders: 'select-order',
  editOrder: 'orders',
  editInventories: 'inventories',
  manageProducts: 'manage-products'
}

export const routePaths = {
  dashboard: '/dashboard',
  searchCustomers: '/customers/search',
  searchOrders: '/orders/search',
  editOrder: '/orders/:orderNumber?',
  editInventory: '/inventories/:inventoryNumber?',
  manageProducts: '/customers/:customerNumber/products'
}

export const routeDisplayNames = {
  dashboard: 'Dashboard',
  selectCustomer: 'Customers',
  selectOrder: 'Orders',
  inventory: 'Inventories',
  manageProducts: 'Products'
}
