import { cacheKeys } from '@/constants/cache-constants'
import { setItem } from '@/helpers/cache-helper'
import { activate, addRange, clear, deactivate, removeRange } from '@/stores/modules/common/common.mutations'
import User from '@/models/User'
import { prependNamespace } from '@sfc-enterprise-ui/utils/src'

const keyName = 'salesRepNumber'
const names = {
  activate: 'activate',
  add: 'add',
  addRange: 'addRange',
  clear: 'clear',
  deactivate: 'deactivate',
  remove: 'remove',
  setCurrent: 'setCurrent'
}

const namespace = 'users/'
const namespaced = prependNamespace(namespace, names)
const mutations = {}

mutations.activate = (state, payload) => {
  try {
    activate(state, payload?.[keyName] || null)
    setItem(cacheKeys.activeUserNumber, payload?.[keyName] || null)
  }
  catch {
  }
}

mutations.add = (state, payload) => {
  return addRange(state, { items: [new User(payload)], keyName })
}

mutations.addRange = (state, payload) => {
  return addRange(state, { items: payload.map(x => new User(x)), keyName })
}

mutations.clear = (state) => {
  return clear(state)
}

mutations.deactivate = (state) => {
  const retVal = deactivate(state)
  if (state.current) {
    setItem(cacheKeys.activeUserNumber, state.current)
  }
  return retVal
}

mutations.remove = (state, payload) => {
  return removeRange(state, [payload])
}

mutations.setCurrent = (state, payload) => {
  state.current = payload?.[keyName]
}

export {
  names,
  namespaced,
  mutations
}
