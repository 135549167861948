import dayjs from 'dayjs'
import { unitOfMeasureNames } from '@/constants/orders-constants'
import LineItem from '@/models/LineItem'
import { addRange, clear, removeRange, updateRange } from '@/stores/modules/common/common.mutations'
import { moduleStateIsActivated, moduleStateHasItemByKey } from '@/stores/modules/common/common.validators'

const keyName = 'lineItemNumber'
const mutations = {}

mutations.add = (state, payload) => addRange(state, {
  items: [new LineItem(payload.value)],
  keyName
})

mutations.addRange = (state, payload) => addRange(state, {
  items: payload.value.map(x => (new LineItem(x))),
  keyName
})

mutations.clear = (state) => clear(state)

mutations.remove = (state, payload) => removeRange(state, [payload])

mutations.setCurrentQuantity = (state, { key, value }) => {
  moduleStateIsActivated(state)
  moduleStateHasItemByKey(state, key)
  if (value < 0) {
    value = 0
  }
  const lineItem = new LineItem(state.all[key])
  const parQuantity = lineItem.parQuantity || lineItem.product?.parQuantity
  let orderQuantity = lineItem.orderQuantity
  if (parQuantity) {
    if (lineItem.isUomEaches) {
      orderQuantity = (parQuantity - value > 0)
        ? parQuantity - value
        : 0
    }
    else if (lineItem.isUomCases) {
      orderQuantity = (parQuantity - value > 0)
        ? Math.ceil((parQuantity - value) / lineItem.product?.perCaseQuantity)
        : 0
    }
  }
  updateRange(state, {
    items: [new LineItem({
      ...lineItem,
      currentQuantity: value,
      orderQuantity,
      updatedAt: dayjs().toISOString()
    })],
    keyName
  })
}

mutations.setOrderQuantity = (state, { key, value }) => {
  moduleStateIsActivated(state)
  moduleStateHasItemByKey(state, key)
  if (value < 0) {
    value = 0
  }
  updateRange(state, {
    items: [new LineItem({
      ...state.all[key],
      orderQuantity: value,
      updatedAt: dayjs().toISOString()
    })],
    keyName
  })
}

mutations.setTotal = (state, payload) => {
  moduleStateIsActivated(state)
  const item = state.all[payload]
  let total = 0
  if (item.unitOfMeasure === unitOfMeasureNames.case) {
    total = item.orderQuantity * item.product.pricePerCase
  }
  else {
    total = item.orderQuantity * item.product.pricePerEach
  }

  updateRange(state, {
    items: [new LineItem({
      ...item,
      total,
      updatedAt: dayjs().toISOString()
    })],
    keyName
  })
}

mutations.setParQuantity = (state, { key, value }) => {
  moduleStateIsActivated(state)
  moduleStateHasItemByKey(state, key)
  if (value < 0) {
    value = 0
  }
  const lineItem = state.all[key]
  const currentQuantity = lineItem.currentQuantity
  let orderQuantity = lineItem.orderQuantity
  if (currentQuantity) {
    if (lineItem.isUomEaches) {
      orderQuantity = (value - currentQuantity > 0)
        ? value - currentQuantity
        : 0
    }
    else if (lineItem.isUomCases) {
      orderQuantity = (value - currentQuantity > 0)
        ? Math.ceil((value - currentQuantity) / lineItem.product?.perCaseQuantity)
        : 0
    }
  }
  updateRange(state, {
    items: [new LineItem({
      ...lineItem,
      parQuantity: value,
      orderQuantity,
      updatedAt: dayjs().toISOString()
    })],
    keyName
  })
}

mutations.setUnitOfMeasure = (state, { key, value }) => {
  moduleStateIsActivated(state)
  moduleStateHasItemByKey(state, key)
  const lineItem = new LineItem(state.all[key])
  const parQuantity = lineItem.parQuantity
  const currentQuantity = lineItem.currentQuantity
  let orderQuantity = lineItem.orderQuantity
  if (
    lineItem.unitOfMeasure === unitOfMeasureNames.each &&
    value === unitOfMeasureNames.case
  ) {
    orderQuantity = Math.ceil(orderQuantity / lineItem.product?.perCaseQuantity)
  }
  else if (
    lineItem.unitOfMeasure === unitOfMeasureNames.case &&
    value === unitOfMeasureNames.each &&
    (parQuantity - currentQuantity > 0)
  ) {
    orderQuantity = parQuantity - currentQuantity
  }
  updateRange(state, {
    items: [new LineItem({
      ...state.all[key],
      orderQuantity,
      unitOfMeasure: value,
      updatedAt: dayjs().toISOString()
    })],
    keyName
  })
}

mutations.setReasonCode = (state, { key, value }) => {
  state.all[key].reasonCode = value
}

mutations.updateRange = (state, payload) => updateRange(state, {
  items: payload.value,
  keyName
})

const names = {
  add: 'add',
  addRange: 'addRange',
  clear: 'clear',
  remove: 'remove',
  setCurrentQuantity: 'setCurrentQuantity',
  setOrderQuantity: 'setOrderQuantity',
  setTotal: 'setTotal',
  setParQuantity: 'setParQuantity',
  setUnitOfMeasure: 'setUnitOfMeasure',
  updateRange: 'updateRange',
  setReasonCode: 'setReasonCode'
}

const namespaced = {
  add: 'lineItems/add',
  addRange: 'lineItems/addRange',
  clear: 'lineItems/clear',
  remove: 'lineItems/remove',
  setCurrentQuantity: 'lineItems/setCurrentQuantity',
  setOrderQuantity: 'lineItems/setOrderQuantity',
  setTotal: 'lineItems/setTotal',
  setParQuantity: 'lineItems/setParQuantity',
  setUnitOfMeasure: 'lineItems/setUnitOfMeasure',
  updateRange: 'lineItems/updateRange',
  setReasonCode: 'lineItems/setReasonCode'
}

export {
  names,
  namespaced,
  mutations
}
