<template>
  <div class="customers-delivery-days">
    <div
      v-for="{ key, value, odd, delivery } in all"
      :class="`day${odd ? ' odd' : ''}${delivery ? ' delivery' : ' no-delivery'}`"
      :key="key"
    >
      {{ value }}
    </div>
  </div>
</template>
<script setup>
import { getters, select } from '@/stores/store'
import { computed } from 'vue'

const props = defineProps({
  customerNumber: {
    type: String,
    required: true
  },
  showOffDays: {
    type: Boolean,
    required: false,
    default: false
  }
})

const all = computed(() => {
  let odd = false
  const customer = getters[select.customers.byNumber](props.customerNumber)
  return customer?.abbreviatedDeliveryDays
    .filter(day => props.showOffDays ? true : day.delivery)
    .map(({ key, value, delivery }) => {
      odd = !odd
      return { key, value, odd, delivery }
    })
})
</script>
<style lang="scss" scoped>
.customers-delivery-days {
  display: grid;
  gap: 0.4rem;
  grid-template-columns: repeat(7, auto);
  height: 2rem;
  justify-content: start;
  .day {
    border-radius: 0.3rem;
    box-sizing: border-box;
    height: 2rem;
    line-height: 1.8rem;
    padding: 0.2rem 0.6rem 0;
    &.delivery {
      background-color: #dde0ec;
    }
    &.no-delivery {
      color: var(--text-alt-color);
    }
  }
}
</style>
