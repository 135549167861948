<template>
  <div
    v-if="(
      props.show &&
      global.featureIsEnabledByName.value(userImpersonationFeature)
    )"
    :class="'users-activate' + (props.useHeaderStyle ? ' header-style' : '')"
  >
    <one-erp-dropdown
      @update:model-value="onSelectChangeActiveUserAsync"
      :model-value="selected"
      :options="all"
      :disabled="props.disabled"
      :placeholder="users.isEmpty.value ? 'No users' : 'Select a user'"
      :use-header-style="props.useHeaderStyle"
    >
      <template #dropdown-item="{ dropdownItem }">
        <span class="label">
          {{ dropdownItem.label }}
        </span>
      </template>
    </one-erp-dropdown>
  </div>
</template>
<script setup>
import { getters, select, dispatch, actions, map as storeMap } from '@/stores/store'
import { userImpersonationFeature } from '@/constants/app-flag-constants'
import { computed } from 'vue'
import {
  Dropdown as OneErpDropdown
} from '@sfc-enterprise-ui/one-erp-components/src'

const { global, users } = storeMap()

const props = defineProps({
  show: {
    type: Boolean,
    required: false,
    default: true
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  useHeaderStyle: {
    type: Boolean,
    required: false,
    default: false
  },
  showOrdersCreditsCounts: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits([
  'change-active-user',
  'loading'
])

const selected = computed(() => {
  const user = getters[select.users.active]
  return {
    id: user.salesRepNumber,
    value: user.salesRepNumber,
    label: user.salesRepName
  }
})

const all = computed(() => {
  return getters[select.users.range](
    null,
    {
      sort: (a, b) => a.salesRepName < b.salesRepName ? -1 : 1,
      include: ['orders']
    }
  ).map(({ salesRepNumber, salesRepName, orders }) => ({
    id: salesRepNumber,
    value: salesRepNumber,
    label: salesRepName,
    showActivityIndicator: orders.hasOrderOrCreditByUser
  }))
})

const onSelectChangeActiveUserAsync = async ({ value }) => {
  try {
    emit('loading', true)
    await dispatch(actions.users.activateAsync, getters[select.users.byNumber](value))
    await dispatch(actions.customers.initializeAsync, getters[select.users.active])
    emit('change-active-user', value)
  }
  finally {
    emit('loading', false)
  }
}
</script>
<style lang="scss" scoped>
.label {
  padding-left: 1.7rem;
  position: relative;
}
</style>
