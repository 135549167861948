import { activate, addRange, clear, deactivate, removeRange, updateRange } from '@/stores/modules/common/common.mutations'
import { moduleStateIsActivated, moduleStateHasItemByKey } from '@/stores/modules/common/common.validators'
import dayjs from 'dayjs'
import Order from '@/models/Order'

const keyName = 'orderNumber'
const mutations = {}

mutations.activate = (state, payload) => {
  try {
    activate(state, payload)
  }
  catch {
  }
}

mutations.add = (state, payload) => {
  addRange(state, { items: [new Order(payload)], keyName })
}

mutations.addRange = (state, payload) => {
  addRange(state, { items: payload.map(x => new Order(x)), keyName })
}

mutations.addReasonCodes = (state, payload) => {
  state.reasonCodes = payload
}

mutations.clear = (state) => {
  clear(state)
}

mutations.deactivate = (state) => {
  deactivate(state)
}

mutations.remove = (state, payload) => {
  removeRange(state, [payload])
}

mutations.setOrderDeliveryDate = (state, payload) => {
  moduleStateIsActivated(state)
  moduleStateHasItemByKey(state, payload.key)
  updateRange(state, {
    items: [new Order({
      ...state.all[payload.key],
      deliveredAt: payload.value,
      updatedAt: dayjs().toISOString()
    })],
    keyName
  })
}

mutations.setOrderSubmissionDate = (state, payload) => {
  moduleStateIsActivated(state)
  moduleStateHasItemByKey(state, payload[keyName])
  updateRange(state, {
    items: [new Order({
      ...state.all[payload[keyName]],
      submittedAt: dayjs().toISOString(),
      updatedAt: dayjs().toISOString()
    })],
    keyName
  })
}

mutations.setOrderTotal = (state, payload) => {
  moduleStateIsActivated(state)
  moduleStateHasItemByKey(state, payload.key)
  updateRange(state, {
    items: [new Order({
      ...state.all[payload.key],
      total: payload.value,
      updatedAt: dayjs().toISOString()
    })],
    keyName
  })
}

mutations.setOrderType = (state, payload) => {
  moduleStateIsActivated(state)
  moduleStateHasItemByKey(state, payload.key)
  updateRange(state, {
    items: [new Order({
      ...state.all[payload.key],
      type: payload.value,
      updatedAt: dayjs().toISOString()
    })],
    keyName
  })
}

mutations.setOrderUpdatedAt = (state, payload) => {
  moduleStateIsActivated(state)
  moduleStateHasItemByKey(state, payload[keyName])
  updateRange(state, {
    items: [new Order({
      ...state.all[payload[keyName]],
      updatedAt: dayjs().toISOString()
    })],
    keyName
  })
}

mutations.setPurchaseOrderNumber = (state, payload) => {
  moduleStateIsActivated(state)
  moduleStateHasItemByKey(state, payload.key)
  updateRange(state, {
    items: [new Order({
      ...state.all[payload.key],
      purchaseOrderNumber: payload.value,
      updatedAt: dayjs().toISOString()
    })],
    keyName
  })
}

const names = {
  activate: 'activate',
  add: 'add',
  addRange: 'addRange',
  addReasonCodes: 'addReasonCodes',
  clear: 'clear',
  deactivate: 'deactivate',
  remove: 'remove',
  setOrderDeliveryDate: 'setOrderDeliveryDate',
  setOrderSubmissionDate: 'setOrderSubmissionDate',
  setOrderTotal: 'setOrderTotal',
  setOrderType: 'setOrderType',
  setPurchaseOrderNumber: 'setPurchaseOrderNumber',
  setOrderUpdatedAt: 'setOrderUpdatedAt'
}

const namespaced = {
  activate: 'orders/activate',
  add: 'orders/add',
  addRange: 'orders/addRange',
  addReasonCodes: 'orders/addReasonCodes',
  clear: 'orders/clear',
  deactivate: 'orders/deactivate',
  remove: 'orders/remove',
  setOrderDeliveryDate: 'orders/setOrderDeliveryDate',
  setOrderSubmissionDate: 'orders/setOrderSubmissionDate',
  setOrderTotal: 'orders/setOrderTotal',
  setOrderType: 'orders/setOrderType',
  setPurchaseOrderNumber: 'orders/setPurchaseOrderNumber',
  setOrderUpdatedAt: 'orders/setOrderUpdatedAt'
}

export {
  names,
  namespaced,
  mutations
}
