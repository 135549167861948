class GetProductsHistoryResponseDto {
  constructor ({
    warehouseNumber = null,
    customerNumber = null,
    productNumber = null,
    lastOrderedQuantity = null,
    avgSalesInEaches = null,
    avgSalesInCases = null,
    avgCreditsInEaches = null,
    avgCreditsInCases = null
  }) {
    this.warehouseNumber = warehouseNumber
    this.customerNumber = customerNumber
    this.productNumber = productNumber
    this.lastOrderedQuantity = lastOrderedQuantity
    this.avgSalesInEaches = avgSalesInEaches
    this.avgSalesInCases = avgSalesInCases
    this.avgCreditsInEaches = avgCreditsInEaches
    this.avgCreditsInCases = avgCreditsInCases
  }
}

export default GetProductsHistoryResponseDto
