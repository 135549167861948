const _isTrue = (val) => ['true', '1'].includes(val.toLowerCase())

export const api = {
  baseUrl: 'http://localhost:5000'
}

export const appInsights = {
  instrumentationKey: 'baf70396-f899-47b5-a344-cf3b0a0a77b1'
}

export const msal = {
  auth: {
    authority: 'https://login.microsoftonline.com/shamrockfoods.com',
    clientId: 'a4571d23-a4ee-4fb3-a5cb-0e3be4cda94d'
  }
}

export const logging = {
  logLevel: 4
}

export const environment = 'Dev'

export const scopes = {
  apiGatewayAccess: 'https://shamrockfoods.com/sfc-dairysales-dev-web/access_api_gateway'
}

export const links = {
  userGuide: 'https://shamrock365.sharepoint.com/:w:/r/sites/Nike/Shared%20Documents/Initiative%20-%20DairySales/Dairy%20Sales%20FDD.docx?d=w3acc71a0a6fa47319af94849ce48bec8&csf=1&web=1&e=OsMZlv'
}

export const caching = {
  orderCutoffHourOfDay: parseInt('20') // 8:00pm AZ
}

export const flags = {
  enableUserGuideLink: _isTrue('true'),
  enableUserImpersonationFeature: _isTrue('true'),
  enableSelectCustomerViewFeature: _isTrue('true'),
  enableSelectOrderViewFeature: _isTrue('false'),
  enableFilterCustomersByDeliveryDayFeature: _isTrue('false')
}

export const orders = {
  deliveryWindowDelayDays: parseInt('2'),
  deliveryWindowTotalDays: parseInt('28')
}
