import dayjs from 'dayjs'
import ProductInventory from '@/models/ProductInventory'
/** @typedef {import('dayjs').Dayjs} Dayjs */
/**
 * @typedef {object} InventoryItemDto
 * @property {number?=} inventoryItemNumber
 * @property {string?=} inventoryNumber
 * @property {number?=} warehouseQuantity
 * @property {number?=} shelfQuantity
 * @property {number?=} otherQuantity
 * @property {number?=} subtotalQuantity
 * @property {string=} unitOfMeasure
 * @property {string?=} updatedAt
 * @property {ProductInventory | {}} product
*/
class InventoryItem {
  /** @param {InventoryItemDto} dto */
  constructor ({
    inventoryItemNumber = null,
    inventoryNumber = null,
    warehouseQuantity = null,
    shelfQuantity = null,
    otherQuantity = null,
    subtotalQuantity = null,
    unitOfMeasure = 'EA',
    updatedAt = null,
    product = {}
  }) {
    /** @type {number?} */
    this.inventoryItemNumber = inventoryItemNumber
    /** @type {string?} */
    this.inventoryNumber = inventoryNumber
    /** @type {number?} */
    this.warehouseQuantity = warehouseQuantity
    /** @type {number?} */
    this.shelfQuantity = shelfQuantity
    /** @type {number?} */
    this.otherQuantity = otherQuantity
    /** @type {number?} */
    this.subtotalQuantity = subtotalQuantity
    /** @type {string} */
    this.unitOfMeasure = unitOfMeasure
    /** @type {Dayjs?} */
    this.updatedAt = dayjs(updatedAt)
    /** @type {import('@/models/ProductInventory').default} */
    this.product = new ProductInventory(product)
  }
}

export default InventoryItem
