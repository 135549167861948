<template>
  <one-erp-modal
    @cancel="modalAsync.cancelFn"
    @confirm="modalAsync.confirmFn"
    :show-modal="modalAsync.active"
    :width="OneErpModalSizes.small"
    :overflow-y="OneErpOverflowYConstants.auto"
    :confirm-button-text="modalAsync.confirmText"
    :cancel-button-text="modalAsync.cancelText"
  >
    <template #header>
      <h2>{{ modalAsync.headerText }}</h2>
    </template>
    <template #content>
      <div class="confirm-delete">
        <span class="bigger-text" v-html="modalAsync.bodyText" />
      </div>
    </template>
  </one-erp-modal>
</template>

<script setup>
import {
  Modal as OneErpModal,
  ModalSizes as OneErpModalSizes,
  OverflowYValues as OneErpOverflowYConstants
} from '@sfc-enterprise-ui/one-erp-components/src'
import modalAsync from '@/components/shared/use-modal-async'
// eslint-disable-next-line no-undef
defineExpose({
  /** @type {typeof modalAsync} */
  modalAsync
})
</script>
