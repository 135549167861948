import { activate, addRange, clear, deactivate, removeRange, updateRange } from '@/stores/modules/common/common.mutations'
import { moduleStateIsActivated, moduleStateHasItemByKey } from '@/stores/modules/common/common.validators'
import dayjs from 'dayjs'
import Inventory from '@/models/Inventory'

const keyName = 'inventoryNumber'

const namespace = 'inventories/'

const names = {
  activate: 'activate',
  add: 'add',
  addRange: 'addRange',
  clear: 'clear',
  deactivate: 'deactivate',
  remove: 'remove',
  setInventorySubmissionDate: 'setInventorySubmissionDate'
}

const mutations = {
  [names.activate]: (state, payload) => {
    activate(state, payload)
  },

  [names.add]: (state, payload) => {
    addRange(state, { items: [new Inventory(payload)], keyName })
  },

  [names.addRange]: (state, payload) => {
    addRange(state, { items: payload.map(x => new Inventory(x)), keyName })
  },

  [names.clear]: (state) => {
    clear(state)
  },

  [names.deactivate]: (state) => {
    deactivate(state)
  },

  [names.remove]: (state, payload) => {
    removeRange(state, [payload])
  },

  [names.setInventorySubmissionDate]: (state, payload) => {
    moduleStateIsActivated(state)
    moduleStateHasItemByKey(state, payload[keyName])
    updateRange(state, {
      items: [new Inventory({
        ...state.all[payload[keyName]],
        submittedAt: dayjs().toISOString(),
        updatedAt: dayjs().toISOString()
      })],
      keyName,
      transform: null
    })
  }
}

const namespaced = {
  [names.activate]: `${namespace}${names.activate}`,
  [names.add]: `${namespace}${names.add}`,
  [names.addRange]: `${namespace}${names.addRange}`,
  [names.clear]: `${namespace}${names.clear}`,
  [names.deactivate]: `${namespace}${names.deactivate}`,
  [names.remove]: `${namespace}${names.remove}`,
  [names.setInventorySubmissionDate]: `${namespace}${names.setInventorySubmissionDate}`
}

export {
  names,
  namespaced,
  mutations
}
