import { active, find, first, filter, byKey, isEmpty, isNotEmpty, range, single } from '@/stores/modules/common/common.getters'

const keyName = 'orderNumber' // TODO put all key names in one file
const reducer = (order, { customers, lineItems, users }, { include }) => {
  const retVal = { ...order || {} }

  if (include?.includes('customer')) {
    retVal.customer = byKey(customers, order.forCustomerNumber) || null
  }

  if (include?.includes('lineItems')) {
    retVal.lineItems = filter(lineItems, x => x.orderNumber === order[keyName])
  }

  if (include?.includes('user')) {
    retVal.user = byKey(users, order.byUserNumber) || null
  }

  return retVal
}

const getters = {}

getters.active = (state) => active(state)

getters.byCustomerNumber = (state) => (key) => find(state, x => x.forCustomerNumber === key)

getters.byNumber = (state) => (key) => byKey(state, key)

getters.find = (state) => (searchPredicate) => find(state, searchPredicate)

getters.first = (state) => first(state)

getters.isEmpty = (state) => isEmpty(state)

getters.isNotEmpty = (state) => isNotEmpty(state)

getters.reasonCodes = (state) => Object.values(state.reasonCodes)

getters.reasonCodesByWarehouse = (state) => (key) => state.reasonCodes[key]

getters.range = (state, _, rootState) => (where, options) => {
  return range(state, rootState, reducer, where, options)
}

getters.single = (state, _, rootState) => (where, options) => {
  return single(state, rootState, reducer, where, options)
}

const names = {
  active: 'active',
  byCustomerNumber: 'byCustomerNumber',
  byNumber: 'byNumber',
  find: 'find',
  first: 'first',
  isEmpty: 'isEmpty',
  isNotEmpty: 'isNotEmpty',
  reasonCodes: 'reasonCodes',
  reasonCodesByWarehouse: 'reasonCodesByWarehouse',
  range: 'range',
  single: 'single'
}

const namespaced = {
  active: 'orders/active',
  byCustomerNumber: 'orders/byCustomerNumber',
  byNumber: 'orders/byNumber',
  find: 'orders/find',
  first: 'orders/first',
  isEmpty: 'orders/isEmpty',
  isNotEmpty: 'orders/isNotEmpty',
  reasonCodes: 'orders/reasonCodes',
  reasonCodesByWarehouse: 'orders/reasonCodesByWarehouse',
  range: 'orders/range',
  single: 'orders/single'
}

export {
  names,
  namespaced,
  getters
}
