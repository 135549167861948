import BaseProduct from '@/models/base/BaseProduct'

/** @typedef {import('@/models/interfaces/IProductCellRenderer').IProductCellRenderer} IProductCellRenderer */
/** @typedef {import('@/models/interfaces/IDescriptionCellRenderer').IDescriptionCellRenderer} IDescriptionCellRenderer */

/**
 * @typedef {object} ProductManageDto
 * @property {string?=} warehouseNumber
 * @property {string?=} customerNumber
 * @property {string?=} productNumber
 * @property {string?=} productDescription
 * @property {string?=} vendorName
 * @property {number?=} sequenceGroupNumber
 * @property {string?=} sequenceGroupDescription
 * @property {boolean=} shouldHideSku
*/

/** @implements {IProductCellRenderer} */
/** @implements {IDescriptionCellRenderer} */
class ProductManage extends BaseProduct {
  /** @param {ProductManageDto} dto */
  constructor ({
    warehouseNumber = null,
    customerNumber = null,
    productNumber = null,
    productDescription = null,
    vendorName = null,
    sequenceGroupNumber = null,
    sequenceGroupDescription = null,
    shouldHideSku = false
  }) {
    super({ warehouseNumber })
    /** @type {string?} */
    this.customerNumber = customerNumber
    /** @type {string?} */
    this.productNumber = productNumber
    /** @type {string?} */
    this.productDescription = productDescription
    /** @type {string?} */
    this.vendorName = vendorName
    /** @type {number?} */
    this.sequenceGroupNumber = sequenceGroupNumber
    /** @type {string?} */
    this.sequenceGroupDescription = sequenceGroupDescription
    /** @type {boolean} */
    this.shouldHideSku = shouldHideSku
  }
}

export default ProductManage
