import dayjs from 'dayjs'

const _getCollection = (collectionName) => {
  let collection

  try {
    collection = JSON.parse(localStorage.getItem(collectionName)) || []
  }
  catch {
    collection = null
  }

  if (!collection) {
    return null
  }
  collection = collection
    .map(x => (!x.exp || x.exp >= dayjs().toISOString()) ? x : null)
    .filter(x => x !== null)
    .map(({ exp, min, ...rest }) => ({
      ...rest,
      exp: min ? dayjs().add(min, 'm').toISOString() : exp,
      min
    }))

  localStorage.setItem(collectionName, JSON.stringify(collection))

  return collection
}

const _setCollection = (collectionName, collection) => {
  collection.forEach(({ exp, min }) => {
    if (min) {
      exp = dayjs().add(min, 'm').toISOString()
    }
  })

  localStorage.setItem(collectionName, JSON.stringify(collection))
}

export const getCollection = (collectionName) => {
  const collection = _getCollection(collectionName)

  if (collection) {
    return collection.map(x => x.val)
  }

  return null
}

export const getCollectionItem = (collectionName, key) => {
  const collection = _getCollection(collectionName)

  if (collection) {
    return collection.find(x => x.key === key)?.val || null
  }

  return null
}

export const addCollectionItem = (collectionName, key, value, options) => {
  const collection = _getCollection(collectionName)

  if (collection.find(x => x.key === key)) {
    return false
  }

  if (!options?.exp && options?.min) {
    options.exp = dayjs().add(options.min, 'm').toISOString()
  }

  collection.push({
    key,
    val: value,
    exp: (!options?.exp && options?.min) ? dayjs().add(options.min, 'm').toISOString() : options?.exp || null,
    min: options?.min || null
  })

  _setCollection(collectionName, collection)

  return true
}

export const putCollectionItem = (collectionName, key, value) => {
  const collection = _getCollection(collectionName)
  const item = collection.find(x => x.key === key)
  const itemIdx = collection.findIndex(x => x.key === key)
  let val

  if (!item || itemIdx === -1) {
    return false
  }

  if (typeof value === 'function') {
    val = value(item.val) || item.val
  }
  else {
    val = value
  }

  collection.splice(itemIdx, 1, {
    key: item.key,
    val,
    exp: (item.exp && item.min) ? dayjs().add(item.min, 'm').toISOString() : item.exp,
    min: item.min
  })

  _setCollection(collectionName, collection)

  return true
}

export const removeCollectionItem = (collectionName, key) => {
  const collection = _getCollection(collectionName)
  const itemIdx = collection.findIndex(x => x.key === key)

  if (itemIdx === -1) {
    return false
  }

  collection.splice(itemIdx, 1)

  _setCollection(collectionName, collection)

  return true
}

export const getItem = (key) => {
  let parsedObject
  const item = localStorage.getItem(key)

  if (item === null) {
    return null
  }

  try {
    parsedObject = JSON.parse(localStorage.getItem(key))
  }
  catch {
    return null
  }

  return parsedObject
}

export const setItem = (key, item) => {
  localStorage.setItem(key, JSON.stringify(item))

  return null
}

export const removeItem = (key) => {
  localStorage.removeItem(key)

  return null
}
