<template>
  <div v-if="props.show" class="customers-activate">
    <one-erp-dropdown
      @update:model-value="onSelectActivateCustomerAsync"
      :model-value="modelValue"
      :options="options"
      :disabled="props.disabled"
      :placeholder="customers.isEmpty.value ? 'No customers' : 'Select a customer'"
      :use-header-style="props.useHeaderStyle"
      :class="'customers-activate' + (props.useHeaderStyle ? ' header-style' : '')"
    >
      <template #dropdown-item="{ dropdownItem }">
        <span class="label">
          {{ dropdownItem.label }}
        </span>
      </template>
    </one-erp-dropdown>
  </div>
</template>
<script setup>
import { state, getters, select, dispatch, actions, map as storeMap } from '@/stores/store'
import { computed } from 'vue'
import {
  Dropdown as OneErpDropdown
} from '@sfc-enterprise-ui/one-erp-components'

const { customers } = storeMap()

const props = defineProps({
  show: {
    type: Boolean,
    required: false,
    default: true
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  useHeaderStyle: {
    type: Boolean,
    required: false,
    default: false
  },
  showOrdersCreditsCounts: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits([
  'change-active-customer'
])

const modelValue = computed(() =>
  getters[select.customers.byNumber](state.customers.active)?.templateDropdown)

const options = computed(() =>
  getters[select.customers.values]
    .map((x) => x.templateDropdown)
    .sort((a, b) => a.label < b.label ? -1 : 1))

const onSelectActivateCustomerAsync = async ({ value }) => {
  await dispatch(
    actions.customers.activateAsync,
    getters[select.customers.byNumber](value)
  )
  emit('change-active-customer', value)
}
</script>
<style lang="scss" scoped>
.label {
  padding-left: 1.7rem;
  position: relative;
}
</style>
