export const unitOfMeasureCode = {
  each: 'EA',
  case: 'CS'
}

export const typeValue = {
  order: 0,
  credit: 1
}

export const typeName = {
  order: 'order',
  credit: 'credit'
}
