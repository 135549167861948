import Address from '@/models/Address'

class Customer {
  /**
   * @param {object} dto
   * @param {string?} [dto.customerNumber]
   * @param {string?} [dto.userNumber]
   * @param {string?} [dto.customerName]
   * @param {string[]} [dto.deliveryDays]
   * @param {boolean?} [dto.hasConsignedInventory]
   * @param {Address} [dto.address]
   */
  constructor ({
    customerNumber = null,
    userNumber = null,
    customerName = null,
    deliveryDays = [],
    hasConsignedInventory = null,
    address = /** @type {Address} */({})
  }) {
    /** @type {string?} */
    this.customerNumber = customerNumber
    /** @type {string?} */
    this.userNumber = userNumber
    /** @type {string?} */
    this.customerName = customerName
    /** @type {string[]} */
    this.deliveryDays = deliveryDays
    /** @type {boolean?} */
    this.hasConsignedInventory = hasConsignedInventory
    /** @type {Address} */
    this.address = new Address(address)
  }

  get indexedOffDays () {
    const days = [0, 1, 2, 3, 4, 5, 6]
    this.deliveryDays?.forEach(day => {
      switch (day) {
        case 'sunday':
          days.splice(days.indexOf(0), 1)
          break
        case 'monday':
          days.splice(days.indexOf(1), 1)
          break
        case 'tuesday':
          days.splice(days.indexOf(2), 1)
          break
        case 'wednesday':
          days.splice(days.indexOf(3), 1)
          break
        case 'thursday':
          days.splice(days.indexOf(4), 1)
          break
        case 'friday':
          days.splice(days.indexOf(5), 1)
          break
        case 'saturday':
          days.splice(days.indexOf(6), 1)
          break
      }
    })
    return days
  }

  get indexedDeliveryDays () {
    /** @type {number[]} */
    const days = []
    this.deliveryDays?.forEach(day => {
      switch (day) {
        case 'sunday':
          days.push(0)
          break
        case 'monday':
          days.push(1)
          break
        case 'tuesday':
          days.push(2)
          break
        case 'wednesday':
          days.push(3)
          break
        case 'thursday':
          days.push(4)
          break
        case 'friday':
          days.push(5)
          break
        case 'saturday':
          days.push(6)
          break
      }
    })
    return days
  }

  get abbreviatedDeliveryDays () {
    const map = {
      sunday: { key: 0, value: 'sun', delivery: false },
      monday: { key: 1, value: 'mon', delivery: false },
      tuesday: { key: 2, value: 'tues', delivery: false },
      wednesday: { key: 3, value: 'wed', delivery: false },
      thursday: { key: 4, value: 'thurs', delivery: false },
      friday: { key: 5, value: 'fri', delivery: false },
      saturday: { key: 6, value: 'sat', delivery: false }
    }
    this.deliveryDays?.forEach(day => {
      if (map[day]) {
        map[day].delivery = true
      }
    })
    return Object.values(map)
  }

  get templateDropdown () {
    return {
      id: this.customerNumber,
      value: this.customerNumber,
      label: this.customerName
    }
  }
}

export default Customer
