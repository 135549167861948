class GetProductsInventoryResponseDto {
  /**
   * @param {object} dto
   * @param {string?} [dto.warehouseNumber]
   * @param {string?} [dto.customerNumber]
   * @param {string?} [dto.productNumber]
   * @param {string?} [dto.productDescription]
   * @param {string?} [dto.store]
   * @param {string?} [dto.packSizeDescription]
   * @param {string?} [dto.vendorName]
   * @param {number?} [dto.eachesPerCase]
   * @param {string?} [dto.classDescription]
   * @param {number?} [dto.sequenceGroupNumber]
   * @param {string?} [dto.sequenceGroupDescription]
   * @param {string?} [dto.itemSource]
   */
  constructor ({
    warehouseNumber = null,
    customerNumber = null,
    productNumber = null,
    productDescription = null,
    store = null,
    packSizeDescription = null,
    vendorName = null,
    eachesPerCase = null,
    classDescription = null,
    sequenceGroupNumber = null,
    sequenceGroupDescription = null,
    itemSource = null
  }) {
    /** @type {string?} */
    this.warehouseNumber = warehouseNumber
    /** @type {string?} */
    this.customerNumber = customerNumber
    /** @type {string?} */
    this.productNumber = productNumber
    /** @type {string?} */
    this.productDescription = productDescription
    /** @type {string?} */
    this.store = store
    /** @type {string?} */
    this.packSizeDescription = packSizeDescription
    /** @type {string?} */
    this.vendorName = vendorName
    /** @type {number?} */
    this.eachesPerCase = eachesPerCase
    /** @type {string?} */
    this.classDescription = classDescription
    /** @type {number?} */
    this.sequenceGroupNumber = sequenceGroupNumber
    /** @type {string?} */
    this.sequenceGroupDescription = sequenceGroupDescription
    /** @type {string?} */
    this.itemSource = itemSource
  }
}

export default GetProductsInventoryResponseDto
