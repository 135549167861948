import { filter, byKey, active, first, exists, isEmpty, isNotEmpty, range, single, values } from '@/stores/modules/common/common.getters'
import { orderTypeNames } from '@/constants/orders-constants'

const reducer = (customer, { orders, users }, { include }) => {
  const retVal = { ...customer || {} }

  if (include?.includes('orders')) {
    const filterForCustomer = x => x.forCustomerNumber === customer.customerNumber
    const filterOrders = x => x.type === orderTypeNames.order
    const filterCredits = x => x.type === orderTypeNames.credit
    const filterInProgress = x => !x.submittedAt
    const filterSubmitted = x => !!x.submittedAt
    retVal.orders = {}
    retVal.orders.inProgressOrders = filter(orders, [filterForCustomer, filterInProgress, filterOrders])
    retVal.orders.inProgressCredits = filter(orders, [filterForCustomer, filterInProgress, filterCredits])
    retVal.orders.submittedOrders = filter(orders, [filterForCustomer, filterSubmitted, filterOrders])
    retVal.orders.submittedCredits = filter(orders, [filterForCustomer, filterSubmitted, filterCredits])
    retVal.orders.hasInProgressOrderOrCredit = (
      !!retVal.orders.inProgressOrders.length ||
      !!retVal.orders.inProgressCredits.length
    )
    retVal.orders.hasSubmittedOrderOrCredit = (
      !!retVal.orders.submittedOrders.length ||
      !!retVal.orders.submittedCredits.length
    )
    retVal.orders.hasOrderOrCredit = (
      !!retVal.orders.inProgressOrders.length ||
      !!retVal.orders.inProgressCredits.length ||
      !!retVal.orders.submittedOrders.length ||
      !!retVal.orders.submittedCredits.length
    )
  }

  if (include?.includes('user')) {
    retVal.user = byKey(users, customer.userNumber) || null
  }

  return retVal
}

const getters = {}

getters.active = (state) => active(state)

getters.byNumber = (state) => (key) => byKey(state, key)

getters.first = (state) => first(state)

getters.existsByNumber = (state) => (key) => exists(state, x => x.salesRepNumber === key)

getters.existsByUserNumber = (state) => (key) => exists(state, x => x.userNumber === key)

getters.isEmpty = (state) => isEmpty(state)

getters.isNotEmpty = (state) => isNotEmpty(state)

getters.range = (state, _, rootState) => (where, options) => {
  return range(state, rootState, reducer, where, options)
}

getters.single = (state, _, rootState) => (where, options) => {
  return single(state, rootState, reducer, where, options)
}

getters.values = (state) => values(state)

const names = {
  active: 'active',
  byNumber: 'byNumber',
  first: 'first',
  existsByNumber: 'existsByNumber',
  existsByUserNumber: 'existsByUserNumber',
  isEmpty: 'isEmpty',
  isNotEmpty: 'isNotEmpty',
  range: 'range',
  single: 'single',
  values: 'values'
}

const namespaced = {
  active: 'customers/active',
  byNumber: 'customers/byNumber',
  first: 'customers/first',
  existsByNumber: 'customers/existsByNumber',
  existsByUserNumber: 'customers/existsByUserNumber',
  isEmpty: 'customers/isEmpty',
  isNotEmpty: 'customers/isNotEmpty',
  range: 'customers/range',
  single: 'customers/single',
  values: 'customers/values'
}

export {
  names,
  namespaced,
  getters
}
