<template>
  <div
    id="vue-app"
    :style="theme.themeCssVars"
    :class="theme.themeClass"
  >
    <header>
      <app-menu />
    </header>
    <main>
      <router-view v-slot="{ Component }" name="content">
        <erp-spinner :show-spinner="global.isLoading.value" />
        <transition name="fade" appear>
          <component :is="Component" />
        </transition>
      </router-view>
      <one-erp-modal
        @cancel="resetState"
        :width="modalSizesConstants.small"
        :show-modal="state.showConfirmErrorModal"
        :overflow-y="overflowConstants.auto"
        confirm-button-text="Got it"
      >
        <template #header />
        <template #content>
          <div class="confirm-error">
            <one-erp-svg
              :svg-color="colorConstants.onyx"
              :svg-background-color="colorConstants.transparent"
              :svg-size="3.4"
              :svg-name="svgConstants.errorCircle"
            />
            <h2>{{ state.confirmErrorModalHeader }}</h2>
            <span>{{ state.confirmErrorModalMessage }}</span>
          </div>
        </template>
        <template #footer>
          <one-erp-button
            @click="resetState"
            :button-type="oneErpButtonTypes.solid"
            button-text="Got it"
          />
        </template>
      </one-erp-modal>
    </main>
  </div>
</template>

<script setup>
import '@/assets/styles.scss'
import '@fortawesome/fontawesome-pro/css/all.css'
import { map as storeMap } from '@/stores/store'
import { onErrorCaptured, reactive } from 'vue'
import {
  Button as OneErpButton,
  ButtonTypes as oneErpButtonTypes,
  Modal as OneErpModal,
  ModalSizes as modalSizesConstants,
  OneErpColors as colorConstants,
  OverflowYValues as overflowConstants,
  Spinner as ErpSpinner,
  SvgChooser as OneErpSvg,
  SvgConstants as svgConstants,
  useTheme
} from '@sfc-enterprise-ui/one-erp-components'

import AppMenu from '@/components/app-menu.vue'

const theme = useTheme()

const { global } = storeMap()

const state = reactive({
  showConfirmErrorModal: false,
  /** @type {String?} */
  confirmErrorModalHeader: null,
  /** @type {String?} */
  confirmErrorModalMessage: null
})

onErrorCaptured((err) => {
  // eslint-disable-next-line no-console
  console.error(err)
  if (err.name === 'UserVisibleError') {
    /** @type {import('@/utilities/errors/raise-user-error').UserVisibleError} */
    const userVisibleError = /** @type {any} err */ (err)
    state.confirmErrorModalHeader = userVisibleError.userMessage
    state.confirmErrorModalMessage = `${userVisibleError.apiMessage} ${userVisibleError.userInstructions}`
  }
  else {
    state.confirmErrorModalHeader = 'An error occurred.'
    state.confirmErrorModalMessage = 'Please contact support.'
  }
  state.showConfirmErrorModal = true
  return false
})

const resetState = () => {
  state.showConfirmErrorModal = false
  state.confirmErrorModalHeader = null
  state.confirmErrorModalMessage = null
}
</script>
<style lang="scss" scoped>
.confirm-error {
  grid-template-areas:
    "icon heading"
    "message message";
  display: grid;
  gap: 1rem;
  grid-template-columns: 3.4rem auto;
  grid-template-rows: 3.4rem auto;
  justify-content: center;
  row-gap: 2rem;
  h2 {
    grid-area: heading;
    line-height: 2.8rem;
    padding-top: 0.6rem;
  }
  span {
    font-size: 1.8rem;
    grid-area: message;
    line-height: 3.4rem;
    text-align: center;
  }
}
</style>
