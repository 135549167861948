/**
 * @typedef {object} BaseProductDto
 * @property {string?=} warehouseNumber
*/

class BaseProduct {
  /** @param {BaseProductDto} dto */
  constructor ({
    warehouseNumber = null
  }) {
    /** @type {string?} */
    this.warehouseNumber = warehouseNumber
  }

  get warehouseName () {
    switch (this.warehouseNumber) {
      case '001':
        return 'Dairy'
      case '007':
        return 'Dairy Maid'
      default:
        return 'Unknown'
    }
  }
}

export default BaseProduct
