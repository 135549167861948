const _isTrue = (val) => ['true', '1'].includes(val.toLowerCase())

export const api = {
  baseUrl: 'https://sfc-dairysales-prod-api.azurewebsites.net'
}

export const appInsights = {
  instrumentationKey: 'c980d9f1-ff74-4929-80a2-53c026b83060'
}

export const msal = {
  auth: {
    authority: 'https://login.microsoftonline.com/shamrockfoods.com',
    clientId: '9f2a2ee6-94c7-4291-a3c0-b027f619aa41'
  }
}

export const logging = {
  logLevel: '4'
}

export const environment = 'prod'

export const scopes = {
  apiGatewayAccess: 'https://shamrockfoods.com/sfc-dairysales-prod-web/access_api_gateway'
}

export const links = {
  userGuide: 'https://shamrock365.sharepoint.com/:w:/r/sites/Nike/Shared%20Documents/Initiative%20-%20DairySales/Dairy%20Sales%20FDD.docx?d=w3acc71a0a6fa47319af94849ce48bec8&csf=1&web=1&e=OsMZlv'
}

export const caching = {
  orderCutoffHourOfDay: parseInt('22')
}

export const flags = {
  enableUserGuideLink: _isTrue('1'),
  enableUserImpersonationFeature: _isTrue('1'),
  enableCustomersSearchFeature: _isTrue('1'),
  enableOrdersSearchFeature: _isTrue('0'),
  enableFilterCustomersByDeliveryDayFeature: _isTrue('0')
}

export const orders = {
  deliveryWindowDelayDays: parseInt('1'),
  deliveryWindowTotalDays: parseInt('365')
}
