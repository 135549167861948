export class PostOrdersProductRequestDto {
  constructor ({
    productNumber = /** @type {string?} */(null),
    quantity = 0,
    unitOfMeasure = /** @type {string?} */(null)
  }) {
    /** @type {string?} */
    this.productNumber = productNumber
    /** @type {number} */
    this.quantity = quantity
    /** @type {string?} */
    this.unitOfMeasure = unitOfMeasure
  }
}

export class PostOrdersWarehouseProductRequestDto {
  constructor ({
    warehouseNumber = /** @type {string?} */(null),
    products = /** @type {PostOrdersProductRequestDto[]} */([])
  }) {
    this.warehouseNumber = warehouseNumber
    this.products = products.map((x) => new PostOrdersProductRequestDto(x))
  }
}

export class PostOrdersRequestDto {
  constructor ({
    salesRepNumber = /** @type {string?} */(null),
    customerNumber = /** @type {string?} */(null),
    deliveredAt = /** @type {string?} */(null),
    warehouses = /** @type {PostOrdersWarehouseProductRequestDto[]} */([]),
    purchaseOrderNumber = /** @type {string?} */(null)
  }) {
    /** @type {string?} */
    this.salesRepNumber = salesRepNumber
    /** @type {string?} */
    this.customerNumber = customerNumber
    /** @type {string?} */
    this.deliveredAt = deliveredAt
    /** @type {PostOrdersWarehouseProductRequestDto[]} */
    this.warehouses = warehouses.map((x) => new PostOrdersWarehouseProductRequestDto(x))
    /** @type {string?} */
    this.purchaseOrderNumber = purchaseOrderNumber
  }
}

export default PostOrdersRequestDto
