class GetUsersResponseDto {
  /**
   * @param {object} dto
   * @param {string?} [dto.salesRepName]
   * @param {string?} [dto.email]
   * @param {string?} [dto.salesRepNumber]
   * @param {string?} [dto.employeeNumber]
   */
  constructor ({
    salesRepName = null,
    email = null,
    salesRepNumber = null,
    employeeNumber = null
  }) {
    /** @type {string?} */
    this.salesRepName = salesRepName
    /** @type {string?} */
    this.email = email
    /** @type {string?} */
    this.salesRepNumber = salesRepNumber
    /** @type {string?} */
    this.employeeNumber = employeeNumber
  }
}

export default GetUsersResponseDto
