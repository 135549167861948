import { isFunction } from '@/utilities/type-utility'
import { itemHasKeyPropertyByKeyName, moduleStateHasActiveProperty, moduleStateHasAllProperty, moduleStateHasItemByKey, moduleStateIsDefined } from '@/stores/modules/common/common.validators'

export const activate = (state, payload) => {
  moduleStateIsDefined(state)
  moduleStateHasActiveProperty(state)
  moduleStateHasItemByKey(state, payload)
  state.active = payload
  return true
}

export const addOverwriteRange = (state, { items, keyName }) => {
  moduleStateIsDefined(state)
  moduleStateHasAllProperty(state)
  items.forEach(item => {
    itemHasKeyPropertyByKeyName(item, keyName)
    state.all[item[keyName]] = item
  })
  return true
}

export const addRange = (state, { items, keyName }) => {
  moduleStateIsDefined(state)
  moduleStateHasAllProperty(state)
  items.forEach(item => {
    itemHasKeyPropertyByKeyName(item, keyName)
    if (!state.all[item[keyName]]) {
      state.all[item[keyName]] = item
    }
  })
  return true
}

export const clear = (state) => {
  moduleStateIsDefined(state)
  if (typeof state.active !== 'undefined') {
    state.active = null
  }
  if (typeof state.all !== 'undefined') {
    state.all = {}
  }
  return true
}

export const deactivate = (state) => {
  moduleStateIsDefined(state)
  moduleStateHasActiveProperty(state)
  if (state.active) {
    state.active = null
    return true
  }
  else {
    return false
  }
}

export const removeRange = (state, payload) => {
  moduleStateIsDefined(state)
  moduleStateHasAllProperty(state)
  payload.forEach(key => {
    moduleStateHasItemByKey(state, key)
    if (state.all[key]) {
      delete state.all[key]
    }
    if (state.active === key) {
      state.active = null
    }
  })
  return true
}

/**
 * @param {*} state
 * @param {Object} filter
 * @param {unknown[]} [filter.items]
 * @param {string} [filter.keyName]
 * @param {Function} [filter.transform]
 */
export const updateRange = (state, { items, keyName, transform }) => {
  moduleStateIsDefined(state)
  moduleStateHasAllProperty(state)
  items.forEach(item => {
    itemHasKeyPropertyByKeyName(item, keyName)
    moduleStateHasItemByKey(state, item[keyName])
    if (isFunction(transform)) {
      state.all[item[keyName]] = transform(item)
    }
    else {
      state.all[item[keyName]] = item
    }
  })
  return true
}
