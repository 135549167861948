import { active, first, filter, byKey, isEmpty, isNotEmpty, range, single } from '@/stores/modules/common/common.getters'
import { orderTypeNames } from '@/constants/orders-constants'

const keyName = 'salesRepNumber'
const reducer = (user, { customers, orders }, { include }) => {
  const retVal = { ...user || {} }

  if (include?.includes('customers')) {
    retVal.customers = filter(customers, x => x.userNumber === user[keyName])
  }

  if (include?.includes('orders')) {
    const filterByUser = x => x.byUserNumber === user[keyName]
    const filterForUser = x => x.forUserNumber === user[keyName]
    const filterOrders = x => x.type === orderTypeNames.order
    const filterCredits = x => x.type === orderTypeNames.credit
    const filterInProgress = x => !x.submittedAt
    const filterSubmitted = x => !!x.submittedAt
    retVal.orders = {}
    retVal.orders.inProgressOrdersByUser = filter(orders, [filterInProgress, filterOrders, filterByUser])
    retVal.orders.inProgressOrdersForUser = filter(orders, [filterInProgress, filterOrders, filterForUser])
    retVal.orders.inProgressCreditsByUser = filter(orders, [filterInProgress, filterCredits, filterByUser])
    retVal.orders.inProgressCreditsForUser = filter(orders, [filterInProgress, filterCredits, filterForUser])
    retVal.orders.submittedOrdersByUser = filter(orders, [filterSubmitted, filterOrders, filterByUser])
    retVal.orders.submittedOrdersForUser = filter(orders, [filterSubmitted, filterOrders, filterForUser])
    retVal.orders.submittedCreditsByUser = filter(orders, [filterSubmitted, filterCredits, filterByUser])
    retVal.orders.submittedCreditsForUser = filter(orders, [filterSubmitted, filterCredits, filterForUser])
    retVal.orders.hasInProgressOrderOrCreditByUser = (
      !!retVal.orders.inProgressOrdersByUser.length ||
      !!retVal.orders.inProgressCreditsByUser.length
    )
    retVal.orders.hasSubmittedOrderOrCreditByUser = (
      !!retVal.orders.submittedOrdersByUser.length ||
      !!retVal.orders.submittedCreditsByUser.length
    )
    retVal.orders.hasOrderOrCreditByUser = (
      !!retVal.orders.inProgressOrdersByUser.length ||
      !!retVal.orders.inProgressCreditsByUser.length ||
      !!retVal.orders.submittedOrdersByUser.length ||
      !!retVal.orders.submittedCreditsByUser.length
    )
  }

  return retVal
}

const getters = {}

getters.active = (state) => active(state)

getters.current = (state) => state.all[state.current]

getters.currentIsActive = (state) => state.current === state.active

getters.byNumber = (state) => (key) => byKey(state, key)

getters.first = (state) => first(state)

getters.isEmpty = (state) => isEmpty(state)

getters.isNotEmpty = (state) => isNotEmpty(state)

getters.range = (state, _, rootState) => (where, options) => {
  return range(state, rootState, reducer, where, options)
}

getters.single = (state, _, rootState) => (where, options) => {
  return single(state, rootState, reducer, where, options)
}

const names = {
  active: 'active',
  current: 'current',
  currentIsActive: 'currentIsActive',
  byNumber: 'byNumber',
  first: 'first',
  isEmpty: 'isEmpty',
  isNotEmpty: 'isNotEmpty',
  range: 'range',
  single: 'single'
}

const namespaced = {
  active: 'users/active',
  current: 'users/current',
  currentIsActive: 'users/currentIsActive',
  byNumber: 'users/byNumber',
  first: 'users/first',
  isEmpty: 'users/isEmpty',
  isNotEmpty: 'users/isNotEmpty',
  range: 'users/range',
  single: 'users/single'
}

export {
  names,
  namespaced,
  getters
}
