export class PostReturnsProductRequestDto {
  constructor ({
    productNumber = /** @type {string?} */(null),
    quantity = 0,
    reasonCode = /** @type {string?} */(null)
  }) {
    this.productNumber = productNumber
    this.quantity = quantity
    this.reasonCode = reasonCode
  }
}

export class PostReturnsWarehouseProductRequestDto {
  constructor ({
    warehouseNumber = /** @type {string?} */(null),
    products = /** @type {PostReturnsProductRequestDto[]} */([])
  }) {
    this.warehouseNumber = warehouseNumber
    this.products = products.map((x) => new PostReturnsProductRequestDto(x))
  }
}

class PostReturnsRequestDto {
  constructor ({
    salesRepNumber = /** @type {string?} */(null),
    customerNumber = /** @type {string?} */(null),
    deliveredAt = /** @type {string?} */(null),
    warehouses = /** @type {PostReturnsWarehouseProductRequestDto[]} */([])
  }) {
    this.salesRepNumber = salesRepNumber
    this.customerNumber = customerNumber
    this.deliveredAt = deliveredAt
    this.warehouses = warehouses.map((x) => new PostReturnsWarehouseProductRequestDto(x))
  }
}

export default PostReturnsRequestDto
