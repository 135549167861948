import { cacheKeys } from '@/constants/cache-constants'
import { setItem, removeItem } from '@/helpers/cache-helper'
import { activate, addRange, clear, deactivate, removeRange } from '@/stores/modules/common/common.mutations'
import Customer from '@/models/Customer'

const keyName = 'customerNumber'
const mutations = {}

mutations.activate = (state, payload) => {
  try {
    activate(state, payload?.[keyName] || null)
    setItem(cacheKeys.activeCustomerNumber, payload?.[keyName] || null)
  }
  catch {
  }
}

mutations.add = (state, payload) => {
  addRange(state, { items: [new Customer(payload)], keyName })
}

mutations.addRange = (state, payload) => {
  addRange(state, { items: payload.map(x => new Customer(x)), keyName })
}

mutations.clear = (state) => {
  clear(state)
}

mutations.deactivate = (state) => {
  deactivate(state)
  removeItem(cacheKeys.activeCustomerNumber)
}

mutations.remove = (state, payload) => {
  removeRange(state, [payload])
}

const names = {
  activate: 'activate',
  add: 'add',
  addRange: 'addRange',
  clear: 'clear',
  deactivate: 'deactivate',
  remove: 'remove'
}

const namespaced = {
  activate: 'customers/activate',
  add: 'customers/add',
  addRange: 'customers/addRange',
  clear: 'customers/clear',
  deactivate: 'customers/deactivate',
  remove: 'customers/remove'
}

export {
  names,
  namespaced,
  mutations
}
