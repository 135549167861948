<template>
  <one-erp-page-layout
    :use-default-header-css-classes="false"
    :use-default-content-css-classes="false"
    :use-default-sidebar-css-classes="false"
  >
    <template #header>
      <erp-spinner :show-spinner="isLoading" />
      <div class="view-masthead" v-if="customerName">
        <div class="active-customer">
          <h2>{{ customerName }}</h2>
          <p>Manage catalog product visibility</p>
        </div>
        <div class="submit">
          <one-erp-button
            @click="productManageSubmitAsync"
            :button-type="ButtonTypes.solid"
            button-text="Submit"
          />
        </div>
      </div>
    </template>
    <template #content>
      <div class="view-content">
        <div v-if="customerName" class="content">
          <div class="multi-filter">
            <one-erp-multi-filter
              @update:filter-function="x => gridMultiFilterFunction = x"
              :filterable-options="gridMultiFilterOptions"
              filter-label="header"
              filter-value="field"
            />
          </div>
          <div class="multi-sort">
            <one-erp-sort-indicator
              @update:descending="() => gridMultiSortDescending = !gridMultiSortDescending"
              @update:sort-param="(parameter) => gridMultiSortParameter = parameter"
              :sortable-parameters="gridMultiSortParameters"
              :sort-param="gridMultiSortParameter"
              :descending="gridMultiSortDescending"
              sort-param-label="header"
              sort-param-value="field"
            />
          </div>
          <div class="grid">
            <products-grid
              :customer-number="customerNumber"
              :filters="[gridMultiFilterFunction]"
              :sorts="[gridMultiSortFunction]"
              v-model="productsManage"
            />
          </div>
        </div>
        <div class="not-found" v-else>
          <div class="page-message">
            <h1 class="page-message__header">
              The customer number '{{ customerNumber }}' is not valid.
            </h1>
            <span class="page-message__text">Please contact support.</span>
          </div>
        </div>
      </div>
    </template>
  </one-erp-page-layout>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { getters, select } from '@/stores/store'
import { routeNames } from '@/constants/route-constants'
import {
  Spinner as ErpSpinner,
  MultiFilter as OneErpMultiFilter,
  PageLayout as OneErpPageLayout,
  SortIndicator as OneErpSortIndicator,
  Button as OneErpButton,
  ButtonTypes
} from '@sfc-enterprise-ui/one-erp-components/src'
import ProductsGrid from '@/areas/customers/components/products-grid.vue'
import { productManageFields } from '@/constants/product-constants'
import { putProductsManageAsync } from '@/services/products-service'

const { push } = useRouter()

const isLoading = ref(false)
const productsManage = ref([])

const props = defineProps({
  customerNumber: { type: String, required: true }
})

const gridMultiSortParameters = [
  { header: 'Description', field: productManageFields.productDescription },
  { header: 'Product Number', field: productManageFields.productNumber },
  { header: 'Vendor', field: productManageFields.vendorName },
  { header: 'Visibility', field: productManageFields.visibility }
]

const gridMultiFilterOptions = [
  { header: 'Description', label: 'Description', field: productManageFields.productDescription, id: 1 },
  { header: 'Product Number', label: 'Product Number', field: productManageFields.productNumber, id: 2 },
  { header: 'Vendor', label: 'Vendor', field: productManageFields.vendorName, id: 3 }
]

const gridMultiSortDescending = ref(false)
const gridMultiFilterFunction = ref((a) => a)
const gridMultiSortParameter = ref(gridMultiSortParameters[0])

const gridMultiSortFunction = (a, b) => {
  const desc = gridMultiSortDescending.value ? -1 : 1
  const c = a[gridMultiSortParameter.value.field]
  const d = b[gridMultiSortParameter.value.field]
  if (typeof c === 'string') {
    return c.localeCompare(d) * desc
  } else {
    return (c - d) * desc
  }
}

const customerName = computed(() =>
  getters[select.customers.byNumber](props.customerNumber)?.customerName)

const productManageSubmitAsync = async () => {
  try {
    isLoading.value = true
    await putProductsManageAsync({
      customerNumber: props.customerNumber,
      productsManage: productsManage.value
    })
    isLoading.value = false
    push({ name: routeNames.searchCustomers })
  } finally {
    isLoading.value = false
  }
}
</script>
<style lang="scss" scoped>
@use "~@sfc-enterprise-ui/one-erp-theme/one-erp-theme.scss" as *;
.view-masthead {
  grid-template-areas:
    'activate-customer submit'
    'activate-customer submit';
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr repeat(2, auto);
  grid-template-rows: repeat(2, 3.4rem);
  padding: 1.8rem;
  row-gap: 0;
  h2 {
    font-family: $font-family;
    font-size: 2rem;
    font-weight: bold;
    height: 2.2rem;
    letter-spacing: 0.5px;
    line-height: 2.2rem;
    margin-left: 2.2rem;
    padding: 0.6rem 0;
  }
  p {
    margin-top: 0;
    margin-left: 2.2rem;
  }
  .activate-customer {
    grid-area: activate-customer;
  }
  .submit {
    grid-area: submit;
    place-self: end end;
  }
}
.content {
  grid-template-areas:
    'multi-filter day-filter multi-sort'
    'grid grid grid';
  display: grid;
  grid-template-columns: 1fr auto auto;
  padding: 3rem 1.8rem 1.8rem;
}
.multi-filter {
  grid-area: multi-filter;
}
.day-filter {
  grid-area: day-filter;
}
.multi-sort {
  grid-area: multi-sort;
  padding: 1rem 0;
}
.grid {
  grid-area: grid;
}
</style>
