<template>
  <div class="inventory-masthead">
    <div class="activate-customer">
      <customers-activate
        @change-active-customer="(customerNumber) => onChangeActiveUserOrCustomer(customerNumber)"
        :disabled="false"
        show-orders-credits-counts
        use-header-style
      />
    </div>
    <div class="submit">
      <one-erp-button
        @click="() => $emit('submit')"
        :button-type="ButtonTypes.solid"
        :disabled="submitDisabled"
        button-text="Submit"
      />
    </div>
  </div>
</template>
<script setup>
// @ts-check
import { useStore } from 'vuex'
import {
  Button as OneErpButton,
  ButtonTypes
} from '@sfc-enterprise-ui/one-erp-components/src'
import CustomersActivate from '@/components/customers/customers-activate.vue'
import { select } from '@/stores/store'
import { useRouter, onBeforeRouteUpdate } from 'vue-router'
import { editInventoryViewBeforeUpdateAsync, editInventoryViewBeforeEnterOrUpdateAsync } from '@/router/guards'
import { routeNames } from '@/constants/route-constants'

const store = useStore()
const router = useRouter()
onBeforeRouteUpdate(editInventoryViewBeforeUpdateAsync)
onBeforeRouteUpdate(editInventoryViewBeforeEnterOrUpdateAsync)

defineProps({
  submitDisabled: { type: Boolean }
})

defineEmits([
  'submit'
])

const onChangeActiveUserOrCustomer = (customerNumber) => {
  const currentInventory = store.getters[select.inventories.find](inventory => (
    (
      inventory.forCustomerNumber === customerNumber
    )
  ))
  if (currentInventory) {
    router.push({
      name: routeNames.editInventories,
      params: { inventoryNumber: currentInventory.inventoryNumber }
    })
  } else {
    router.push({
      name: routeNames.editInventories
    })
  }
}
</script>
<style scoped lang="scss">
.inventory-masthead {
  grid-template-areas:
    'activate-customer submit'
    'activate-customer submit';
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr repeat(2, auto);
  grid-template-rows: repeat(2, 3.4rem);
  padding: 1.8rem;
  row-gap: 0;

  .activate-customer {
    grid-area: activate-customer;
  }
  .submit {
    grid-area: submit;
    place-self: end end;
  }
}</style>
