/**
 * Add telemetry initializer to all appInsight calls for this application
 */
export default (appName, appVersion) => {
  if (!appName) {
    throw new Error('"appName" is missing')
  }
  if (!appVersion) {
    throw new Error('"appVersion" is missing')
  }
  window.appInsights.queue.push(() => {
    window.appInsights.context.addTelemetryInitializer(envelope => {
      const telemetryItem = envelope.data.baseData

      // Example: Check the telemetry item???s type and removes url from all page view documents
      // if (envelope.name === Microsoft.ApplicationInsights.Telemetry.PageView.envelopeType) {
      //   telemetryItem.url = 'URL CENSORED'
      // }

      // Set custom app class and name properties (for filters)
      telemetryItem.properties = telemetryItem.properties || {}
      telemetryItem.properties['Application name'] = appName
      telemetryItem.properties['Application version'] = appVersion

      // Example: To set custom metrics
      // telemetryItem.measurements = telemetryItem.measurements || {}
      // telemetryItem.measurements['globalMetric'] = 100
    })
  })
}
