export const appConfig = {
  name: 'Dairy Sales',
  description: 'Application enabling the creation and management of Dairy and DairyMaid customer orders.',
  version: '1.0.0'
}

export const cacheConstants = {
  accessToken: 'access-token'
}

export const httpStatusCodeConstants = {
  notFound: 404,
  unauthorized: 401
}

export const injectionConstants = {
  dayjs: 'dayjs',
  user: 'user'
}

const appConstants = {
  injectionConstants
}

export default appConstants
