export const productFields = {
  warehouseName: 'warehouseName',
  productNumber: 'productNumber',
  productDescription: 'productDescription',
  vendorName: 'vendorName',
  sequenceGroupNumber: 'sequenceGroupNumber'
}

/**
 * @typedef IProductInventoryFields
 * @type {Object}
 * @property {*} productNumber
 * @property {*} productDescription
 * @property {*} warehouseQuantity
 * @property {*} shelfQuantity
 * @property {*} otherQuantity
 * @property {*} subtotalQuantity
 */

/** @type {IProductInventoryFields} */
export const productInventoryFields = {
  productNumber: 'productNumber',
  productDescription: 'productDescription',
  warehouseQuantity: 'warehouseQuantity',
  shelfQuantity: 'shelfQuantity',
  otherQuantity: 'otherQuantity',
  subtotalQuantity: 'subtotalQuantity'
}

/**
 * @typedef IProductManageFields
 * @type {Object}
 * @property {*} productNumber
 * @property {*} productDescription
 */

/** @type {IProductManageFields} */
export const productManageGridColumns = {
  productNumber: 'productNumber',
  productDescription: 'productDescription'
}

export const productManageFields = {
  productNumber: 'productNumber',
  productDescription: 'productDescription',
  vendorName: 'vendorName',
  visibility: 'shouldHideSku'
}
