<template>
  <div class="active-order-line-items-review">
    <one-erp-simple-grid
      :column-attributes="schema"
      :row-data="lineItems"
      :enable-grid-tips="false"
      max-grid-height="21rem"
    >
      <template #cellcontent="{ row, column }">
        <div class="order-quantity" v-if="column.field === orderReviewGridColumns.orderQuantity">
          {{ row.orderQuantityUom }}
        </div>
        <div class="description" v-if="column.field === orderReviewGridColumns.productDescription">
          {{ row.product.productDescription }}
        </div>
        <div class="warehouse-name" v-if="column.field === orderReviewGridColumns.warehouseName">
          {{ row.product.warehouseName }}
        </div>
        <div class="total" v-if="column.field === orderReviewGridColumns.templateTotal">
          {{ row.templateTotal }}
        </div>
      </template>
    </one-erp-simple-grid>
  </div>
</template>
<script setup>
import { getters, select } from '@/stores/store'
import { computed } from 'vue'
import LineItem from '@/models/LineItem'
import {
  SimpleGrid as OneErpSimpleGrid
} from '@sfc-enterprise-ui/one-erp-components'
import { orderReviewGridColumns } from '@/constants/orders-constants'

const schema = [
  { header: 'Quantity', field: orderReviewGridColumns.orderQuantity, width: '12rem' },
  { header: 'Product', field: orderReviewGridColumns.productDescription, width: '3fr' },
  { header: 'Warehouse', field: orderReviewGridColumns.warehouseName, width: '11rem' },
  { header: 'Total', field: orderReviewGridColumns.templateTotal, width: '11rem' }
]

const lineItems = computed(() => {
  return getters[select.lineItems.range](
    (x) => (
      x.orderNumber === getters[select.orders.active]?.orderNumber &&
      (x.orderQuantity || 0) > 0
    ),
    {
      map: (x) => new LineItem(x)
    }
  )
})
</script>
