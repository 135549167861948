import { getAsync } from '@sfc-enterprise-ui/fetch-api'
import GetUsersResponseDto from '@/services/dto/GetUsersResponseDto'

export const getUsersCurrentAsync = async () => {
  const result = await getAsync('/users/current')

  return new GetUsersResponseDto(result)
}

export const getUsersAsync = async () => {
  const result = await getAsync('/users')

  return result.map((x) => new GetUsersResponseDto(x))
}
