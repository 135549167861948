import { computed } from 'vue'
import { createStore } from 'vuex'
import { syncCachedSettings } from '@/stores/plugins'

import customersStore from '@/stores/modules/customers/customers-store'
import globalStore from '@/stores/modules/global/global-store'
import lineItemsStore from '@/stores/modules/line-items/line-items-store'
import ordersStore from '@/stores/modules/orders/orders-store'
import usersStore from '@/stores/modules/users/users-store'
import inventoriesStore from '@/stores/modules/inventories/inventories-store'
import inventoryItemsStore from '@/stores/modules/inventory-items/inventory-items-store'

import { names as customersGettersNames, namespaced as customersGettersNamespaced } from '@/stores/modules/customers/customers-store.getters'
import { names as globalGettersNames, namespaced as globalGettersNamespaced } from '@/stores/modules/global/global-store.getters'
import { names as lineItemsGettersNames, namespaced as lineItemsGettersNamespaced } from '@/stores/modules/line-items/line-items-store.getters'
import { names as ordersGettersNames, namespaced as ordersGettersNamespaced } from '@/stores/modules/orders/orders-store.getters'
import { names as usersGettersNames, namespaced as usersGettersNamespaced } from '@/stores/modules/users/users-store.getters'
import { names as inventoriesGettersNames, namespaced as inventoriesGettersNamespaced } from '@/stores/modules/inventories/inventories-store.getters'
import { names as inventoryItemsGettersNames, namespaced as inventoryItemsGettersNamespaced } from '@/stores/modules/inventory-items/inventory-items-store.getters'

import { namespaced as customersActionsNamespaced } from '@/stores/modules/customers/customers-store.actions'
import { namespaced as customersMutationsNamespaced } from '@/stores/modules/customers/customers-store.mutations'
import { namespaced as globalActionsNamespaced } from '@/stores/modules/global/global-store.actions'
import { namespaced as globalMutationsNamespaced } from '@/stores/modules/global/global-store.mutations'
import { namespaced as lineItemsActionsNamespaced } from '@/stores/modules/line-items/line-items-store.actions'
import { namespaced as lineItemsMutationsNamespaced } from '@/stores/modules/line-items/line-items-store.mutations'
import { namespaced as ordersActionsNamespaced } from '@/stores/modules/orders/orders-store.actions'
import { namespaced as ordersMutationsNamespaced } from '@/stores/modules/orders/orders-store.mutations'
import { namespaced as usersActionsNamespaced } from '@/stores/modules/users/users-store.actions'
import { namespaced as usersMutationsNamespaced } from '@/stores/modules/users/users-store.mutations'
import { namespaced as inventoriesActionsNamespaced } from '@/stores/modules/inventories/inventories-store.actions'
import { namespaced as inventoriesMutationsNamespaced } from '@/stores/modules/inventories/inventories-store.mutations'
import { namespaced as inventoryItemsActionsNamespaced } from '@/stores/modules/inventory-items/inventory-items-store.actions'
import { namespaced as inventoryItemsMutationsNamespaced } from '@/stores/modules/inventory-items/inventory-items-store.mutations'

export const store = createStore({
  strict: true,
  modules: {
    customers: customersStore,
    global: globalStore,
    lineItems: lineItemsStore,
    orders: ordersStore,
    users: usersStore,
    inventories: inventoriesStore,
    inventoryItems: inventoryItemsStore
  },
  plugins: [
    syncCachedSettings
  ]
})

const mapGetters = (/** @type {String} */namespace, names) => {
  return Object
    .entries(names)
    .reduce((acc, [key, val]) => {
      acc[key] = computed(() => store.getters[`${namespace}/${val}`])
      return acc
    }, {})
}

export const commit = store.commit
export const dispatch = store.dispatch
export const getters = store.getters
export const state = store.state
export const actions = {
  customers: customersActionsNamespaced,
  global: globalActionsNamespaced,
  lineItems: lineItemsActionsNamespaced,
  orders: ordersActionsNamespaced,
  users: usersActionsNamespaced,
  inventories: inventoriesActionsNamespaced,
  inventoryItems: inventoryItemsActionsNamespaced
}
export const mutations = {
  customers: customersMutationsNamespaced,
  global: globalMutationsNamespaced,
  lineItems: lineItemsMutationsNamespaced,
  orders: ordersMutationsNamespaced,
  users: usersMutationsNamespaced,
  inventories: inventoriesMutationsNamespaced,
  inventoryItems: inventoryItemsMutationsNamespaced
}
export const select = {
  customers: customersGettersNamespaced,
  global: globalGettersNamespaced,
  lineItems: lineItemsGettersNamespaced,
  orders: ordersGettersNamespaced,
  users: usersGettersNamespaced,
  inventories: inventoriesGettersNamespaced,
  inventoryItems: inventoryItemsGettersNamespaced
}
export const map = () => ({
  customers: mapGetters('customers', customersGettersNames),
  global: mapGetters('global', globalGettersNames),
  lineItems: mapGetters('lineItems', lineItemsGettersNames),
  orders: mapGetters('orders', ordersGettersNames),
  users: mapGetters('users', usersGettersNames),
  inventories: mapGetters('inventories', inventoriesGettersNames),
  inventoryItems: mapGetters('inventoryItems', inventoryItemsGettersNames)
})

export default store
