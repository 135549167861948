import { actions } from '@/stores/modules/global/global-store.actions'
import { getters } from '@/stores/modules/global/global-store.getters'
import { mutations } from '@/stores/modules/global/global-store.mutations'
import { warehouses } from '@/constants/warehouse-constants'
import envConfig from '@/env.config'

const store = {
  state: {
    isInitialized: false,
    isLoading: false,
    hasError: false,
    warehouses,
    flags: envConfig.flags
  },
  strict: true,
  namespaced: true,
  actions,
  getters,
  mutations
}

export default store
