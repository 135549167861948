<template>
  <div class="orders-activate-delete">
    <div class="dropdown">
      <one-erp-dropdown
        @update:model-value="({ id }) => uiState.selectedOrderNumber = id"
        :disabled="!all.length"
        :model-value="selected"
        :options="all"
        :placeholder="!all.length ? 'None' : 'Select an item'"
      />
    </div>
    <one-erp-button
      @click="onSelectActivateOrderAsync"
      :button-text="props.activateButtonText"
      :disabled="!all.length || !selected"
      class="activate-order"
      button-type="solid"
    />
    <one-erp-button
      @click="onSelectDeleteOrder"
      :button-text="props.deleteButtonText"
      :disabled="!all.length || !selected"
      class="delete-order"
      button-type="tertiary"
    />
  </div>
  <one-erp-modal
    @cancel="onSelectCancelDeleteOrder"
    @confirm="onSelectConfirmDeleteOrderAsync"
    :width="modalSizesConstants.small"
    :show-modal="uiState.showConfirmDeleteModal"
    :overflow-y="overflowConstants.auto"
    cancel-button-text="Back"
    confirm-button-text="Delete"
  >
    <template #header>
      <h2>{{ `Delete ${selected?.order?.type || 'item'}` }}</h2>
    </template>
    <template #content>
      <div class="confirm-delete">
        <span class="bigger-text">
          {{
            `Are you sure you want to delete your existing ${selected?.order?.type || 'item'}` +
              ` for ${selected?.customer?.customerName || 'this customer'}?`
          }}
        </span>
      </div>
    </template>
  </one-erp-modal>
</template>
<script setup>
import { orderTypeNames } from '@/constants/orders-constants'
import { state as storeState, getters, select, dispatch, actions } from '@/stores/store'
import { reactive, computed } from 'vue'
import {
  Button as OneErpButton,
  Dropdown as OneErpDropdown,
  Modal as OneErpModal,
  ModalSizes as modalSizesConstants,
  OverflowYValues as overflowConstants
} from '@sfc-enterprise-ui/one-erp-components'

const filter = (order) => (
  !order.submittedAt &&
  order.forUserNumber === storeState.users.active &&
  (
    order.type === orderTypeNames.order ||
    order.type === orderTypeNames.credit
  )
)

const props = defineProps({
  activateButtonText: { type: String, required: false, default: 'Activate' },
  deleteButtonText: { type: String, required: false, default: 'Delete' }
})

const emit = defineEmits([
  'open-confirm-delete-order-credit-modal',
  'close-confirm-delete-order-credit-modal',
  'activate-order-credit',
  'delete-credit'
])

const uiState = reactive({
  selectedOrderNumber: null,
  showConfirmDeleteModal: false
})

const selected = computed(() => {
  const { customer, ...order } = getters[select.orders.single](
    [
      (order) => order.orderNumber === uiState.selectedOrderNumber,
      (order) => order.forCustomerNumber === storeState.customers.active,
      (order) => order.byUserNumber === storeState.users.current
    ],
    { include: ['customer'] }
  )
  if (
    order.orderNumber &&
    order.type &&
    customer
  ) {
    if (!order.submittedAt) {
      return {
        orderNumber: order.orderNumber,
        customer,
        id: order.orderNumber,
        value: order.orderNumber,
        label: `${customer?.customerName} (${order.type})`
      }
    }
  }
  return null
})

const all = computed(() => {
  return getters[select.orders.range](
    filter,
    {
      sort: ({ customer: a }, { customer: b }) => (a?.customerName < b?.customerName) ? -1 : 1,
      include: ['customer']
    }
  )
    .map(({ orderNumber, type, customer }) => ({
      id: orderNumber,
      value: orderNumber,
      label: `${customer?.customerName} (${type})`
    }))
})

const onSelectActivateOrderAsync = async () => {
  emit('activate-order-credit', selected.value?.id)
}

const onSelectDeleteOrder = () => {
  uiState.showConfirmDeleteModal = true
  emit('open-confirm-delete-order-credit-modal')
}

const onSelectCancelDeleteOrder = () => {
  uiState.showConfirmDeleteModal = false
  emit('close-confirm-delete-order-credit-modal')
}

const onSelectConfirmDeleteOrderAsync = async () => {
  window.appInsights.trackEvent('onSelectConfirmDeleteOrderAsync', {
    selected: selected?.value?.orderNumber
  })

  await dispatch(actions.orders.cacheRemoveAsync, selected.value)
  await dispatch(actions.orders.removeAsync, selected.value)
  emit('delete-credit')

  uiState.selectedOrderNumber = null
  uiState.showConfirmDeleteModal = false
  emit('close-confirm-delete-order-credit-modal')
}
</script>
<style lang="scss" scoped>
.orders-activate-delete {
  grid-template-areas:
    'orders orders'
    'activate-order delete-order';
  display: grid;
  gap: 3rem;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 3.4rem;
  height: 100%;
  row-gap: 1.6rem;
}
.dropdown {
  grid-area: orders;
}
.activate-order {
  grid-area: activate-order;
}
.delete-order {
  grid-area: delete-order;
}
</style>
