import envConstants from '@/env.constants'
import * as debugEnv from '@/env.debug'
import * as releaseEnv from '@/env.release'
import * as testEnv from '@/env.test'

let envConfig

const envs = envConstants.nodeEnvironments

const isReleaseEnvironment = [envs.prod, envs.dev].includes(process.env.NODE_ENV)

const isDebugEnvironment = envs.local === process.env.NODE_ENV

const isTestEnvironment = envs.test === process.env.NODE_ENV

if (isReleaseEnvironment) {
  envConfig = releaseEnv
}
else if (isDebugEnvironment) {
  envConfig = debugEnv
}
else if (isTestEnvironment) {
  envConfig = testEnv
}

export default envConfig
