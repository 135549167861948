import Product from '@/models/Product'
import ProductInventory from '@/models/ProductInventory'
import PutProductsParRequestDto from '@/services/dto/PutProductsParRequestDto.js'
import { isEmpty, isNumber } from '@/utilities/type-utility'

/**
 * @param {import('@/services/dto/GetProductsResponseDto.js').default} dto
 * @returns {import('@/models/Product').default}
 */
export const mapGetProductsResponseDtoToProductModel = (dto) => {
  let errorMessage

  // #region validate dto
  if (!dto.warehouseNumber === null) errorMessage = 'warehouseNumber is not valid.'
  if (!dto.productNumber === null) errorMessage = 'productNumber is not valid.'
  if (!dto.productDescription === null) errorMessage = 'productDescription is not valid.'
  if (!dto.vendorName === null) errorMessage = 'vendorName is not valid.'
  if (!dto.packSizeDescription === null) errorMessage = 'packSizeDescription is not valid.'
  if (!dto.purchaseUom === null) errorMessage = 'purchaseUom is not valid.'
  if (!dto.quantityPerCase === null) errorMessage = 'quantityPerCase is not valid.'
  if (!dto.onHandCasesQuantity === null) errorMessage = 'onHandCasesQuantity is not valid.'
  if (!dto.onHandEachesQuantity === null) errorMessage = 'onHandEachesQuantity is not valid.'
  if (!dto.pricePerCase === null) errorMessage = 'pricePerCase is not valid.'
  if (!dto.pricePerEach === null) errorMessage = 'pricePerEach is not valid.'
  if (!dto.sequenceGroupDescription === null) errorMessage = 'sequenceGroupDescription is not valid.'
  if (!dto.sequenceGroupNumber === null) errorMessage = 'sequenceGroupNumber is not valid.'
  // #endregion

  if (errorMessage) {
    throw new Error(`Model validation failed for product ${dto.productNumber}: ${errorMessage}`)
  }

  return new Product({
    warehouseNumber: dto.warehouseNumber,
    productNumber: dto.productNumber,
    productDescription: dto.productDescription,
    vendorName: dto.vendorName,
    packSizeDescription: dto.packSizeDescription,
    purchaseUnitOfMeasure: dto.purchaseUom,
    perCaseQuantity: dto.quantityPerCase,
    onHandCasesQuantity: dto.onHandCasesQuantity,
    onHandUnitsQuantity: dto.onHandEachesQuantity,
    pricePerCase: dto.pricePerCase,
    pricePerEach: dto.pricePerEach,
    sequenceGroupDescription: dto.sequenceGroupDescription,
    sequenceGroupNumber: dto.sequenceGroupNumber
  })
}

/**
 * @param {import('@/services/dto/GetProductsInventoryResponseDto.js').default} dto
 * @returns {import('@/models/ProductInventory').default}
 */
export const mapGetProductsInventoryResponseDtoToProductInventoryModel = (dto) => {
  return new ProductInventory({
    warehouseNumber: dto.warehouseNumber,
    customerNumber: dto.customerNumber,
    store: dto.store,
    productNumber: dto.productNumber,
    packSizeDescription: dto.packSizeDescription,
    vendorName: dto.vendorName,
    productDescription: dto.productDescription,
    eachesPerCase: dto.eachesPerCase,
    classDescription: dto.classDescription,
    sequenceGroupDescription: dto.sequenceGroupDescription,
    sequenceGroupNumber: dto.sequenceGroupNumber,
    itemSource: dto.itemSource
  })
}

/**
 * Validates and maps the application model to the API endpoint DTO.
 *
 * @param {import('@/models/Order.js').default} order
 * @param {import('@/models/LineItem.js').default[]} lineItems
 * @returns {import('@/services/dto/PutProductsParRequestDto.js').default}
 */
export const mapOrderModelToPutProductsParRequestDto = (order, lineItems) => {
  let errorMessage

  // #region validate order model
  if (!order.forCustomerNumber) errorMessage = 'forCustomerNumber is not valid.'
  // #endregion

  // #region validate line items models
  for (const lineItem of lineItems) {
    if (isEmpty(lineItem.product?.productNumber)) {
      errorMessage += 'Product number is not valid. '
      continue
    }
    if (isEmpty(lineItem.product?.warehouseNumber)) {
      errorMessage += 'Warehouse number is not valid. '
      continue
    }
    if (!isNumber(lineItem.parQuantity)) {
      errorMessage += `${lineItem.product?.productNumber} has an invalid par quantity. `
    }
    if (isNumber(lineItem.parQuantity) && lineItem.parQuantity < 0) {
      errorMessage += `${lineItem.product?.productNumber} par quantity must be greater than zero. `
    }
  }
  // #endregion

  if (errorMessage) {
    throw new Error(`Product validation failed: ${errorMessage}`)
  }

  const warehouseObjsTable = {}

  for (const lineItem of lineItems) {
    const { warehouseNumber, orderGuideNumber } = lineItem.product

    if (!warehouseObjsTable[warehouseNumber]) {
      warehouseObjsTable[warehouseNumber] = {
        warehouseNumber,
        orderGuideNumber,
        products: []
      }
    }

    warehouseObjsTable[warehouseNumber].products.push({
      productNumber: lineItem.product.productNumber,
      quantity: lineItem.parQuantity,
      unitOfMeasure: 'EA'
    })
  }

  return new PutProductsParRequestDto({
    customerNumber: order.forCustomerNumber,
    warehouses: Object.values(warehouseObjsTable)
  })
}
