import { getCollectionItem, putCollectionItem } from '@/helpers/cache-helper'
import { names as lineItemMutations } from '@/stores/modules/line-items/line-items-store.mutations'
import { unitOfMeasureCodes, orderTypeNames, unitOfMeasureNames } from '@/constants/orders-constants'
import { getProductsAsync, getProductsParAsync, getProductsHistoryAsync } from '@/services/products-service'
import { select } from '@/stores/store'
import cacheConstants from '@/constants/cache-constants'
import LineItem from '@/models/LineItem'
import { mapGetProductsResponseDtoToProductModel } from '@/utilities/mappers/product-mappers'
import Product from '@/models/Product'
import { isDefined } from '@/utilities/type-utility'

const keyName = 'lineItemNumber'
const actions = {}

actions.clearAsync = ({ commit }) => commit(lineItemMutations.clear)

actions.cacheLoadAllForOrderAsync = async ({ commit }, payload) => {
  commit(lineItemMutations.clear)
  commit(
    lineItemMutations.addRange,
    {
      key: payload.orderNumber,
      value: getCollectionItem(cacheConstants.keys.orders, payload.orderNumber)?.lineItems || []
    }
  )
}

actions.cacheSaveAllForOrderAsync = async ({ rootGetters }, payload) => {
  putCollectionItem(
    cacheConstants.keys.orders,
    payload.orderNumber,
    ({ order }) => ({
      order,
      lineItems: rootGetters[select.lineItems.filter](x => x.orderNumber === payload.orderNumber)
    })
  )
}

/**
 * @param {import('@/models/Order').default} payload
 */
actions.cacheSaveAllFromRemoteForOrderAsync = async (_, payload) => {
  const customerNumber = payload.forCustomerNumber
  const cached = getCollectionItem(cacheConstants.keys.orders, payload.orderNumber)
  let nextLineItemNumber = 1

  const getProductsResult =
    (await getProductsAsync({ customerNumber }))
      .map((x) => mapGetProductsResponseDtoToProductModel(x))

  /** @typedef {Record<string, {
   *  quantity: number,
   *  unitOfMeasure: string}>} getProductsParResult */
  const getProductsParResult =
    (await getProductsParAsync({ customerNumber }))
      .reduce((/** @type getProductsParResult */acc, x) => {
        acc[x.productNumber] = {
          quantity: x.quantity,
          unitOfMeasure: x.unitOfMeasure
        }
        return acc
      }, {})

  /** @typedef {Record<string, {
   *  lastOrderedQuantity: number,
   *  avgSalesInEaches: number,
   *  avgSalesInCases: number,
   *  avgCreditsInEaches: number,
   *  avgCreditsInCases: number}>} historyLookup */
  const historyLookup =
  (await getProductsHistoryAsync({ customerNumber }))
    .reduce((/** @type {historyLookup} */acc, x) => {
      acc[x.productNumber] = {
        lastOrderedQuantity: x.lastOrderedQuantity,
        avgSalesInEaches: x.avgSalesInEaches,
        avgSalesInCases: x.avgSalesInCases,
        avgCreditsInEaches: x.avgCreditsInEaches,
        avgCreditsInCases: x.avgCreditsInCases
      }
      return acc
    }, {})

  cached.lineItems = getProductsResult.map(product => {
    const unitOfMeasure = (
      (product?.purchaseUnitOfMeasure === unitOfMeasureCodes.each) ||
      (payload.type === orderTypeNames.credit)
    )
      ? unitOfMeasureNames.each
      : unitOfMeasureNames.case

    let lineItem
    if (isDefined(product.productNumber)) {
      lineItem = new LineItem({
        [keyName]: nextLineItemNumber,
        orderNumber: payload.orderNumber,
        unitOfMeasure,
        product: new Product({
          ...product,
          parQuantity: getProductsParResult[product.productNumber].quantity || 0,
          parQuantityUnitOfMeasure: getProductsParResult[product.productNumber].unitOfMeasure || null,
          lastOrderedQuantity: historyLookup[product.productNumber]?.lastOrderedQuantity || null,
          avgSalesInEaches: historyLookup[product.productNumber]?.avgSalesInEaches || null,
          avgSalesInCases: historyLookup[product.productNumber]?.avgSalesInCases || null,
          avgCreditsInEaches: historyLookup[product.productNumber]?.avgCreditsInEaches || null,
          avgCreditsInCases: historyLookup[product.productNumber]?.avgCreditsInCases || null
        })
      })
    }
    nextLineItemNumber++
    return lineItem
  })

  nextLineItemNumber = 1

  putCollectionItem(
    cacheConstants.keys.orders,
    payload.orderNumber,
    cached
  )
}

actions.setLineItemReasonCodeAsync = ({ commit }, payload) => {
  commit(lineItemMutations.setReasonCode, payload)
}

actions.setLineItemCurrentQuantityAsync = ({ commit }, payload) => {
  commit(lineItemMutations.setCurrentQuantity, payload)
}

actions.setLineItemOrderQuantityAsync = ({ commit }, payload) => {
  commit(lineItemMutations.setOrderQuantity, payload)
}

actions.setLineItemTotalAsync = ({ commit }, payload) => {
  commit(lineItemMutations.setTotal, payload)
}

actions.setLineItemParQuantityAsync = ({ commit }, payload) => {
  commit(lineItemMutations.setParQuantity, payload)
}

actions.setLineItemUnitOfMeasureAsync = ({ commit }, payload) => {
  commit(lineItemMutations.setUnitOfMeasure, payload)
}

const names = {
  clearAsync: 'clearAsync',
  cacheLoadAllForOrderAsync: 'cacheLoadAllForOrderAsync',
  cacheSaveAllForOrderAsync: 'cacheSaveAllForOrderAsync',
  cacheSaveAllFromRemoteForOrderAsync: 'cacheSaveAllFromRemoteForOrderAsync',
  cacheUpdateAllFromRemoteForOrderAsync: 'cacheUpdateAllFromRemoteForOrderAsync',
  setLineItemReasonCodeAsync: 'setLineItemReasonCodeAsync',
  setLineItemCurrentQuantityAsync: 'setLineItemCurrentQuantityAsync',
  setLineItemOrderQuantityAsync: 'setLineItemOrderQuantityAsync',
  setLineItemTotalAsync: 'setLineItemTotalAsync',
  setLineItemParQuantityAsync: 'setLineItemParQuantityAsync',
  setLineItemUnitOfMeasureAsync: 'setLineItemUnitOfMeasureAsync'
}

const namespaced = {
  clearAsync: `lineItems/${names.clearAsync}`,
  cacheLoadAllForOrderAsync: `lineItems/${names.cacheLoadAllForOrderAsync}`,
  cacheSaveAllForOrderAsync: `lineItems/${names.cacheSaveAllForOrderAsync}`,
  cacheSaveAllFromRemoteForOrderAsync: `lineItems/${names.cacheSaveAllFromRemoteForOrderAsync}`,
  cacheUpdateAllFromRemoteForOrderAsync: `lineItems/${names.cacheUpdateAllFromRemoteForOrderAsync}`,
  setLineItemReasonCodeAsync: `lineItems/${names.setLineItemReasonCodeAsync}`,
  setLineItemCurrentQuantityAsync: `lineItems/${names.setLineItemCurrentQuantityAsync}`,
  setLineItemOrderQuantityAsync: `lineItems/${names.setLineItemOrderQuantityAsync}`,
  setLineItemTotalAsync: `lineItems/${names.setLineItemTotalAsync}`,
  setLineItemParQuantityAsync: `lineItems/${names.setLineItemParQuantityAsync}`,
  setLineItemUnitOfMeasureAsync: `lineItems/${names.setLineItemUnitOfMeasureAsync}`
}

export {
  names,
  namespaced,
  actions
}
