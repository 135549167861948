import { createRouter, createWebHashHistory } from 'vue-router'
import { dispatch, actions, getters, select } from '@/stores/store'
import { decodeAccessTokenPayload } from '@/helpers/access-token-helper'
import { routeNames, routePaths, routeDisplayNames } from '@/constants/route-constants'
import { editOrderViewBeforeEnterOrUpdateAsync, editInventoryViewBeforeEnterOrUpdateAsync } from '@/router/guards'

import HomeView from '@/areas/home/home-view.vue'
import OrdersEditView from '@/areas/orders/orders-edit-view.vue'
import CustomersSearchView from '@/areas/customers/customers-search-view.vue'
import InventoryPlacementArea from '@/areas/inventories/inventory-placement-area.vue'
import ManageProductsView from '@/areas/customers/manage-products-view.vue'

/** @type {Readonly<import('vue-router').RouteRecordRaw[]>} */
const routes = [
  {
    path: '/',
    redirect: routePaths.dashboard,
    beforeEnter: (_, __, next) => {
      next({ name: routeNames.dashboard })
    }
  },
  {
    path: routePaths.dashboard,
    name: routeNames.dashboard,
    components: {
      content: HomeView
    }
  },
  {
    path: routePaths.searchCustomers,
    name: routeNames.searchCustomers,
    components: {
      content: CustomersSearchView
    },
    meta: {
      displayName: routeDisplayNames.selectCustomer
    }
  },
  {
    path: routePaths.editOrder,
    name: routeNames.editOrder,
    components: {
      content: OrdersEditView
    },
    beforeEnter: editOrderViewBeforeEnterOrUpdateAsync
  },
  {
    path: routePaths.editInventory,
    name: routeNames.editInventories,
    components: {
      content: InventoryPlacementArea
    },
    beforeEnter: editInventoryViewBeforeEnterOrUpdateAsync
  },
  {
    path: routePaths.manageProducts,
    name: routeNames.manageProducts,
    components: {
      content: ManageProductsView
    },
    props: true,
    meta: {
      displayName: routeDisplayNames.manageProducts
    }
  }
]

const history = createWebHashHistory(process.env.BASE_URL)
const router = createRouter({ history, routes })

router.beforeEach(async () => {
  if (!getters[select.global.isInitialized]) {
    try {
      await dispatch(actions.global.setLoading, true)

      // Initialize users module with user roles from claims principal.
      await dispatch(actions.users.initializeAsync, decodeAccessTokenPayload().roles)

      const activeUser = getters[select.users.active]

      // Initialize customers module.
      await dispatch(actions.customers.initializeAsync, activeUser)

      // Load cached orders data.
      await dispatch(actions.orders.cacheLoadAsync)

      // Load cached inventories data.
      await dispatch(actions.inventories.cacheLoad)

      // Initialization complete.
      await dispatch(actions.global.initialize)

      // Get the reason codes.
      dispatch(actions.orders.addReasonCodesAsync)
    }
    finally {
      await dispatch(actions.global.setLoading, false)
    }
  }
})

export default router
