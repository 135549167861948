export const cacheKeys = {
  accessToken: 'access-token',
  activeCustomerNumber: 'dairysales_active-customer-number',
  activeUserNumber: 'dairysales_active-user-number',
  orders: 'dairysales_orders',
  inventories: 'dairysales_inventories'
}

export default {
  keys: cacheKeys
}
