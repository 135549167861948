<template>
  <one-erp-spinner :show-spinner="isLoading" />
  <div class="card-content">
    <div class="select-inventory">
      <one-erp-dropdown
        @update:model-value="({ id }) => state.selectedInventoryNumber = id"
        :disabled="!all.length"
        :model-value="selected"
        :options="all"
        :placeholder="!all.length ? 'None' : 'Select an item'"
      >
        <template #dropdown-item="{ dropdownItem }">
          <span class="label">
            {{ dropdownItem.label }}
          </span>
        </template>
      </one-erp-dropdown>
    </div>
    <div class="create-inventory">
      <one-erp-button
        @click="onSelectCreateInventoryAsync"
        :button-type="OneErpButtonTypes.solid"
        button-text="Create Inventory"
      />
    </div>
    <div class="view-inventory">
      <one-erp-button
        @click="onSelectActivateOrderAsync"
        :button-type="OneErpButtonTypes.open"
        :disabled="viewButtonDisabled"
        button-text="View"
      />
    </div>
    <div class="delete-inventory">
      <one-erp-button
        @click="onSelectDeleteInventory"
        :button-type="OneErpButtonTypes.tertiary"
        :disabled="deleteButtonDisabled"
        button-text="Delete"
      />
    </div>
  </div>
  <modal-dialog ref="modalAsync" />
</template>
<script setup>
// @ts-check
import { reactive, computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import {
  Spinner as OneErpSpinner,
  Button as OneErpButton,
  ButtonTypes as OneErpButtonTypes,
  Dropdown as OneErpDropdown
} from '@sfc-enterprise-ui/one-erp-components/src'
import raiseUserError from '@/utilities/errors/raise-user-error'
import { routeNames } from '@/constants/route-constants'
import idHelper from '@/helpers/id-helper'
import ModalDialog from '@/components/shared/modal-dialog.vue'
import { select, actions } from '@/stores/store'
import { isEmpty } from '@/utilities/type-utility'

const { state: storeState, getters, dispatch } = useStore()
const { push } = useRouter()

/** @type {import('vue').Ref<typeof import('@/components/shared/modal-dialog.vue').default?>?=} */
const modalAsync = ref(null)

const filter = (/** @type {import('@/models/Inventory').default} */inventoryModel) => (
  !inventoryModel.submittedAt &&
  inventoryModel.forUserNumber === storeState.users.active
)

const isLoading = ref(false)
const state = reactive({
  isLoading: false,
  /** @type {string?} */
  selectedInventoryNumber: null,
  /** @type {import('@/models/Inventory').default?} */
  inventoryModel: null,
  showConfirmDeleteModal: false,
  showConfirmDeleteExistingModal: false
})

onMounted(() => {
  if (state.selectedInventoryNumber === null) {
    state.selectedInventoryNumber = all.value.length ? all.value[0].id : null
  }
})

const viewButtonDisabled = computed(() =>
  !all.value.length || !selected.value
)

const deleteButtonDisabled = computed(() =>
  !all.value.length || !selected.value
)

const selected = computed(() => {
  if (state.selectedInventoryNumber === null) {
    return
  }
  const { customer, ...inventories } = getters[select.inventories.single](
    (inventories) => {
      return inventories.inventoryNumber === state.selectedInventoryNumber &&
      inventories.forCustomerNumber === storeState.customers.active &&
      inventories.byUserNumber === storeState.users.current &&
      inventories.submittedAt === null
    },
    { include: ['customer'] }
  )
  if (
    inventories.inventoryNumber &&
    customer
  ) {
    return {
      inventoryNumber: inventories.inventoryNumber,
      customer,
      id: inventories.inventoryNumber,
      value: inventories.inventoryNumber,
      label: `${customer?.customerName}`
    }
  }
  return null
})

/** @type {import('vue').ComputedRef<{id: string, value: string, label: string}[]>} */
const all = computed(() => {
  return getters[select.inventories.range](
    filter,
    {
      sort: ({ customer: a }, { customer: b }) => (a?.customerName < b?.customerName) ? -1 : 1,
      include: ['customer']
    }
  )
    .map(({ inventoryNumber, customer }) => ({
      id: inventoryNumber,
      value: inventoryNumber,
      label: `${customer?.customerName}`
    }))
})

const activeCustomerInProgressInventory = computed(() => {
  return getters[select.inventories.find](x => (
    x.forCustomerNumber === storeState.customers.active &&
    x.submittedAt === null
  ))
})

const onSelectDeleteInventory = async () => {
  const confirm = await modalAsync?.value?.modalAsync.open({
    type: 'confirm',
    headerText: 'Delete Inventory',
    bodyText: `Are you sure you want to delete your existing item for ${selected.value?.customer?.customerName || 'this customer'}?`,
    confirmText: 'Delete',
    cancelText: 'Back'
  })
  if (confirm) {
    try {
      await dispatch(
        actions.inventories.cacheRemove,
        selected.value
      )
      await dispatch(
        actions.inventories.removeAsync,
        selected.value
      )
    }
    finally {
      state.inventoryModel = null
    }
  } else {
    state.inventoryModel = null
  }
}

const onSelectCreateInventoryAsync = async () => {
  if (!isEmpty(activeCustomerInProgressInventory.value)) {
    state.inventoryModel = activeCustomerInProgressInventory.value
    const confirm = await modalAsync?.value?.modalAsync.open({
      type: 'confirm',
      headerText: 'Delete Exiting Inventory',
      bodyText: `Creating a new inventory requires first submitting or deleting your existing inventory.<br><br> Are you sure you want to delete your existing item for ${selected.value?.customer?.customerName || 'this customer'}?`,
      confirmText: 'Delete and Create',
      cancelText: 'Back'
    })
    if (confirm) {
      try {
        await dispatch(
          actions.inventories.cacheRemove,
          selected.value
        )
        await dispatch(
          actions.inventories.removeAsync,
          selected.value
        )

        await createInventoryAsync()
      }
      finally {
        state.inventoryModel = null
      }
    } else {
      state.inventoryModel = null
    }
  }
  else {
    try {
      await createInventoryAsync()
    }
    catch (err) {
      raiseUserError(
        err.message,
        'Could not create the Inventory.',
        'Please try again and contact support if this problem continues.'
      )
    }
  }
}

const onSelectActivateOrderAsync = async () => {
  state.isLoading = true

  if (!viewButtonDisabled.value) {
    push({
      name: routeNames.editInventories,
      params: { inventoryNumber: selected.value?.inventoryNumber }
    })
  }
}

const createInventoryAsync = async () => {
  isLoading.value = true
  let inventory
  const byUser = getters[select.users.current]
  const forUser = getters[select.users.active]
  const forCustomer = getters[select.customers.active]
  try {
    if (byUser && forUser && forCustomer) {
      inventory = {
        inventoryNumber: 'DSI_' + idHelper.random(13).toUpperCase(),
        byUserNumber: byUser.salesRepNumber,
        forUserNumber: forUser.salesRepNumber,
        forCustomerNumber: forCustomer.customerNumber
      }
    }
    else {
      throw new Error('Missing dependencies to create new inventory.')
    }

    await dispatch(actions.inventories.createAsync, inventory)
    await dispatch(actions.inventories.cacheCreateAsync, inventory)
    await dispatch(actions.inventoryItems.cacheSaveAllFromRemoteForInventoryAsync, inventory)

    await push({
      name: routeNames.editInventories,
      params: { inventoryNumber: inventory.inventoryNumber }
    })
  }
  catch (err) {
    try {
      await dispatch(actions.inventories.removeAsync, inventory)
      await dispatch(actions.inventories.cacheRemove, inventory)
    }
    catch { }
    raiseUserError(
      err.message,
      'Could not create the Inventory.',
      'Please try again and contact support if this problem continues.'
    )
  }
  finally {
    isLoading.value = false
  }
}
</script>
<style scoped lang="scss">
.card-content {
  display: grid;
  grid-template-areas:
    'tp tp tp'
    'bl bm br';
  row-gap: 0;
  grid-template-rows: 1fr auto;
  grid-template-columns: repeat(3, auto);
  height: 100%;
  width: 100%;

  .select-inventory {
    grid-area: tp;
    margin-bottom: 6rem;
  }

  .create-inventory {
    grid-area: bl;
    justify-self: start;
  }

  .view-inventory {
    grid-area: bm;
    justify-self: center;
  }

  .delete-inventory {
    grid-area: br;
    margin-top: 1rem;
    justify-self: end;
  }
}
</style>
