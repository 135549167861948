class GetProductsResponseDto {
  /**
   * @param {object} dto
   * @param {string?} [dto.warehouseNumber]
   * @param {string?} [dto.customerNumber]
   * @param {string?} [dto.productNumber]
   * @param {string?} [dto.productDescription]
   * @param {string?} [dto.vendorName]
   * @param {string?} [dto.packSizeDescription]
   * @param {string?} [dto.purchaseUom]
   * @param {number?} [dto.quantityPerCase]
   * @param {string?} [dto.priceUom]
   * @param {number?} [dto.onHandEachesQuantity]
   * @param {number?} [dto.onHandCasesQuantity]
   * @param {number?} [dto.sequenceGroupNumber]
   * @param {string?} [dto.sequenceGroupDescription]
   * @param {number?} [dto.pricePerEach]
   * @param {number?} [dto.pricePerCase]
   */
  constructor ({
    /** @type {string?} */
    warehouseNumber = null,
    /** @type {string?} */
    customerNumber = null,
    /** @type {string?} */
    productNumber = null,
    /** @type {string?} */
    productDescription = null,
    /** @type {string?} */
    vendorName = null,
    /** @type {string?} */
    packSizeDescription = null,
    /** @type {string?} */
    purchaseUom = null,
    /** @type {number?} */
    quantityPerCase = null,
    /** @type {string?} */
    priceUom = null,
    /** @type {number?} */
    onHandEachesQuantity = null,
    /** @type {number?} */
    onHandCasesQuantity = null,
    /** @type {number?} */
    sequenceGroupNumber = null,
    /** @type {string?} */
    sequenceGroupDescription = null,
    /** @type {number?} */
    pricePerEach = null,
    /** @type {number?} */
    pricePerCase = null
  }) {
    this.warehouseNumber = warehouseNumber
    this.customerNumber = customerNumber
    this.productNumber = productNumber
    this.productDescription = productDescription
    this.vendorName = vendorName
    this.packSizeDescription = packSizeDescription
    this.purchaseUom = purchaseUom
    this.quantityPerCase = quantityPerCase
    this.priceUom = priceUom
    this.onHandEachesQuantity = onHandEachesQuantity
    this.onHandCasesQuantity = onHandCasesQuantity
    this.sequenceGroupNumber = sequenceGroupNumber
    this.sequenceGroupDescription = sequenceGroupDescription
    this.pricePerEach = pricePerEach
    this.pricePerCase = pricePerCase
  }
}

export default GetProductsResponseDto
