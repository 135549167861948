import rolesConstants from '@/constants/roles-constants'

class User {
  /**
   * @param {object} dto
   * @param {string?} [dto.salesRepNumber]
   * @param {string?} [dto.salesRepName]
   * @param {string?} [dto.username]
   * @param {string?} [dto.email]
   * @param {string?} [dto.employeeNumber]
   * @param {string[]} [dto.roles]
   */
  constructor ({
    salesRepNumber = null,
    salesRepName = null,
    username = null,
    email = null,
    employeeNumber = null,
    roles = []
  }) {
    /** @type {string?} */
    this.salesRepNumber = salesRepNumber
    /** @type {string?} */
    this.salesRepName = salesRepName
    /** @type {string?} */
    this.username = username
    /** @type {string?} */
    this.email = email
    /** @type {string?} */
    this.employeeNumber = employeeNumber
    /** @type {string[]} */
    this.roles = roles
  }

  get isImpersonationUser () {
    return this.roles.includes(rolesConstants.names.impersonationUser)
  }
}

export default User
