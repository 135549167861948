class Address {
  /**
   * @param {object} dto
   * @param {string?} [dto.street]
   * @param {string?} [dto.state]
   * @param {string?} [dto.city]
   * @param {string?} [dto.zipCode]
   */
  constructor ({
    street = null,
    state = null,
    city = null,
    zipCode = null
  }) {
    /** @type {string?} */
    this.street = street
    /** @type {string?} */
    this.state = state
    /** @type {string?} */
    this.city = city
    /** @type {string?} */
    this.zipCode = zipCode
  }

  /** @readonly {string} */
  get singleLine () {
    return `${this.street}, ` + `${this.city}, ` + `${this.state} ` + `${this.zipCode}`
  }
}

export default Address
