<template>
  <div class="customers-quick-stats">
    {{ data }}
  </div>
</template>
<script setup>
import { getters, select } from '@/stores/store'
import { computed } from 'vue'

const props = defineProps({
  customerNumber: {
    type: String,
    required: true
  }
})

const data = computed(() => {
  const { orders } = getters[select.customers.single](
    (/** @type {import('@/models/Customer').default} */customer) => customer.customerNumber === props.customerNumber,
    { include: ['orders'] }
  )
  if (orders) {
    let buffer = ''
    if (orders.hasInProgressOrderOrCredit) {
      const ordersCount = orders.inProgressOrders.length
      const creditsCount = orders.inProgressCredits.length
      if (ordersCount) {
        buffer += ordersCount
        buffer += ` order${ordersCount > 1 ? 's' : ''}`
      }
      if (ordersCount && creditsCount) {
        buffer += ' & '
      }
      if (creditsCount) {
        buffer += creditsCount
        buffer += ` credit${creditsCount > 1 ? 's' : ''}`
      }
      buffer += ' started'
    }
    if (
      orders.hasInProgressOrderOrCredit &&
      orders.hasSubmittedOrderOrCredit
    ) {
      buffer += ', '
    }
    if (orders.hasSubmittedOrderOrCredit) {
      const ordersCount = orders.submittedOrders.length
      const creditsCount = orders.submittedCredits.length
      if (ordersCount) {
        buffer += ordersCount
        buffer += ` order${ordersCount > 1 ? 's' : ''}`
      }
      if (ordersCount && creditsCount) {
        buffer += ' & '
      }
      if (creditsCount) {
        buffer += creditsCount
        buffer += ` credit${creditsCount > 1 ? 's' : ''}`
      }
      buffer += ' submitted'
    }
    return buffer
  }
  else {
    return null
  }
})
</script>
