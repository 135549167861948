import { actions } from '@/stores/modules/inventories/inventories-store.actions'
import { getters } from '@/stores/modules/inventories/inventories-store.getters'
import { mutations } from '@/stores/modules/inventories/inventories-store.mutations'

const store = {
  state: {
    active: null,
    all: {}
  },
  strict: true,
  namespaced: true,
  actions,
  getters,
  mutations
}

export default store
