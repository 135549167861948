import Address from '@/models/Address'

class GetCustomersResponseDto {
  constructor ({
    customerNumber = /** @type {string?} */(null),
    customerName = /** @type {string?} */(null),
    address = /** @type {Address} */({}),
    deliveryDays = /** @type {string[]} */([]),
    salesRepNumbers = /** @type {string[]} */([]),
    warehouseNumbers = /** @type {string[]} */([]),
    hasConsignedInventory = /** @type {boolean?} */(null)
  }) {
    /** @type {string?} */
    this.customerNumber = customerNumber
    /** @type {string?} */
    this.customerName = customerName
    /** @type {Address} */
    this.address = new Address(address)
    /** @type {string[]} */
    this.deliveryDays = deliveryDays
    /** @type {string[]} */
    this.salesRepNumbers = salesRepNumbers
    /** @type {string[]} */
    this.warehouseNumbers = warehouseNumbers
    /** @type {boolean?} */
    this.hasConsignedInventory = hasConsignedInventory
  }
}

export default GetCustomersResponseDto
