import { getCollectionItem, putCollectionItem } from '@/helpers/cache-helper'
import { names as inventoryItemMutations } from '@/stores/modules/inventory-items/inventory-items-store.mutations'
import { getProductsInventoryAsync } from '@/services/products-service'
import { select } from '@/stores/store'
import cacheConstants from '@/constants/cache-constants'
import InventoryItem from '@/models/InventoryItem'
import { mapGetProductsInventoryResponseDtoToProductInventoryModel } from '@/utilities/mappers/product-mappers'
import { prependNamespace } from '@sfc-enterprise-ui/utils/src'

const namespace = 'inventoryItems/'
const names = {
  clearAsync: 'clearAsync',
  cacheLoadAllForInventoryAsync: 'cacheLoadAllForInventoryAsync',
  cacheSaveAllForInventoryAsync: 'cacheSaveAllForInventoryAsync',
  cacheSaveAllFromRemoteForInventoryAsync: 'cacheSaveAllFromRemoteForInventoryAsync',
  setInventoryItemWarehouseQuantityAsync: 'setInventoryItemWarehouseQuantityAsync',
  setInventoryItemShelfQuantityAsync: 'setInventoryItemShelfQuantityAsync',
  setInventoryItemOtherQuantityAsync: 'setInventoryItemOtherQuantityAsync',
  setInventoryItemTotalAsync: 'setInventoryItemTotalAsync'
}

const actions = {
  [names.clearAsync]: ({ commit }) => commit(inventoryItemMutations.clear),

  [names.cacheLoadAllForInventoryAsync]: async ({ commit }, payload) => {
    commit(inventoryItemMutations.clear)
    commit(
      inventoryItemMutations.addRange,
      {
        key: payload.inventoryNumber,
        value: getCollectionItem(cacheConstants.keys.inventories, payload.inventoryNumber)?.inventoryItems || []
      }
    )
  },

  [names.cacheSaveAllForInventoryAsync]: async ({ rootGetters }, payload) => {
    putCollectionItem(
      cacheConstants.keys.inventories,
      payload.inventoryNumber,
      ({ inventory }) => ({
        inventory,
        inventoryItems: rootGetters[select.inventoryItems.filter](x => x.inventoryNumber === payload.inventoryNumber)
      })
    )
  },

  /**
   * @param {import('@/models/Inventory').default} payload
   */
  [names.cacheSaveAllFromRemoteForInventoryAsync]: async (_, payload) => {
    const customerNumber = payload.forCustomerNumber
    const cached = getCollectionItem(cacheConstants.keys.inventories, payload.inventoryNumber)
    let nextInventoryItemNumber = 1

    const getProductsResult =
      (await getProductsInventoryAsync({ customerNumber }))
        .map((x) => mapGetProductsInventoryResponseDtoToProductInventoryModel(x))

    cached.inventoryItems = getProductsResult.map(product => {
      const inventoryItem = new InventoryItem({
        inventoryItemNumber: nextInventoryItemNumber,
        inventoryNumber: payload.inventoryNumber,
        product
      })
      nextInventoryItemNumber++
      return inventoryItem
    })

    nextInventoryItemNumber = 1

    putCollectionItem(
      cacheConstants.keys.inventories,
      payload.inventoryNumber,
      cached
    )
  },

  [names.setInventoryItemWarehouseQuantityAsync]: ({ commit }, payload) => {
    commit(inventoryItemMutations.setWarehouseQuantity, payload)
  },

  [names.setInventoryItemShelfQuantityAsync]: ({ commit }, payload) => {
    commit(inventoryItemMutations.setShelfQuantity, payload)
  },

  [names.setInventoryItemOtherQuantityAsync]: ({ commit }, payload) => {
    commit(inventoryItemMutations.setOtherQuantity, payload)
  },

  [names.setInventoryItemTotalAsync]: ({ commit }, payload) => {
    commit(inventoryItemMutations.setSubtotalQuantity, payload)
  }
}

const namespaced = prependNamespace(namespace, names)

export {
  names,
  namespaced,
  actions
}
