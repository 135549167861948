import { postAsync } from '@sfc-enterprise-ui/fetch-api'

/**
 * @param {import('@/services/dto/PostInventoriesRequestDto').IPostInventoriesRequestDto} inventoryDto
 * @returns {Promise<null>}
 */
export const postInventoriesAsync = async (inventoryDto) => {
  await postAsync('/inventories', inventoryDto)

  return null
}
