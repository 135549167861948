import dayjs from 'dayjs'
import InventoryItem from '@/models/InventoryItem'
import { addRange, clear, removeRange, updateRange } from '@/stores/modules/common/common.mutations'
import { moduleStateIsActivated, moduleStateHasItemByKey } from '@/stores/modules/common/common.validators'
import { isDefined, prependNamespace } from '@sfc-enterprise-ui/utils/src'

const keyName = 'inventoryItemNumber'

const namespace = 'inventoryItems/'
const names = {
  add: 'add',
  addRange: 'addRange',
  clear: 'clear',
  remove: 'remove',
  setWarehouseQuantity: 'setWarehouseQuantity',
  setShelfQuantity: 'setShelfQuantity',
  setOtherQuantity: 'setOtherQuantity',
  setSubtotalQuantity: 'setSubtotalQuantity',
  updateRange: 'updateRange'
}

const mutations = {
  [names.add]: (state, payload) => addRange(state, {
    items: [new InventoryItem(payload.value)],
    keyName
  }),

  [names.addRange]: (state, payload) => addRange(state, {
    items: payload.value.map(x => (new InventoryItem(x))),
    keyName
  }),

  [names.clear]: (state) => clear(state),

  [names.remove]: (state, payload) => removeRange(state, [payload]),

  [names.setWarehouseQuantity]: (state, { key, value }) => {
    moduleStateIsActivated(state)
    moduleStateHasItemByKey(state, key)
    let warehouseQuantity = !isNaN(parseInt(value, 10)) ? parseInt(value, 10) : null
    if (warehouseQuantity !== null && warehouseQuantity < 0) {
      warehouseQuantity = 0
    }
    updateRange(state, {
      items: [new InventoryItem({
        ...state.all[key],
        warehouseQuantity,
        updatedAt: dayjs().toISOString()
      })],
      keyName
    }
    )
  },

  [names.setShelfQuantity]: (state, { key, value }) => {
    moduleStateIsActivated(state)
    moduleStateHasItemByKey(state, key)
    let shelfQuantity = !isNaN(parseInt(value, 10)) ? parseInt(value, 10) : null
    if (shelfQuantity !== null && shelfQuantity < 0) {
      shelfQuantity = 0
    }
    updateRange(state, {
      items: [new InventoryItem({
        ...state.all[key],
        shelfQuantity,
        updatedAt: dayjs().toISOString()
      })],
      keyName
    }
    )
  },

  [names.setOtherQuantity]: (state, { key, value }) => {
    moduleStateIsActivated(state)
    moduleStateHasItemByKey(state, key)
    let otherQuantity = !isNaN(parseInt(value, 10)) ? parseInt(value, 10) : null
    if (otherQuantity !== null && otherQuantity < 0) {
      otherQuantity = 0
    }
    updateRange(state, {
      items: [new InventoryItem({
        ...state.all[key],
        otherQuantity,
        updatedAt: dayjs().toISOString()
      })],
      keyName
    }
    )
  },
  /**
   * @param {any} state
   * @param {string} payload - the key to lookup
   */
  [names.setSubtotalQuantity]: (state, payload) => {
    moduleStateIsActivated(state)
    /** @type {InventoryItem} */
    const item = state.all[payload]
    let subtotalQuantity = null
    if (isDefined(item.warehouseQuantity) || isDefined(item.shelfQuantity) || isDefined(item.otherQuantity)) {
      subtotalQuantity = (item.warehouseQuantity ?? 0) + (item.shelfQuantity ?? 0) + (item.otherQuantity ?? 0)
    }
    updateRange(state, {
      items: [new InventoryItem({
        ...item,
        subtotalQuantity,
        updatedAt: dayjs().toISOString()
      })],
      keyName
    })
  },

  [names.updateRange]: (state, payload) => updateRange(state, {
    items: payload.value,
    keyName
  })
}

const namespaced = prependNamespace(namespace, names)

export {
  names,
  namespaced,
  mutations
}
