import { filter, byKey, active, find, first, isEmpty, isNotEmpty, range, single } from '@/stores/modules/common/common.getters'

const reducer = (inventory, { customers, inventoryItems, users }, { include }) => {
  const retVal = { ...inventory || {} }

  if (include?.includes('customer')) {
    retVal.customer = byKey(customers, inventory.forCustomerNumber) || null
  }

  if (include?.includes('inventoryItems')) {
    retVal.inventoryItems = filter(inventoryItems, x => x.inventoryNumber === inventory.inventoryNumber)
  }

  if (include?.includes('user')) {
    retVal.user = byKey(users, inventory.byUserNumber) || null
  }

  return retVal
}

const getters = {}

getters.active = (state) => active(state)

getters.byCustomerNumber = (state) => (key) => find(state, x => x.forCustomerNumber === key)

getters.byNumber = (state) => (key) => byKey(state, key)

getters.find = (state) => (searchPredicate) => find(state, searchPredicate)

getters.first = (state) => first(state)

getters.isEmpty = (state) => isEmpty(state)

getters.isNotEmpty = (state) => isNotEmpty(state)

getters.range = (state, _, rootState) => (where, options) => {
  return range(state, rootState, reducer, where, options)
}

getters.single = (state, _, rootState) => (where, options) => {
  return single(state, rootState, reducer, where, options)
}

const names = {
  active: 'active',
  byCustomerNumber: 'byCustomerNumber',
  byNumber: 'byNumber',
  find: 'find',
  first: 'first',
  isEmpty: 'isEmpty',
  isNotEmpty: 'isNotEmpty',
  range: 'range',
  single: 'single'
}

const namespaced = {
  active: 'inventories/active',
  byCustomerNumber: 'inventories/byCustomerNumber',
  byNumber: 'inventories/byNumber',
  find: 'inventories/find',
  first: 'inventories/first',
  isEmpty: 'inventories/isEmpty',
  isNotEmpty: 'inventories/isNotEmpty',
  range: 'inventories/range',
  single: 'inventories/single'
}

export {
  names,
  namespaced,
  getters
}
