<template>
  <erp-spinner :show-spinner="isLoading" />
  <div class="orders-line-items-grid">
    <one-erp-simple-grid
      max-grid-height="calc(100vh - 29rem)"
      :column-attributes="columnDefs"
      :item-id-param="ITEM_KEY"
      :row-data="productItemsFilteredSorted"
      :enable-grid-tips="false"
      :hide-vertical-separators="true"
      :selectable="true"
      :active-items="activeItems"
      @row-selected="rowSelected"
    >
      <template #cellcontent="{row, column}">
        <component
          :is="column.renderer"
          :row-data="row"
          :column-data="column"
          :value="row[column.field]"
        />
      </template>
    </one-erp-simple-grid>
  </div>
</template>
<script setup>
import { onMounted, ref, computed } from 'vue'
import {
  Spinner as ErpSpinner,
  SimpleGrid as OneErpSimpleGrid
} from '@sfc-enterprise-ui/one-erp-components/src'
import { columnDefs } from '@/areas/customers/components/products-column-defs'
import { getProductsManageAsync } from '@/services/products-service'
import { productManageFields } from '@/constants/product-constants'

const ITEM_KEY = productManageFields.productNumber

const props = defineProps({
  customerNumber: { type: String, required: true },
  filters: { type: Array, required: false, default: () => [] },
  sorts: { type: Array, required: false, default: () => [] },
  modelValue: { type: Array, required: true }
})

const emit = defineEmits(['update:modelValue'])

const isLoading = ref(false)
const productItems = ref()
const activeItems = ref()

onMounted(async () => {
  isLoading.value = true
  productItems.value = await getProductsManageAsync({ customerNumber: props.customerNumber })
  emit('update:modelValue', productItems.value)
  activeItems.value = productItems.value.filter((item) => !item.shouldHideSku).map((item) => item[ITEM_KEY])
  isLoading.value = false
})

const productItemsFilteredSorted = computed(() => {
  let products = productItems.value ?? []
  if (props.filters.length) {
    for (const filter of props.filters) {
      products = products.filter(filter)
    }
  }
  if (props.sorts.length) {
    for (const sort of props.sorts) {
      products.sort(sort)
    }
  }
  return products
})

const rowSelected = (/** @type {number} */ productNumber) => {
  const found = productItems.value.find((item) => item.productNumber === productNumber)
  if (found) {
    found.shouldHideSku = !found.shouldHideSku
    emit('update:modelValue', productItems.value)
  }
  activeItems.value.includes(productNumber)
    ? (activeItems.value = activeItems.value.filter((x) => x !== productNumber))
    : activeItems.value.push(productNumber)
}
</script>
<style lang="scss" scoped>
@use "~@sfc-enterprise-ui/one-erp-theme/one-erp-theme.scss" as *;
.description {
  display: grid;
  grid-template-rows: auto auto;
  align-content: center;
  height: 4.4rem;
  .product {
    color: var(--text-alt-color);
    font-weight: bold;
  }
  .vendor {
    font-family: $font-family-content;
    letter-spacing: 0.4px;
    color: var(--label-text-color);
    margin-top: 0.4rem;
  }
}
.number,
.vendor-name {
  height: 4.4rem;
  line-height: 4.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
