class PutProductsManageRequestDto {
  /**
   * @param {object} dto
   * @param {string} dto.productNumber
   * @param {boolean} dto.shouldHideSku
   */
  constructor ({
    productNumber,
    shouldHideSku
  }) {
    /** @type {string} */
    this.productNumber = productNumber
    /** @type { boolean} */
    this.shouldHideSku = shouldHideSku
  }
}

export default PutProductsManageRequestDto
