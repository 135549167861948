<template>
  <div
    class="number-input"
    ref="tooltip"
  >
    <mouseover-tooltip
      v-show="showErrorCircle"
      :element-ref-to-attach-to="tooltip"
      :anchor-to-right="true"
    >
      All values must be entered per product.
    </mouseover-tooltip>
    <one-erp-form-input
      @change="(value) => $emit('update', /** @type {IRowData} */(rowData).inventoryItemNumber, columnData.field, value)"
      :model-value="value"
      :disabled="columnData.disabled"
      :input-type="OneErpInputType.text"
      inputmode="numeric"
      @click="(event) => event.target.setSelectionRange(0, value?.toString().length)"
      @keydown="(event) => { if( isNaN(Number(event.key))) event.preventDefault() }"
      width="2rem"
    />
    <svg-chooser
      v-show="showErrorCircle"
      :svg-name="SvgConstants.errorCircle"
      :svg-size="2.4"
      :svg-color="OneErpColors.darkThemeRed"
      :svg-background-color="OneErpColors.transparent"
    />
  </div>
</template>

<script setup>
/** @typedef {import('@/models/interfaces/IInputCellRenderer').IInputCellRenderer} IRowData */

import {
  FormInput as OneErpFormInput,
  InputType as OneErpInputType,
  SvgChooser,
  SvgConstants,
  OneErpColors,
  MouseoverTooltip
} from '@sfc-enterprise-ui/one-erp-components/src'
import { ref } from 'vue'

defineProps({
  rowData: { type: Object, required: false, default: null },
  columnData: { type: Object, required: false, default: null },
  value: { type: [Object, String, Number], required: false, default: null },
  showErrorCircle: { type: Boolean, required: false, default: false }
})

defineEmits([
  'update'
])

const tooltip = ref()
</script>

<style lang="scss" scoped>
.number-input {
  position: relative;
  display: flex;
  gap: 0.3rem;
  padding: 0.4rem;
  align-items: center;
  .override-indicator {
    background-color: var(--primary-color);
    border-radius: 50%;
    height: 0.8rem;
    position: absolute;
    right: 0.6rem;
    top: 0.7rem;
    width: 0.8rem;
  }
}
</style>
