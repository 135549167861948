<template>
  <div class="orders-line-items-grid">
    <one-erp-simple-grid
      max-grid-height="calc(100vh - 32rem)"
      :column-attributes="schema"
      :row-data="all"
      :enable-grid-tips="false"
      :hide-vertical-separators="true"
    >
      <template #cellcontent="{ row, column }">
        <div class="number" v-if="column.field === orderGridFields.productNumber">
          {{ row.productNumber }}
        </div>
        <div class="description" v-if="column.field === orderGridFields.productDescription">
          <span class="product">{{ row.productDescription }}</span>
          <span class="vendor">{{ row.sequenceGroupDescription }} | {{ row.vendorName }} | {{ row.warehouseName }}</span>
        </div>
        <div class="last-ordered-quantity" v-if="column.field === orderGridFields.lastOrderedQuantity">
          {{ row.lastOrderedQuantity }}
        </div>
        <div class="avg-sales" v-if="column.field === orderGridFields.avgSales">
          {{ row.avgSales }}
        </div>
        <div class="avg-credits" v-if="column.field === orderGridFields.avgCredits">
          {{ row.avgCredits }}
        </div>
        <div class="pack-size-description" v-if="column.field === orderGridFields.packSizeDescription">
          {{ row.packSizeDescription }}
        </div>
        <div class="on-hand-quantity" v-if="column.field === orderGridFields.onHandQuantity">
          {{ getFormattedOnHand(row) }}
        </div>
        <div class="price" v-if="column.field === orderGridFields.price">
          {{ getFormattedPrice(row) }}
        </div>
        <div class="return-reason" v-if="column.field === orderGridFields.reasonCode">
          <one-erp-dropdown
            @update:model-value="({ value }) => $emit('update-reason-code', row.lineItemNumber, value)"
            :model-value="row.reasonCodes?.find((x) => x.value === row.reasonCode)"
            :options="row.reasonCodes"
            placeholder="Select a reason"
          />
        </div>
        <div class="uom" v-if="column.field === orderGridFields.unitOfMeasure">
          <orders-switch-uom
            @select-is-case="(x) => $emit('select-uom-is-each', row.lineItemNumber, x)"
            :is-case="row.lineItemIsUomCases"
            :is-disabled="!row.canPurchaseByEach"
          />
        </div>
        <div class="par-quantity" v-if="column.field === orderGridFields.parQuantity">
          <one-erp-input
            @change="(x) => $emit('update-par-quantity', row.lineItemNumber, x)"
            @change:model-value="() => row.lineItemParQuantityDefault"
            :model-value="row.lineItemParQuantityDefault"
            :input-type="OneErpInputType.number"
            width="4.2rem"
          />
          <div class="override-indicator" v-if="row.parQuantityOverride" />
        </div>
        <div class="current-quantity" v-if="column.field === orderGridFields.currentQuantity">
          <one-erp-input
            @change="(x) => $emit('update-current-quantity', row.lineItemNumber, x)"
            @change:model-value="() => row.lineItemCurrentQuantity"
            :model-value="row.lineItemCurrentQuantity"
            :input-type="OneErpInputType.number"
            width="4.2rem"
          />
        </div>
        <div class="order-quantity" v-if="column.field === orderGridFields.orderQuantity">
          <one-erp-input
            @change="(x) => $emit('update-order-quantity', row.lineItemNumber, x)"
            @change:model-value="() => row.lineItemOrderQuantity"
            :model-value="row.lineItemOrderQuantity"
            :disabled="row.orderInputDisabled"
            :input-type="OneErpInputType.number"
            width="4.2rem"
          />
        </div>
        <div class="fine-controls" v-if="column.field === orderGridFields.fineControls">
          <one-erp-svg
            @click="$emit('update-order-quantity', row.lineItemNumber, (row.lineItemOrderQuantity + 1))"
            :svg-size="2.4"
            :svg-name="svgConstants.add"
            :svg-color="colorConstants.white"
            :svg-background-color="colorConstants.shamrock"
          />
          <one-erp-svg
            @click="$emit('update-order-quantity', row.lineItemNumber, (row.lineItemOrderQuantity - 1))"
            :svg-size="2.4"
            :svg-name="svgConstants.subtract"
            :svg-color="colorConstants.white"
            :svg-background-color="colorConstants.shamrock"
          />
        </div>
      </template>
    </one-erp-simple-grid>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { getters, select } from '@/stores/store'
import {
  Dropdown as OneErpDropdown,
  FormInput as OneErpInput,
  OneErpColors as colorConstants,
  SimpleGrid as OneErpSimpleGrid,
  SvgChooser as OneErpSvg,
  SvgConstants as svgConstants,
  InputType as OneErpInputType
} from '@sfc-enterprise-ui/one-erp-components/src'
import OrdersSwitchUom from '@/components/orders/orders-inputs-uom.vue'
import { orderGridFields, unitOfMeasureCodes } from '@/constants/orders-constants'
import { isDefined } from '@sfc-enterprise-ui/utils/src'

const props = defineProps({
  hideProductNumber: { type: Boolean, required: false, default: false },
  hideProductDescription: { type: Boolean, required: false, default: false },
  hideLastOrderedQuantity: { type: Boolean, required: false, default: false },
  hideAvgSales: { type: Boolean, required: false, default: false },
  hideAvgCredits: { type: Boolean, required: false, default: false },
  hideReturnReasonCode: { type: Boolean, required: false, default: false },
  hideProductWarehouseName: { type: Boolean, required: false, default: false },
  hideProductVendorName: { type: Boolean, required: false, default: false },
  hideProductPackSizeDescription: { type: Boolean, required: false, default: false },
  hideProductOnHandQuantity: { type: Boolean, required: false, default: false },
  hideProductPrice: { type: Boolean, required: false, default: false },
  hideUnitOfMeasure: { type: Boolean, required: false, default: false },
  hideParQuantity: { type: Boolean, required: false, default: false },
  hideCurrentQuantity: { type: Boolean, required: false, default: false },
  hideOrderQuantity: { type: Boolean, required: false, default: false },
  hideFineControls: { type: Boolean, required: false, default: false },
  currentQuantityHeaderText: { type: String, required: false, default: 'Inv. (EA)' },
  orderQuantityHeaderText: { type: String, required: false, default: 'Order' },
  defaultUom: { type: String, required: false, default: null },
  orderNumber: { type: String, required: true },
  filters: { type: Array, required: false, default: () => [] },
  sorts: { type: Array, required: false, default: () => [] },
  isOrder: { type: Boolean, required: true },
  isCredit: { type: Boolean, required: true }

})

defineEmits([
  'select-uom-is-each',
  'update-par-quantity',
  'update-current-quantity',
  'update-order-quantity',
  'update-reason-code'
])

const schema = computed(() => {
  const retVal = []

  if (!props.hideProductNumber) {
    retVal.push({ header: 'Product', field: orderGridFields.productNumber, width: '9rem' })
  }
  if (!props.hideProductDescription) {
    retVal.push({ header: 'Description', field: orderGridFields.productDescription, width: '3fr' })
  }
  if (!props.hideLastOrderedQuantity) {
    retVal.push({ header: 'Last Order', field: orderGridFields.lastOrderedQuantity, width: '12rem' })
  }
  if (!props.hideAvgSales) {
    retVal.push({ header: 'AVG Sales', field: orderGridFields.avgSales, width: '12rem' })
  }
  if (!props.hideAvgCredits) {
    retVal.push({ header: 'AVG Credits', field: orderGridFields.avgCredits, width: '12rem' })
  }
  if (!props.hideProductPackSizeDescription) {
    retVal.push({ header: 'Case Count', field: orderGridFields.packSizeDescription, width: '12rem' })
  }
  if (!props.hideProductOnHandQuantity) {
    retVal.push({ header: 'On Hand', field: orderGridFields.onHandQuantity, width: '12rem' })
  }
  if (!props.hideProductPrice) {
    retVal.push({ header: 'Price', field: orderGridFields.price, width: '12rem' })
  }
  if (!props.hideReturnReasonCode) {
    retVal.push({ header: 'Reason', field: orderGridFields.reasonCode, width: '22rem' })
  }
  if (!props.hideUnitOfMeasure) {
    retVal.push({ header: 'UOM', field: orderGridFields.unitOfMeasure, width: '11rem' })
  }
  if (!props.hideParQuantity) {
    retVal.push({ header: 'PAR (EA)', field: orderGridFields.parQuantity, width: '8rem' })
  }
  if (!props.hideCurrentQuantity) {
    retVal.push({ header: props.currentQuantityHeaderText, field: orderGridFields.currentQuantity, width: '8rem' })
  }
  if (!props.hideOrderQuantity) {
    retVal.push({ header: props.orderQuantityHeaderText, field: orderGridFields.orderQuantity, width: '8rem' })
  }
  if (!props.hideFineControls) {
    retVal.push({ header: '', field: orderGridFields.fineControls, width: '8rem' })
  }
  return retVal
})

const all = computed(() => {
  return getters[select.lineItems.range](
    [
      (x) => x.lineItemOrderNumber === props.orderNumber,
      ...props.filters.filter((x) => !!x)
    ],
    {
      sort: [
        /** @param {import('@/models/LineItem').default} a
         * @param {import('@/models/LineItem').default} b
        */
        (a, b) => (a.product?.productDescription ?? '') < (b.product?.productDescription ?? '') ? -1 : 1,
        ...props.sorts.filter((x) => !!x)
      ],
      /** @param {import('@/models/LineItem').default} x */
      map: (x) => ({
        lineItemNumber: x.lineItemNumber,
        lineItemOrderNumber: x.orderNumber,
        lineItemCurrentQuantity: x.currentQuantity,
        lineItemOrderQuantity: x.orderQuantity,
        lineItemParQuantityDefault: x.parQuantityDefault,
        lineItemParQuantityHasOverride: x.parQuantityHasOverride,
        lineItemIsUomEaches: x.isUomEaches,
        lineItemIsUomCases: x.isUomCases,
        ...x.product,
        warehouseName: x.product?.warehouseName,
        canPurchaseByEach: x.product?.canPurchaseByEach,
        canPurchaseByCase: x.product?.canPurchaseByCase,
        reasonCode: x.reasonCode,
        reasonCodes: getters[select.orders.reasonCodesByWarehouse](x.product?.warehouseNumber)
          ?.map((y) => ({
            id: y.code,
            value: y.code,
            label: y.description
          })),
        orderInputDisabled: props.isCredit && !x.reasonCode,
        lastOrderedQuantity: getLastOrderedQuantityFormatted(x),
        avgSales: getAvgSalesFormatted(x),
        avgCredits: getAvgCreditsFormatted(x)
      })
    }
  )
})

const getFormattedPrice = (row) => {
  if (props.defaultUom === unitOfMeasureCodes.each || row.lineItemIsUomEaches) {
    return `$ ${row.pricePerEach.toFixed(2)} (EA)`
  }
  else if (props.defaultUom === unitOfMeasureCodes.case || row.lineItemIsUomCases) {
    return `$ ${row.pricePerCase.toFixed(2)} (CS)`
  }
  else {
    return 'None'
  }
}

const getFormattedOnHand = (row) => {
  if (props.defaultUom === unitOfMeasureCodes.each || row.lineItemIsUomEaches) {
    return `${row.onHandUnitsQuantity} (EA)`
  }
  else if (props.defaultUom === unitOfMeasureCodes.case || row.lineItemIsUomCases) {
    return `${row.onHandCasesQuantity} (CS)`
  }
  else {
    return 'None'
  }
}

const getAvgSalesFormatted = (row) => row.isUomEaches
  ? isDefined(row.product.avgSalesInEaches)
    ? `${row.product.avgSalesInEaches} (EA)`
    : 'None'
  : row.product.avgSalesInCases
    ? `${row.product.avgSalesInCases} (CS)`
    : 'None'

const getAvgCreditsFormatted = (row) => row.isUomEaches
  ? isDefined(row.product.avgCreditsInEaches)
    ? `${row.product.avgCreditsInEaches} (EA)`
    : 'None'
  : isDefined(row.product.avgCreditsInCases)
    ? `${row.product.avgCreditsInCases} (CS)`
    : 'None'

const getLastOrderedQuantityFormatted = (row) => isDefined(row.product.lastOrderedQuantity)
  ? `${row.product.lastOrderedQuantity} (EA)`
  : 'None'
</script>
<style lang="scss" scoped>
.description {
  display: grid;
  grid-template-rows: auto auto;
  align-content: center;
  height: 4.4rem;
  .product {
    color: var(--text-alt-color);
    font-weight: bold;
  }
  .vendor {
    font-family: "Chivo";
    letter-spacing: 0.4px;
    color: var(--label-text-color);
    margin-top: 0.4rem;
  }
}
.last-ordered-quantity,
.avg-sales,
.avg-credits,
.number,
.warehouse-name,
.vendor-name,
.pack-size-description,
.on-hand-quantity,
.price {
  height: 4.4rem;
  line-height: 4.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.warehouse-name,
.pack-size-description,
.on-hand-quantity,
.price {
  margin-right: 1.8rem;
  text-align: center;
}
.par-quantity,
.current-quantity,
.order-quantity {
  padding: 0.4rem;
}
.par-quantity {
  position: relative;
  .override-indicator {
    background-color: var(--primary-color);
    border-radius: 50%;
    height: 0.8rem;
    position: absolute;
    right: 0.6rem;
    top: 0.7rem;
    width: 0.8rem;
  }
}
.fine-controls {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 2.4rem);
  height: 4.4rem;
  place-content: center center;
}
</style>
