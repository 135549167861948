// @ts-check
import { ref } from 'vue'
const type = ref('')
const active = ref(false)
const headerText = ref('')
const bodyText = ref('')
const confirmText = ref('')
const cancelText = ref('')

/**
 * @typedef {Object} Options
 * @property {String?=} type
 * @property {String?=} headerText
 * @property {String?=} bodyText
 * @property {String?=} confirmText
 * @property {String?=} cancelText
 */

/** @type Function */
let close

const modalPromise = () => new Promise((resolve) => (close = resolve))

/** @param {Options=} options */
const open = (options) => {
  type.value = options?.type ?? ''
  bodyText.value = options?.bodyText ?? ''
  headerText.value = options?.headerText ?? ''
  confirmText.value = options?.confirmText ?? ''
  cancelText.value = options?.cancelText ?? ''
  active.value = true
  return modalPromise()
}

const reset = () => {
  type.value = ''
  active.value = false
  headerText.value = ''
  bodyText.value = ''
  confirmText.value = ''
  cancelText.value = ''
}

const modalAsync = {
  get type () {
    return type.value
  },
  get active () {
    return active.value
  },
  get headerText () {
    return headerText.value
  },
  get bodyText () {
    return bodyText.value
  },
  get confirmText () {
    return confirmText.value
  },
  get cancelText () {
    return cancelText.value
  },
  /** @param {Options=} options */
  open (options) {
    return open(options)
  },
  /** @param {String} message */
  alert (message) {
  /** @type {Options=} options */
    const options = {
      type: 'alert',
      headerText: 'Alert',
      bodyText: message,
      confirmText: 'Okay',
      cancelText: 'Cancel'
    }
    return open(options)
  },
  /** @param {String} message */
  confirm (message) {
    /** @type {Options=} options */
    const options = {
      type: 'confirm',
      headerText: 'Confirm',
      bodyText: message,
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    }
    return open(options)
  },
  cancelFn () {
    close(false)
    reset()
  },
  /** @param {unknown} data */
  confirmFn (data) {
    data = type.value === 'confirm' ? true : data
    close(data)
    reset()
  }
}

export default modalAsync
