const random = (length = 10) => {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789'

  let id = ''

  while (length > 0) {
    length = length - 1
    const index = Math.floor(Math.random() * chars.length)
    id += chars[index]
  }

  return id
}

const idHelper = {
  random
}

export default idHelper
