<template>
  <div class="orders-line-items-grid">
    <one-erp-simple-grid
      max-grid-height="calc(100vh - 32rem)"
      :column-attributes="columnDefs"
      :item-id-param="'product'"
      :row-data="inventoryItems"
      :enable-grid-tips="false"
      :hide-vertical-separators="true"
    >
      <template #cellcontent="{row, column}">
        <component
          :is="column.renderer"
          :row-data="row"
          :column-data="column"
          :value="row[column.field]"
          :show-error-circle="hasRowError(row, column)"
          @update="column.updateFn"
        />
      </template>
    </one-erp-simple-grid>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { SimpleGrid as OneErpSimpleGrid } from '@sfc-enterprise-ui/one-erp-components/src'
import { actions, select } from '@/stores/store'
import { columnDefs } from '@/areas/inventories/inventory-column-defs'
import { productInventoryFields } from '@/constants/product-constants'

const store = useStore()

const props = defineProps({
  inventoryNumber: { type: String, required: true },
  filters: { type: Array, required: false, default: () => [] },
  sorts: { type: Array, required: false, default: () => [] }
})

const inventory = computed(() => {
  return store.getters[select.inventories.active]
})

const inventoryItems = computed(() => {
  return store.getters[select.inventoryItems.range](
    [
      /** @param {import('@/models/InventoryItem').default} x */
      (x) => x.inventoryNumber === props.inventoryNumber,
      ...props.filters.filter((x) => !!x)
    ],
    {
      sort: [
        /** @param {import('@/models/InventoryItem').default} a
         * @param {import('@/models/InventoryItem').default} b
        */
        (a, b) => (a.product?.productDescription ?? '') < (b.product?.productDescription ?? '') ? -1 : 1,
        ...props.sorts.filter((x) => !!x)
      ],
      /** @param {import('@/models/InventoryItem').default} x */
      map: (x) => ({
        inventoryItemNumber: x.inventoryItemNumber,
        inventoryNumber: x.inventoryNumber,
        warehouseQuantity: x.warehouseQuantity,
        shelfQuantity: x.shelfQuantity,
        otherQuantity: x.otherQuantity,
        subtotalQuantity: x.subtotalQuantity,
        ...x.product,
        warehouseName: x.product.warehouseName
      })
    }
  )
})

/**
 * @param {String} key - row data number updated
 * @param {keyof import('@/constants/product-constants').IProductInventoryFields} field - input field updated
 * @param {String} value - input value updated
 */
const onUpdateInputAsync = async (key, field, value) => {
  switch (field) {
    case productInventoryFields.warehouseQuantity:
      store.dispatch(actions.inventoryItems.setInventoryItemWarehouseQuantityAsync, { key, value })
      break
    case productInventoryFields.shelfQuantity:
      store.dispatch(actions.inventoryItems.setInventoryItemShelfQuantityAsync, { key, value })
      break
    case productInventoryFields.otherQuantity:
      store.dispatch(actions.inventoryItems.setInventoryItemOtherQuantityAsync, { key, value })
      break
  }
  store.dispatch(actions.inventoryItems.setInventoryItemTotalAsync, key)
  store.dispatch(actions.inventories.cacheSaveAsync, inventory.value)
  store.dispatch(actions.inventoryItems.cacheSaveAllForInventoryAsync, inventory.value)
}

columnDefs.find((col) => col.field === productInventoryFields.warehouseQuantity).updateFn = onUpdateInputAsync
columnDefs.find((col) => col.field === productInventoryFields.shelfQuantity).updateFn = onUpdateInputAsync
columnDefs.find((col) => col.field === productInventoryFields.otherQuantity).updateFn = onUpdateInputAsync

const hasRowError = (rowData, columnData) => {
  if (rowData.subtotalQuantity !== null) {
    if (rowData[columnData.field] === null) {
      return true
    }
  }
  return false
}
</script>
<style lang="scss" scoped>
.description {
  display: grid;
  grid-template-rows: auto auto;
  align-content: center;
  height: 4.4rem;
  .product {
    color: var(--text-alt-color);
    font-weight: bold;
  }
  .vendor {
    font-family: "Chivo";
    letter-spacing: 0.4px;
    color: var(--label-text-color);
    margin-top: 0.4rem;
  }
}
.number,
.warehouse-name,
.vendor-name,
.pack-size-description,
.on-hand-quantity,
.price {
  height: 4.4rem;
  line-height: 4.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.warehouse-name,
.pack-size-description,
.on-hand-quantity,
.price {
  margin-right: 1.8rem;
  text-align: center;
}
.par-quantity,
.current-quantity,
.order-quantity {
  padding: 0.4rem;
}
.par-quantity {
  position: relative;
  .override-indicator {
    background-color: var(--primary-color);
    border-radius: 50%;
    height: 0.8rem;
    position: absolute;
    right: 0.6rem;
    top: 0.7rem;
    width: 0.8rem;
  }
}
.fine-controls {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 2.4rem);
  height: 4.4rem;
  place-content: center center;
}
</style>
