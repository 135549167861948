import dayjs from 'dayjs'

class Order {
  /**
   * @param {Object} dto
   * @param {string?} [dto.orderNumber]
   * @param {string?} [dto.type]
   * @param {string?} [dto.createdAt]
   * @param {string?} [dto.submittedAt]
   * @param {string?} [dto.deliveredAt]
   * @param {string?} [dto.updatedAt]
   * @param {string?} [dto.byUserNumber]
   * @param {string?} [dto.forUserNumber]
   * @param {string?} [dto.forCustomerNumber]
   * @param {string?} [dto.total]
   * @param {string?} [dto.purchaseOrderNumber]
   */
  constructor ({
    orderNumber = null,
    type = null,
    createdAt = null,
    submittedAt = null,
    deliveredAt = null,
    updatedAt = null,
    byUserNumber = null,
    forUserNumber = null,
    forCustomerNumber = null,
    total = null,
    purchaseOrderNumber = null
  }) {
    /** @type {string?} */
    this.orderNumber = orderNumber
    /** @type {string?} */
    this.type = type
    /** @type {string?} */
    this.createdAt = createdAt
    /** @type {string?} */
    this.submittedAt = submittedAt
    /** @type {string?} */
    this.deliveredAt = deliveredAt
    /** @type {string?} */
    this.updatedAt = updatedAt
    /** @type {string?} */
    this.byUserNumber = byUserNumber
    /** @type {string?} */
    this.forUserNumber = forUserNumber
    /** @type {string?} */
    this.forCustomerNumber = forCustomerNumber
    /** @type {string?} */
    this.total = total
    /** @type {string?} */
    this.purchaseOrderNumber = purchaseOrderNumber
  }

  get isSubmitted () {
    return !!this.submittedAt
  }

  get datePickerDeliveredAt () {
    if (this.deliveredAt) {
      return dayjs(this.deliveredAt).format('MM/DD/YYYY')
    }
    else {
      return null
    }
  }

  get templateDeliveredAt () {
    if (this.deliveredAt) {
      return dayjs(this.deliveredAt).format('dddd, MMMM DD, YYYY')
    }
    else {
      return null
    }
  }

  get templateTotal () {
    return `$ ${this.total ? parseFloat(this.total).toFixed(2).toLocaleString() : '0'}`
  }
}

export default Order
