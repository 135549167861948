import { moduleStateIsDefined, moduleStateHasActiveProperty, moduleStateHasAllProperty } from '@/stores/modules/common/common.validators'

export const active = (state) => {
  moduleStateIsDefined(state)
  moduleStateHasActiveProperty(state)
  return state?.all[state?.active] || null
}

export const byKey = (state, key) => {
  moduleStateIsDefined(state)
  moduleStateHasAllProperty(state)
  return state?.all[key] || null
}

export const exists = (state, searchPredicate) => {
  moduleStateIsDefined(state)
  moduleStateHasAllProperty(state)
  return !!Object.values(state?.all || {}).find(searchPredicate)
}

export const first = (state) => {
  moduleStateIsDefined(state)
  moduleStateHasAllProperty(state)
  return Object.values(state?.all || {})[0] || null
}

export const filter = (state, filterPredicates) => {
  moduleStateIsDefined(state)
  moduleStateHasAllProperty(state)
  let retVal = Object.values(state?.all || {})
  if (Array.isArray(filterPredicates)) {
    filterPredicates.forEach(filter => {
      retVal = retVal.filter(filter)
    })
    return retVal
  }
  else {
    return retVal.filter(filterPredicates)
  }
}

export const find = (state, searchPredicate) => {
  moduleStateIsDefined(state)
  moduleStateHasAllProperty(state)
  return Object.values(state?.all || {}).find(searchPredicate) || null
}

export const isEmpty = (state) => {
  moduleStateIsDefined(state)
  moduleStateHasAllProperty(state)
  return !Object.keys(state?.all || {}).length
}

export const isNotEmpty = (state) => {
  moduleStateIsDefined(state)
  moduleStateHasAllProperty(state)
  return !!Object.keys(state?.all || {}).length
}

export const keys = (state) => {
  moduleStateIsDefined(state)
  moduleStateHasAllProperty(state)
  return Object.keys(state?.all) || []
}

export const range = (state, rootState, reducer, where, options) => {
  moduleStateIsDefined(state)
  moduleStateHasAllProperty(state)
  let items = Object.values(state.all).map(x => reducer(x, rootState, options || {}))
  const itemsLength = items.length
  if (options?.map) {
    items = items.map(options.map)
  }
  if (Array.isArray(where)) {
    for (const filter of where) {
      items = items.filter(filter)
    }
  }
  else if (where) {
    items = items.filter(where)
  }
  if (Array.isArray(options?.sort)) {
    for (const sort of options?.sort) {
      items = items.sort(sort)
    }
  }
  else if (options?.sort) {
    items = items.sort(options.sort)
  }
  if (options?.slice) {
    const from = options.slice[0]
    const to = options.slice[1]
    items = items.slice(from, (itemsLength < to) ? itemsLength : to)
  }
  return items
}

/**
 * @param {*} state Vuex internal
 * @param {*} rootState Vuex internal
 * @param {*} reducer Vuex internal
 * @param {()=>{}[] | (()=>{})} where [Array] Finds first in a list predicates (OR); [Function] Finds first in a single predicate (AND).
 * @param {*} options Include children options.
 */
export const single = (state, rootState, reducer, where, options) => {
  moduleStateIsDefined(state)
  moduleStateHasAllProperty(state)
  const items = Object.values(state.all).map(x => reducer(x, rootState, options || {}))
  if (Array.isArray(where)) {
    for (const filter of where) {
      const found = items.find(filter)
      if (found) {
        return found
      }
    }
    return {}
  }
  else if (where) {
    return items.find(where) || {}
  }
  else {
    return {}
  }
}

export const values = (state) => {
  return Object.values(state.all || {})
}
