import dayjs from 'dayjs'
import envConfig from '@/env.config'

export default function calculateDeliveryDate (forCustomer) {
  const todayIndex = dayjs().day()
  const nextDeliveryDayIndex =
    forCustomer.indexedDeliveryDays.find(dayIndex => dayIndex > todayIndex + envConfig.orders.deliveryWindowDelayDays) ||
    forCustomer.indexedDeliveryDays[0] + 7
  const deliveryDate = dayjs().day(nextDeliveryDayIndex).startOf('day').toISOString()
  return deliveryDate
}
