export const lineItemInvalidStatusMessages = {
  removedFromOrderGuide: 'This product has been removed from the price chart.',
  noneOnHand: 'This product does not have enough stock on hand to order.'
}

export const lineItemStatusNames = {
  active: 'active',
  inactive: 'inactive',
  invalid: 'invalid'
}

export const orderPlacementProtocol = {
  entityFramework: 0,
  soap: 1
}

export const orderTypeNames = {
  order: 'order',
  credit: 'credit'
}

export const orderTypeCodes = {
  order: 0,
  credit: 1
}

export const unitOfMeasureNames = {
  case: 'case',
  each: 'each'
}

export const unitOfMeasureNamesPlural = {
  case: 'cases',
  each: 'eaches'
}

export const unitOfMeasureCodes = {
  case: 'CS',
  each: 'EA'
}

export const routerQueryStatus = {
  notFound: 'not_found',
  found: 'found',
  alreadySubmitted: 'already_submitted',
  noOrderNumberParameter: 'no_order_number_parameter'
}

export const orderGridFields = {
  lineItemNumber: 'lineItemNumber',
  productNumber: 'productNumber',
  productDescription: 'productDescription',
  vendorName: 'vendorName',
  warehouseName: 'warehouseName',
  lastOrderedQuantity: 'lastOrderedQuantity',
  avgSales: 'avgSales',
  avgCredits: 'avgCredits',
  sequenceGroupNumber: 'sequenceGroupNumber',
  packSizeDescription: 'packSizeDescription',
  onHandQuantity: 'onHandQuantity',
  price: 'price',
  reasonCode: 'reasonCode',
  unitOfMeasure: 'unitOfMeasure',
  parQuantity: 'parQuantity',
  currentQuantity: 'currentQuantity',
  orderQuantity: 'orderQuantity',
  fineControls: 'fineControls'
}

export const orderReviewGridColumns = {
  orderQuantity: 'orderQuantity',
  productDescription: 'productDescription',
  warehouseName: 'warehouseName',
  templateTotal: 'templateTotal'
}
