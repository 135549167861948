class GetProductsManageResponseDto {
  /**
   * @param {object} dto
   * @param {string?} [dto.warehouseNumber]
   * @param {string?} [dto.customerNumber]
   * @param {string?} [dto.productNumber]
   * @param {string?} [dto.productDescription]
   * @param {string?} [dto.vendorName]
   * @param {string?} [dto.eachesPerCase]
   * @param {number?} [dto.sequenceGroupNumber]
   * @param {string?} [dto.sequenceGroupDescription]
   * @param {boolean?} [dto.shouldHideSku]
   */
  constructor ({
    warehouseNumber = null,
    customerNumber = null,
    productNumber = null,
    productDescription = null,
    vendorName = null,
    sequenceGroupNumber = null,
    sequenceGroupDescription = null,
    shouldHideSku = null
  }) {
    /** @type {string?} */
    this.warehouseNumber = warehouseNumber
    /** @type {string?} */
    this.customerNumber = customerNumber
    /** @type {string?} */
    this.productNumber = productNumber
    /** @type {string?} */
    this.productDescription = productDescription
    /** @type {string?} */
    this.vendorName = vendorName
    /** @type {number?} */
    this.sequenceGroupNumber = sequenceGroupNumber
    /** @type {string?} */
    this.sequenceGroupDescription = sequenceGroupDescription
    /** @type { boolean?} */
    this.shouldHideSku = shouldHideSku
  }
}

export default GetProductsManageResponseDto
