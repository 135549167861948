import { filter, byKey, isEmpty, isNotEmpty, range, single } from '@/stores/modules/common/common.getters'
import InventoryItem from '@/models/InventoryItem'

const reducer = (inventoryItem, { orders }, { include }) => {
  const retVal = inventoryItem ? new InventoryItem(inventoryItem) : {}

  if (include?.includes('customer')) {
    retVal.customer = byKey(orders, inventoryItem.forCustomerNumber) || null
  }

  return retVal
}

const getters = {}

getters.byNumber = (state) => (key) => byKey(state, key)

getters.filter = (state) => (filterPredicate) => filter(state, filterPredicate)

getters.isEmpty = (state) => isEmpty(state)

getters.isNotEmpty = (state) => isNotEmpty(state)

getters.nextNumber = (_, __, { inventoryItems }) => {
  const all = Object.values(inventoryItems.all)
    .sort((a, b) => a.inventoryItemNumber < b.inventoryItemNumber ? -1 : 1)
  return (all[all.length - 1]?.inventoryItemNumber || 0) + 1
}

getters.range = (state, _, rootState) => (where, options) => {
  return range(state, rootState, reducer, where, options)
}

getters.single = (state, _, rootState) => (where, options) => {
  return single(state, rootState, reducer, where, options)
}

const names = {
  byNumber: 'byNumber',
  filter: 'filter',
  isEmpty: 'isEmpty',
  isNotEmpty: 'isNotEmpty',
  nextNumber: 'nextNumber',
  range: 'range',
  single: 'single'
}

const namespaced = {
  byNumber: 'inventoryItems/byNumber',
  filter: 'inventoryItems/filter',
  isEmpty: 'inventoryItems/isEmpty',
  isNotEmpty: 'inventoryItems/isNotEmpty',
  nextNumber: 'inventoryItems/nextNumber',
  range: 'inventoryItems/range',
  single: 'inventoryItems/single'
}

export {
  names,
  namespaced,
  getters
}
