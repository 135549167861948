import { isNumber, isString } from '@/utilities/type-utility'
import { lineItemStatusNames, unitOfMeasureNames, unitOfMeasureNamesPlural } from '@/constants/orders-constants'
import Product from '@/models/Product'

class LineItem {
  /**
   * @param {object} dto
   * @param {number?} [dto.lineItemNumber]
   * @param {string?} [dto.orderNumber]
   * @param {string?} [dto.comment]
   * @param {string?} [dto.status]
   * @param {string?} [dto.statusMessage]
   * @param {number?} [dto.currentQuantity]
   * @param {number?} [dto.orderQuantity]
   * @param {number | string?} [dto.total]
   * @param {string?} [dto.unitOfMeasure]
   * @param {number?} [dto.parQuantity]
   * @param {string?} [dto.updatedAt]
   * @param {string?} [dto.reasonCode]
   * @param {Product?} [dto.product]
   */
  constructor ({
    lineItemNumber = null,
    orderNumber = null,
    comment = null,
    status = null,
    statusMessage = null,
    currentQuantity = 0,
    orderQuantity = 0,
    total = 0.0,
    unitOfMeasure = null,
    parQuantity = null,
    updatedAt = null,
    reasonCode = null,
    product = /** @type {Product} */({})
  }) {
    /** @type {number?} */
    this.lineItemNumber = lineItemNumber
    /** @type {string?} */
    this.orderNumber = orderNumber
    /** @type {string?} */
    this.comment = comment
    /** @type {string?} */
    this.status = status || lineItemStatusNames.active
    /** @type {string?} */
    this.statusMessage = statusMessage
    /** @type {number?} */
    this.currentQuantity = currentQuantity
    /** @type {number?} */
    this.orderQuantity = orderQuantity
    /** @type {number | string?} */
    this.total = total
    /** @type {string?} */
    this.unitOfMeasure = unitOfMeasure
    /** @type {number?} */
    this.parQuantity = parQuantity
    /** @type {string?} */
    this.updatedAt = updatedAt
    /** @type {string?} */
    this.reasonCode = reasonCode
    /** @type {Product?} */
    this.product = new Product(/** @type {*} */(product))
  }

  get isUomEaches () {
    return this.unitOfMeasure === unitOfMeasureNames.each
  }

  get isUomCases () {
    return this.unitOfMeasure === unitOfMeasureNames.case
  }

  /**
   * Returns the order quantity followed by the unit of measure in a more human readable format.
   */
  get orderQuantityUom () {
    if (isNumber(this.orderQuantity)) {
      const uom = this.orderQuantity > 1 ? unitOfMeasureNamesPlural : unitOfMeasureNames
      const label = this.isUomEaches ? uom.each : uom.case
      return `${this.orderQuantity} ${label}`
    } else {
      return ''
    }
  }

  get parQuantityDefault () {
    return isNumber(this.parQuantity)
      ? this.parQuantity
      : isNumber(this.product?.parQuantity)
        ? this.product?.parQuantity
        : 0
  }

  get parQuantityHasOverride () {
    return isNumber(this.parQuantity)
  }

  get templateTotal () {
    if (isString(this.total)) {
      this.total = parseFloat(this.total)
    }
    return isNumber(this.total)
      ? `$ ${this.total.toFixed(2).toLocaleString()}`
      : ''
  }
}

export default LineItem
