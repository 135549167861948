export class UserVisibleError extends Error {
  name = 'UserVisibleError'

  /**
 * @param {string} apiMessage Message received from the service.
 * @param {string} userMessage Message to show the user.
 * @param {string} userInstructions Instructions to show the user.
 * @param {string?} trace If applicable, the trace data received from the service.
 */
  constructor (apiMessage, userMessage, userInstructions, trace) {
    super(apiMessage)
    this.apiMessage = apiMessage
    this.userMessage = userMessage
    this.userInstructions = userInstructions
    this.trace = trace
  }
}

/**
 * Throws a type of error that is picked up by the Vue app and displays error context to the user in
 * a user-friendly manner. Also captures any underlying error data for consumption by Vue, as
 * needed.
 *
 * @param {string} apiMessage Message received from the service.
 * @param {string} userMessage Message to show the user.
 * @param {string} userInstructions Instructions to show the user.
 */
const raiseUserError = (apiMessage, userMessage, userInstructions) => {
  throw new UserVisibleError(apiMessage, userMessage, userInstructions, null)
}

export default raiseUserError
