import { actions } from '@/stores/modules/line-items/line-items-store.actions'
import { getters } from '@/stores/modules/line-items/line-items-store.getters'
import { mutations } from '@/stores/modules/line-items/line-items-store.mutations'

const store = {
  state: {
    all: {}
  },
  strict: true,
  namespaced: true,
  actions,
  getters,
  mutations
}

export default store
