<template>
  <div>
    <one-erp-menu
      :available-route-parameters="availableRouteParameters"
      :current-roles="currentRoles"
      :route="$route"
      :router="$router"
    />
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { Menu as OneErpMenu } from '@sfc-enterprise-ui/one-erp-components'

const currentRoles = ref([])
const availableRouteParameters = ref([
])

</script>
