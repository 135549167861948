import Customer from '@/models/Customer'

/**
 * @param {import('@/services/dto/GetCustomersResponseDto').default} dto
 * @returns {import('@/models/Customer').default}
 */
export const mapGetCustomersResponseDtoToCustomerModel = (dto) => {
  let errorMessage

  // #region validate dto
  if (!dto.customerNumber) errorMessage = 'customerNumber is not valid.'
  // #endregion

  if (errorMessage) {
    throw new Error(`Model validation failed: ${errorMessage}`)
  }

  return new Customer({
    customerNumber: dto.customerNumber,
    customerName: dto.customerName,
    address: dto.address,
    deliveryDays: dto.deliveryDays,
    hasConsignedInventory: dto.hasConsignedInventory
  })
}
