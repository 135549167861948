import { getters, select, dispatch, actions } from '@/stores/store'

export const editOrderViewBeforeUpdateAsync = async (to, from, next) => {
  await dispatch(actions.lineItems.clearAsync)
  await dispatch(actions.orders.deactivateAsync)

  return next()
}

export const editOrderViewBeforeEnterOrUpdateAsync = async (to, from, next) => {
  const orderNumber = to.params.orderNumber
  const order = getters[select.orders.byNumber](orderNumber)
  const customer = getters[select.customers.byNumber](order?.forCustomerNumber)

  if (order && customer) {
    await dispatch(actions.customers.activateAsync, customer)
    await dispatch(actions.orders.activateAsync, order)
    await dispatch(actions.lineItems.cacheLoadAllForOrderAsync, order)
  }

  return next()
}

export const editInventoryViewBeforeUpdateAsync = async (to, from, next) => {
  await dispatch(actions.inventoryItems.clearAsync)
  await dispatch(actions.inventories.deactivateAsync)

  return next()
}

export const editInventoryViewBeforeEnterOrUpdateAsync = async (to, from, next) => {
  const inventoryNumber = to.params.inventoryNumber
  const inventory = getters[select.inventories.byNumber](inventoryNumber)
  const customer = getters[select.customers.byNumber](inventory?.forCustomerNumber)

  if (inventory && customer) {
    await dispatch(actions.customers.activateAsync, customer)
    await dispatch(actions.inventories.activateAsync, inventory)
    await dispatch(actions.inventoryItems.cacheLoadAllForInventoryAsync, inventory)
  }

  return next()
}
