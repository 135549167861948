export const itemHasKeyPropertyByKeyName = (item, keyName) => {
  if (typeof item[keyName] === 'undefined') {
    throw new Error(`${keyName} of ${item[keyName]}: Item key property is undefined.`)
  }
}

export const moduleStateHasActiveProperty = (state) => {
  if (typeof state?.active === 'undefined') {
    throw new Error('Module state property \'active\' is undefined.')
  }
}

export const moduleStateHasAllProperty = (state) => {
  if (typeof state?.all === 'undefined') {
    throw new Error('Module state property \'all\' is undefined.')
  }
  if (typeof state?.all !== 'object') {
    throw new Error('Module state property \'all\' is not an object.')
  }
}

export const moduleStateHasItemByKey = (state, key) => {
  if (!state?.all[key]) {
    throw new Error('Cannot find item in module state.')
  }
}

export const moduleStateIsActivated = (state) => {
  if (state?.active === null) {
    throw new Error('Module state is not activated.')
  }
}

export const moduleStateIsDefined = (state) => {
  if (typeof state === 'undefined') {
    throw new Error('Module state is undefined.')
  }
  if (typeof state !== 'object') {
    throw new Error('Module state is not an object.')
  }
}
