import { Base64 } from 'js-base64'
import { cacheConstants } from '@/app-constants'
import { getItem } from '@/helpers/cache-helper'

export const decodeAccessTokenPayload = () => {
  const token = getItem(cacheConstants.accessToken)
  if (!token) {
    throw Error('Could not find access token')
  }
  else {
    return JSON.parse(Base64.decode(token.split('.')[1]))
  }
}
