import { cacheKeys } from '@/constants/cache-constants'
import { getCustomersAsync } from '@/services/customers-service'
import { getItem } from '@/helpers/cache-helper'
import { mapGetCustomersResponseDtoToCustomerModel } from '@/utilities/mappers/customer-mapper'
import { names as customerMutationNames } from '@/stores/modules/customers/customers-store.mutations'

const actions = {}

actions.initializeAsync = async ({ commit, getters }, user) => {
  const customers = (await getCustomersAsync({ salesRepNumber: user.salesRepNumber }))
    .map((x) => mapGetCustomersResponseDtoToCustomerModel(x))

  commit(customerMutationNames.clear)
  commit(customerMutationNames.addRange, customers)

  commit(
    customerMutationNames.activate,
    getItem(getters.byNumber(getItem(cacheKeys.activeCustomerNumber))) || getters.first
  )
}

actions.activateAsync = ({ commit }, customer) => {
  commit(customerMutationNames.activate, customer)
}

const names = {
  initializeAsync: 'initializeAsync',
  activateAsync: 'activateAsync'
}

const namespaced = {
  initializeAsync: 'customers/initializeAsync',

  /**
   * @param {} payload Customer object; if undefined will set active from defaults.
   */
  activateAsync: 'customers/activateAsync'
}

export {
  names,
  namespaced,
  actions
}
