import { unitOfMeasureCode } from '@/constants/order-constants'
import BaseProduct from '@/models/base/BaseProduct'

/** @typedef {import('@/models/interfaces/IProductCellRenderer').IProductCellRenderer} IProductCellRenderer */
/** @typedef {import('@/models/interfaces/IDescriptionCellRenderer').IDescriptionCellRenderer} IDescriptionCellRenderer */

/** @implements {IProductCellRenderer} */
/** @implements {IDescriptionCellRenderer} */
class Product extends BaseProduct {
/**
 * @param {object} dto
 * @param {string?} [dto.warehouseNumber]
 * @param {string?} [dto.productNumber]
 * @param {string?} [dto.productDescription]
 * @param {string?} [dto.vendorName]
 * @param {number?} [dto.pricePerEach]
 * @param {number?} [dto.pricePerCase]
 * @param {string?} [dto.packSizeDescription]
 * @param {string?} [dto.purchaseUnitOfMeasure]
 * @param {string?} [dto.unitOfMeasureBase]
 * @param {number?} [dto.perCaseQuantity]
 * @param {number?} [dto.onHandUnitsQuantity]
 * @param {number?} [dto.onHandCasesQuantity]
 * @param {number?} [dto.parQuantity]
 * @param {string?} [dto.parQuantityUnitOfMeasure]
 * @param {string?} [dto.sequenceGroupDescription]
 * @param {number?} [dto.sequenceGroupNumber]
 * @param {number?} [dto.lastOrderedQuantity]
 * @param {number?} [dto.avgSalesInEaches]
 * @param {number?} [dto.avgSalesInCases]
 * @param {number?} [dto.avgCreditsInEaches]
 * @param {number?} [dto.avgCreditsInCases]
*/
  constructor ({
    warehouseNumber = null,
    productNumber = null,
    productDescription = null,
    vendorName = null,
    pricePerEach = null,
    pricePerCase = null,
    packSizeDescription = null,
    purchaseUnitOfMeasure = null,
    unitOfMeasureBase = null,
    perCaseQuantity = null,
    onHandUnitsQuantity = null,
    onHandCasesQuantity = null,
    parQuantity = null,
    parQuantityUnitOfMeasure = null,
    sequenceGroupDescription = null,
    sequenceGroupNumber = null,
    lastOrderedQuantity = null,
    avgSalesInEaches = null,
    avgSalesInCases = null,
    avgCreditsInEaches = null,
    avgCreditsInCases = null
  }) {
    super({ warehouseNumber })
    /** @type {string?} */
    this.productNumber = productNumber
    /** @type {string?} */
    this.productDescription = productDescription
    /** @type {string?} */
    this.vendorName = vendorName
    /** @type {number?} */
    this.pricePerEach = pricePerEach
    /** @type {number?} */
    this.pricePerCase = pricePerCase
    /** @type {string?} */
    this.packSizeDescription = packSizeDescription
    /** @type {string?} */
    this.purchaseUnitOfMeasure = purchaseUnitOfMeasure
    /** @type {string?} */
    this.unitOfMeasureBase = unitOfMeasureBase
    /** @type {number?} */
    this.perCaseQuantity = perCaseQuantity
    /** @type {number?} */
    this.onHandUnitsQuantity = onHandUnitsQuantity
    /** @type {number?} */
    this.onHandCasesQuantity = onHandCasesQuantity
    /** @type {number?} */
    this.parQuantity = parQuantity
    /** @type {string?} */
    this.parQuantityUnitOfMeasure = parQuantityUnitOfMeasure
    /** @type {string?} */
    this.sequenceGroupDescription = sequenceGroupDescription
    /** @type {number?} */
    this.sequenceGroupNumber = sequenceGroupNumber
    /** @type {number?} */
    this.lastOrderedQuantity = lastOrderedQuantity
    /** @type {number?} */
    this.avgSalesInEaches = avgSalesInEaches
    /** @type {number?} */
    this.avgSalesInCases = avgSalesInCases
    /** @type {number?} */
    this.avgCreditsInEaches = avgCreditsInEaches
    /** @type {number?} */
    this.avgCreditsInCases = avgCreditsInCases
  }

  get canPurchaseByEach () {
    return this.purchaseUnitOfMeasure === unitOfMeasureCode.each
  }

  get canPurchaseByCase () {
    return this.purchaseUnitOfMeasure === unitOfMeasureCode.case
  }
}

export default Product
