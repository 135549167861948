<template>
  <div class="orders-create">
    <div class="customer-details" v-if="props.showActiveCustomerDetails && activeCustomer">
      <div class="name">
        {{ activeCustomer.customerName }}
      </div>
      <div class="address">
        {{ activeCustomer.address?.singleLine }}
      </div>
      <div class="delivery-days">
        <span class="label">Delivery:</span>
        <customers-delivery-days
          :customer-number="activeCustomer.customerNumber"
        />
      </div>
    </div>
    <one-erp-button
      @click="onSelectCreateOrderAsync"
      :button-type="oneErpButtonTypes.solid"
      class="create-order"
      button-text="Create Order"
    />
    <one-erp-button
      @click="onSelectCreateCreditAsync"
      :button-type="oneErpButtonTypes.open"
      class="create-credit"
      button-text="Create Credit"
    />
    <one-erp-modal
      @cancel="onSelectCancelDeleteOrderCredit"
      @confirm="onSelectConfirmDeleteOrderCreditAsync"
      :width="modalSizesConstants.small"
      :show-modal="uiState.showConfirmDeleteModal"
      :overflow-y="overflowConstants.auto"
      cancel-button-text="Back"
      confirm-button-text="Delete and Create"
    >
      <template #header>
        <h2>{{ `Delete ${uiState.orderOrCredit?.type || 'item'}` }}</h2>
      </template>
      <template #content>
        <div class="confirm-delete">
          <span class="bigger-text">
            {{ `Creating a new ${uiState.orderType} requires first submitting or deleting your existing ${uiState.orderOrCredit?.type || 'item'}.` }}
          </span>
          <br>
          <span class="bigger-text">
            {{
              `Are you sure you want to delete your existing ${uiState.orderOrCredit?.type || 'item'}` +
                ` for ${activeCustomer?.customerName || 'this customer'}?`
            }}
          </span>
        </div>
      </template>
    </one-erp-modal>
  </div>
</template>
<script setup>
import { orderTypeNames } from '@/constants/orders-constants'
import idHelper from '@/helpers/id-helper'
import { actions, dispatch, getters, select, state as storeState } from '@/stores/store'
import calculateDeliveryDate from '@/utilities/delivery-date-utils'
import raiseUserError from '@/utilities/errors/raise-user-error'
import {
  Button as OneErpButton,
  Modal as OneErpModal,
  ModalSizes as modalSizesConstants,
  ButtonTypes as oneErpButtonTypes,
  OverflowYValues as overflowConstants
} from '@sfc-enterprise-ui/one-erp-components'
import { computed, reactive } from 'vue'
import CustomersDeliveryDays from '@/components/customers/customers-delivery-days.vue'

const emit = defineEmits([
  'open-confirm-delete-order-credit-modal',
  'close-confirm-delete-order-credit-modal',
  'order-credit-created',
  'order-credit-deleted',
  'loading'
])

const props = defineProps({
  showActiveCustomerDetails: {
    type: Boolean,
    required: false,
    default: false
  }
})

const uiState = reactive({
  /** @type {String?} */
  orderType: null,
  showConfirmDeleteModal: false,
  /** @type {import('@/models/Order').default?} */
  orderOrCredit: null,
  /** @type {String?} */
  purchaseOrderNumber: null
})

const activeCustomer = computed(() => {
  return getters[select.customers.active] || {}
})

const activeCustomerInProgressOrder = computed(() => {
  return getters[select.orders.find](x => (
    x.forCustomerNumber === storeState.customers.active &&
  !x.submittedAt &&
  x.type === orderTypeNames.order
  ))
})

const activeCustomerInProgressCredit = computed(() => {
  return getters[select.orders.find](x => (
    x.forCustomerNumber === storeState.customers.active &&
  !x.submittedAt &&
  x.type === orderTypeNames.credit
  ))
})

const onSelectCreateOrderAsync = async () => {
  window.appInsights.trackEvent('onSelectCreateOrderAsync', {
    customer: activeCustomer?.value?.customerNumber,
    orderInProgress: activeCustomerInProgressOrder?.value?.customerNumber
  })

  uiState.orderType = orderTypeNames.order
  if (activeCustomerInProgressOrder.value) {
    uiState.showConfirmDeleteModal = true
    uiState.orderOrCredit = activeCustomerInProgressOrder.value
    emit('open-confirm-delete-order-credit-modal')
  }
  else {
    try {
      await createOrderCreditAsync()
    }
    finally {
      uiState.orderType = null
    }
  }
}

const onSelectCreateCreditAsync = async () => {
  window.appInsights.trackEvent('onSelectCreateCreditAsync', {
    customer: activeCustomer?.value?.customerNumber,
    creditInProgress: activeCustomerInProgressCredit?.value?.customerNumber
  })

  uiState.orderType = orderTypeNames.credit
  if (activeCustomerInProgressCredit.value) {
    uiState.showConfirmDeleteModal = true
    uiState.orderOrCredit = activeCustomerInProgressCredit.value
    emit('close-confirm-delete-order-credit-modal')
  }
  else {
    try {
      await createOrderCreditAsync()
    }
    finally {
      uiState.orderType = null
    }
  }
}

const onSelectCancelDeleteOrderCredit = () => {
  uiState.orderType = null
  uiState.showConfirmDeleteModal = false
  uiState.orderOrCredit = null
  emit('close-confirm-delete-order-credit-modal')
}

const onSelectConfirmDeleteOrderCreditAsync = async () => {
  uiState.showConfirmDeleteModal = false
  emit('close-confirm-delete-order-credit-modal')

  if (uiState.orderType) {
    try {
      await dispatch(
        actions.orders.cacheRemoveAsync,
        uiState.orderOrCredit
      )
      await dispatch(
        actions.orders.removeAsync,
        uiState.orderOrCredit
      )
      emit('order-credit-deleted')

      await createOrderCreditAsync()
    }
    finally {
      uiState.orderType = null
      uiState.orderOrCredit = null
    }
  }
}

const createOrderCreditAsync = async () => {
  let order
  const byUser = getters[select.users.current]
  const forUser = getters[select.users.active]
  const forCustomer = getters[select.customers.active]
  try {
    emit('loading', true)

    const deliveryDate = calculateDeliveryDate(forCustomer)

    if (byUser && forUser && forCustomer) {
      order = {
        orderNumber: 'DS_' + idHelper.random(13).toUpperCase(),
        byUserNumber: byUser.salesRepNumber,
        forUserNumber: forUser.salesRepNumber,
        forCustomerNumber: forCustomer.customerNumber,
        deliveredAt: deliveryDate,
        purchaseOrderNumber: uiState.purchaseOrderNumber,
        type: uiState.orderType
      }
    }
    else {
      throw new Error('Missing dependencies to create new order.')
    }

    await dispatch(actions.orders.createAsync, order)
    await dispatch(actions.orders.cacheCreateAsync, order)
    await dispatch(actions.lineItems.cacheSaveAllFromRemoteForOrderAsync, order)

    window.appInsights.trackEvent('createOrderCreditAsync', {
      orderNumber: order?.orderNumber,
      byUserNumber: order?.byUserNumber,
      forUserNumber: order?.forUserNumber,
      forCustomerNumber: order?.forCustomerNumber,
      deliveredAt: order?.deliveredAt,
      purchaseOrderNumber: order?.purchaseOrderNumber,
      type: order?.type
    })

    emit('order-credit-created', order.orderNumber)
  }
  catch (err) {
    try {
      await dispatch(actions.orders.removeAsync, order)
      await dispatch(actions.orders.cacheRemoveAsync, order)
    }
    catch { }
    raiseUserError(
      err.message,
      `Could not create the ${order?.type}.`,
      'Please try again and contact support if this problem continues.'
    )
  }
  finally {
    emit('loading', false)
  }
}
</script>
<style lang="scss" scoped>
.orders-create {
  grid-template-areas:
    'customer-details customer-details'
    'create-order-button create-credit-button';
  display: grid;
  gap: 3rem;
  row-gap: 0;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  height: 100%;
}
.customer-details {
  grid-template-areas:
    'name'
    'address'
    'delivery-days';
  display: grid;
  grid-area: customer-details;
  grid-template-rows: repeat(3, auto);
  margin-bottom: 4rem;
  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .name {
    font-size: 1.8rem;
    grid-area: 'name';
    padding-bottom: 0.5rem;
  }
  .address {
    color: var(--text-alt-color);
    font-weight: bold;
    grid-area: 'address';
    padding-bottom: 1.5rem;
  }
  .delivery-days {
    display: grid;
    gap: 1rem;
    grid-auto-flow: column;
    grid-area: 'delivery-days';
    justify-content: start;
    .label {
      font-weight: bold;
      line-height: 1.8rem;
      padding-top: 0.2rem;
    }
  }
}
.create-order,
.create-credit {
  align-self: end;
}
.create-order {
  grid-area: create-order-button;
}
.create-credit {
  grid-area: create-credit-button;
}
</style>
